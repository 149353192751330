/**
 * Created by evgenijsenkevic on 26.12.2024
 */

var FriendsListRequestsToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.FRIENDS_LIST_REQUESTS);

    this.enable();
};

FriendsListRequestsToolbarItem.prototype = Object.create(ToolbarItem.prototype);
FriendsListRequestsToolbarItem.prototype.constructor = FriendsListRequestsToolbarItem;

FriendsListRequestsToolbarItem.prototype.isVisible = function () {
    return levels.user.checkAvailable(cleverapps.Availables.FRIENDBALLOON);
};

FriendsListRequestsToolbarItem.prototype.onClick = function () {
    this.unmark();

    cleverapps.focusManager.display({
        focus: "FriendsSendPresentWindow",
        action: function (f) {
            cleverapps.SocialManager.enforceLogin(function (code) {
                if (code !== cleverapps.CODE_SUCCEED) {
                    f();
                    return;
                }

                if (cleverapps.platformLogic.chooseFriend) {
                    cleverapps.platformLogic.chooseFriend(function (friendId) {
                        levels.FriendRequests.sendGift(friendId);
                        f();
                    }, function (error) {
                        f();

                        if (!error) {
                            return;
                        }

                        if (error.code === "FRIEND_NOT_CHOOSED") {
                            cleverapps.notification.create("FriendGift.not_play");
                        } else if (error.code !== "USER_INPUT") {
                            cleverapps.throwAsync(
                                "cleverapps.platformLogic.chooseFriend error: " + JSON.stringify(error)
                            );
                        }
                    });
                } else {
                    FriendsCache.loadFriends(function () {
                        new FriendsListRequestsWindow();
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    });
                }
            });
        }
    });
};
