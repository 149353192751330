/**
 * Created by Mikhail Menshenin on 19.08.2024
 */

var SlotMachineReel = function (index) {
    cleverapps.EventEmitter.call(this);

    this.symbols = SlotMachineReel.SYMBOLS.map(function (symbol, index) {
        return new SlotMachineSymbol(this, symbol, index);
    }, this);
    this.current = 0;
    this.index = index;

    this.setCurrentByType(cleverapps.Random.choose(SlotMachineReel.SYMBOLS).type);
};

SlotMachineReel.prototype = Object.create(cleverapps.EventEmitter.prototype);
SlotMachineReel.prototype.constructor = SlotMachineReel;

SlotMachineReel.prototype.setCurrentByType = function (type) {
    this.current = this.symbols.filter(function (symbol) {
        return symbol.type === type;
    })[0].index;

    for (var i = 0; i < this.symbols.length; i++) {
        this.symbols[i].move();
    }
};

SlotMachineReel.prototype.getCurrent = function () {
    return this.symbols[this.current];
};

SlotMachineReel.prototype.spin = function (type, reelSpinCount, callback) {
    this.getCurrent().reset();

    this.minSpinsAmount = cleverapps.Random.random(20, 25);
    this._spinOneDegree(0, type, reelSpinCount, callback);
};

SlotMachineReel.prototype._spinOneDegree = function (spins, type, reelSpinCount, callback) {
    spins++;
    this.current = (this.current - 1 + this.symbols.length) % this.symbols.length;

    var duration = SlotMachineReel.ONE_DEGREE_DURATION * 3;
    var isFast = false;
    if (spins > 2) {
        duration = SlotMachineReel.ONE_DEGREE_DURATION;
        isFast = true;
    }

    if (spins === reelSpinCount + this.index - SlotMachineReel.SYMBOLS.length) {
        this.setCurrentByType(type);
    }

    if (spins >= reelSpinCount - 2) {
        duration = SlotMachineReel.ONE_DEGREE_DURATION * 3;
        isFast = false;
    }

    var isLast = spins === reelSpinCount + this.index;

    for (var i = 0; i < this.symbols.length; i++) {
        this.symbols[i].spin(duration, isFast, isLast);
    }

    var game = Game.currentGame;
    game.counter.setTimeout(function () {
        if (isLast) {
            callback();
        } else {
            this._spinOneDegree(spins, type, reelSpinCount, callback);
        }
    }.bind(this), duration);
};

SlotMachineReel.ONE_DEGREE_DURATION = 50;
