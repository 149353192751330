/**
 * Created by razial on 14.02.2025.
 */

var CommonLivesAdapter = function () {

};

CommonLivesAdapter.prototype.save = function (data, fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.LIVES, data);
    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("lives");
    }
};

CommonLivesAdapter.prototype.load = function () {
    return cleverapps.dataLoader.load(DataLoader.TYPES.LIVES);
};

CommonLivesAdapter.prototype.logEarnEvent = function (event, amount) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.EARN.LIVES + "_" + event, { value: amount });
};

var LivesBySlotsAdapter = function (slot) {
    this.slot = slot;
};

LivesBySlotsAdapter.prototype.save = function (data, fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.LIVES + this.slot, data);
    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("lives" + this.slot);
    }
};

LivesBySlotsAdapter.prototype.load = function () {
    return cleverapps.dataLoader.load(DataLoader.TYPES.LIVES + this.slot);
};

LivesBySlotsAdapter.prototype.logEarnEvent = function (event, amount) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.EARN.LIVES + this.slot + "_" + event, { value: amount });
};

var LivesHelper = {
    slots: {},

    Initialize: function (isNewUser) {
        Meta.SLOTS.forEach(function (slot) {
            var adapter;

            if (slot === Meta.SLOT_MAIN) {
                adapter = new CommonLivesAdapter();
            } else {
                adapter = new LivesBySlotsAdapter(slot);
            }

            this.slots[slot] = new cleverapps.Lives(adapter, isNewUser);
        }.bind(this));

        cleverapps.lives = this.slots[Meta.SLOT_MAIN];
    },

    Switch: function (slot) {
        slot = slot || Meta.SLOT_MAIN;
        cleverapps.lives = this.slots[slot];
    },

    Reset: function (slot) {
        this.slots[slot].reset();
    },

    GetInstance: function (slot) {
        return this.slots[slot];
    }
};

CustomSyncers.registerBySlots("lives", function (slot) {
    var lives = LivesHelper.GetInstance(slot);
    return lives ? lives.getInfo() : {};
}, function (slot, serverData) {
    var lives = LivesHelper.GetInstance(slot);
    if (lives) {
        lives.updateInfo(serverData, true);
    }
});
