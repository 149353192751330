/**
 * Created by Mikhail Menshenin on 30.07.2024
 */

var SlotMachineIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.slotmachine_icon_json,
        targets: ["SlotMachineIcon"],
        control: "SlotMachineIcon"
    });

    this.resetState();
};

SlotMachineIcon.prototype = Object.create(SideBarIcon.prototype);
SlotMachineIcon.prototype.constructor = SlotMachineIcon;

SlotMachineIcon.prototype.resetState = function () {
    this.available = cleverapps.environment.hasScene(cleverapps.Environment.SCENE_MAIN);
};

SlotMachineIcon.prototype.onPressed = function () {
    cleverapps.focusManager.display({
        focus: "SlotMachineIconClicked",
        action: function (f) {
            cleverapps.meta.wantsToPlay(f);
        }
    });
};

SlotMachineIcon.prototype.getForce = function () {
    if (aisensia.slotMachine && aisensia.slotMachine.spins === 0) {
        return {
            text: "Tutorial.CoinKingdom.SlotMachineIconForce"
        };
    }
};
