/**
 * Created by Mikhail Menshenin on 13.01.2025
 */

var OutOfEnergyWindow = CleverappsWindow.extend({
    onWindowLoaded: function (slotMachine) {
        this.slotMachine = slotMachine;
        this.product = Product.CreateById("energyPack");

        this._super({
            content: this.createContent(),
            title: "OutOfEnergyWindow.title",
            name: "OutOfEnergywindow",
            noBackground: true,
            notCloseByTouchInShadow: true
        });

        if (this.product && this.product.currency === "hard") {
            cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");
        }

        cleverapps.paymentsLogic.onChangeStatus(this.updateButton.bind(this), this);
        this.updateButton();
    },

    createContent: function () {
        var styles = cleverapps.styles.OutOfEnergyWindow;

        var image = this.createImage();
        var button = this.button = this.createButton();
        var text = this.createText();

        var content = new cleverapps.Layout([image, button, text], {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL,
            padding: styles.padding
        });

        return content;
    },

    createImage: function () {
        var spine = new cleverapps.Spine(bundles.outofenergywindow.jsons.energypack_json);
        spine.setIdle("idle");
        return spine;
    },

    createButton: function () {
        var styles = cleverapps.styles.OutOfEnergyWindow;
        return new cleverapps.UI.Button({
            text: this.getButtonText(),
            width: styles.button.width,
            height: styles.button.height,
            onClicked: this.onBuy.bind(this)
        });
    },

    updateButton: function () {
        if (connector.payments.isConnected()) {
            this.button.enable();
        } else {
            this.button.disable();
        }
    },

    createText: function () {
        var timer = this.timer = new cleverapps.CountDownView(new cleverapps.CountDown(this.slotMachine.getEnergyRestoreTimeLeft()), {
            font: cleverapps.styles.FONTS.WHITE_TEXT,
            showHours: false
        });
        timer.setString = cleverapps.extendFunc(timer.setString, function (string) {
            string = Messages.get("OutOfEnergyWindow.text", {
                time: string,
                count: 1
            });
            this._super(string);
        });
        timer.refresh();
        timer.text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        return timer;
    },

    onBuy: function () {
        if (this.product) {
            this.product.buy(function (success) {
                if (success) {
                    this.close();
                }
            }.bind(this));
        } else if (cleverapps.config.debugMode) {
            var rewardList = new RewardsList({ energy: 50 }, { event: cleverapps.EVENTS.EARN.SLOTMACHINE });
            rewardList.receiveRewards();
            rewardList.onAnimationFinished();
            this.close();
        }
    },

    getButtonText: function () {
        return this.product && this.product.getCurrentPrice() || "buy";
    },

    listBundles: function () {
        return ["outofenergywindow"];
    }
});

cleverapps.styles.OutOfEnergyWindow = {
    margin: 75,

    padding: {
        bottom: 40
    },

    animation: {
        width: 250,
        height: 250,

        x: { align: "center" },
        y: { align: "center" }
    },

    text: {
        width: 700,
        height: 300
    },

    button: {
        width: 300,
        height: 100
    }
};
