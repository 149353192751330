/**
 * Created by Mikhail Menshenin on 23.09.2024
 */

var Shields = function (isNewUser) {
    cleverapps.EventEmitter.call(this);

    if (isNewUser) {
        this.init();
    } else {
        this.load();
    }
};

Shields.prototype = Object.create(cleverapps.EventEmitter.prototype);
Shields.prototype.constructor = Shields;

Shields.prototype.set = function (amount, silent) {
    this.exceeded = amount > Shields.MAX_SHIELDS;
    this.amount = this.exceeded ? Shields.MAX_SHIELDS : amount;
    if (!silent) {
        this.trigger("change");
    }
};

Shields.prototype.get = function () {
    return this.amount;
};

Shields.prototype.change = function () {
    this.trigger("change");
};

Shields.prototype.init = function () {
    this.amount = Shields.MAX_SHIELDS;
    this.exceeded = false;
    this.save();
};

Shields.prototype.load = function (data) {
    data = data || cleverapps.dataLoader.load(DataLoader.TYPES.SHIELDS);
    if (data) {
        var amount = data.amount || data.a || 0;
        this.set(amount, true);
        this.save(true);
    } else {
        this.init();
    }
};

Shields.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.SHIELDS, this.getInfo());
    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("shields");
    }
};

Shields.prototype.getInfo = function () {
    return {
        a: this.amount
    };
};

CustomSyncers.extractors.shields = function () {
    return cleverapps.shields.getInfo();
};

CustomSyncers.importerData.shields = function (data) {
    cleverapps.shields.load(data);
};

Shields.MAX_SHIELDS = 3;

DataLoader.TYPES.SHIELDS = "_shields";

cleverapps.InitByFeature["shields"] = function (isNewUser) {
    cleverapps.shields = new Shields(isNewUser);
}