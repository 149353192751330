/**
 * Created by Mikhail Menshenin on 24.07.2024
 */

var TutorialScenario = function (name, options) {
    this.name = name;
    this.options = options;
    this.priority = options.priority || 0;

    this.steps = [];
    this.stepNo = 0;
};

TutorialScenario.prototype.isAvailable = function () {
    return this.options.isAvailable();
};

TutorialScenario.prototype.isLastStep = function () {
    return this.stepNo === this.steps.length - 1;
};

TutorialScenario.prototype.start = function () {
    this.steps = this.options.listSteps();
    if (this.options.onStart) {
        this.options.onStart();
    }

    this.getCurrentStep().start();
};

TutorialScenario.prototype.startNextStep = function () {
    this.finishStep();
    
    this.stepNo++;

    var nextStep = this.getCurrentStep();
    nextStep.start();
};

TutorialScenario.prototype.logStepStart = function () {
    var name = this.name;
    if (this.options.prepareNameForLogging) {
        name = this.options.prepareNameForLogging(name);
    }
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.TUTORIAL_STEP + name + "_" + this.stepNo);
};

TutorialScenario.prototype.finishStep = function () {
    var current = this.getCurrentStep();
    if (current) {
        current.finish();
    }
};

TutorialScenario.prototype.getCurrentActiveStep = function () {
    var step = this.steps[this.stepNo];
    if (step && !step.isFinished()) {
        return step;
    }

    return undefined;
};

TutorialScenario.prototype.getCurrentStep = function () {
    return this.steps[this.stepNo];
};

TutorialScenario.prototype.setStep = function (step) {
    this.stepNo = step;
};

TutorialScenario.prototype.onFinishScenario = function () {
    this.finishStep();

    if (this.options.onFinish) {
        this.options.onFinish();
    }
};

TutorialScenario.BASIC_TUTORIAL_NAME = "basic";