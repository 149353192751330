/**
 * Created by Slava on 09.09.2024
 */

ToolbarItemView.prototype.createIcon = function () {
    if (this.model.getIcon().sprite) {
        return cleverapps.UI.createScale9Sprite(this.model.getIcon().sprite);
    }
    if (cleverapps.Spine.isAnimationLoaded(this.model.getIcon())) {
        var icon = new cleverapps.Spine(this.model.getIcon());
        icon.setAnimation(0, "idle", true);
        return icon;
    }
    return new cc.Sprite(this.model.getIcon());
}