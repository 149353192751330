/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var VillageScene = cleverapps.FixedWidthScene.extend({
    ctor: function () {
        this._super();

        this.village = cleverapps.meta.location;
    },

    onSceneLoaded: function (sceneName) {
        this._super(sceneName || cleverapps.Environment.SCENE_MAIN);

        cleverapps.environment.levelNo = levels.user.level;
        cleverapps.environment.episodeNo = levels.user.episode;

        cleverapps.placements.run(Placements.INTERMEDIATE);

        this.addVillage();

        this.addUI();

        this.upMenuContainer.avoidNode = "village";
        this.addAvoidNode(this.upMenuContainer);

        this.updateSize();

        this.resolutionMode = cleverapps.resolution.mode;

        if (aisensia.savedVillage) {
            aisensia.savedVillage = undefined;
        }

        var exclamationView = this.exclamationView = ExclamationViewBase.Create();
        this.addChild(exclamationView, 30);
        exclamationView.setPositionRound(cleverapps.styles.VillageScene.exclamation);

        cleverapps.UI.onClick(this, this.onClick.bind(this), {
            onDoubleClick: this.onDoubleClick.bind(this),
            interactiveScale: false
        });

        if (this.village.isStateBuild()) {
            this.village.checkAttention();
        }

        if (this.village.isFirstInit()) {
            this.firstInit();
        }
    },

    onClick: function (touch) {
        var touched = this.villageView.checkTouch(touch, false);

        this.sideBar.slots.forEach(function (slot) {
            if (touched && this.village.isStateBuild()) {
                slot.hide();
            } else {
                slot.show();
            }
        }.bind(this));
    },

    onDoubleClick: function (touch) {
        this.villageView.checkTouch(touch, true);
    },

    onResize: function () {
        if (this.resolutionMode !== cleverapps.resolution.mode) {
            this.resolutionMode = cleverapps.resolution.mode;
            this.onChangeResolutionMode();
        }

        this._super();
    },

    onChangeResolutionMode: function () {
        var bundle = this.village.getBackgroundBundle();
        cleverapps.bundleLoader.loadBundles([bundle], {
            onSuccess: function () {
                this.backgroundStyles = this.getBackgroundStyles();
                this.setBackground();
                this.addVillage();
            }.bind(this)
        });
    },

    addVillage: function () {
        var village = this.villageView = new VillageView(this.village);
        this.background.addChild(village);

        var control = new HidingNode(village, HidingNode.DIR.NONE);
        cleverapps.focusManager.registerControl("village", control);

        return control;
    },

    addUI: function () {
        this.addProgress();

        var styles = cleverapps.styles.VillageScene;

        var hammer = this.hammer = new VillageUpgradeToolbarItemView(new VillageUpgradeToolbarItem(this.village));
        hammer.avoidNode = "hammer";
        hammer.setPositionRound(styles.hammer.position);
        this.addChild(hammer);
        var control = new HidingNode(hammer, HidingNode.DIR.NONE);
        cleverapps.focusManager.registerControl("hammer", control);
    },

    addProgress: function () {
        var styles = cleverapps.styles.VillageScene;
        var progress = new VillageProgressView(this.village);
        progress.setPositionRound(styles.progress.position);
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            progress.setScale(0.8);
        }
        this.addChild(progress);

        var control = new HidingNode(progress, HidingNode.DIR.NONE);
        cleverapps.focusManager.registerControl("progress", control);

        return control;
    },

    firstInit: function () {
        cleverapps.focusManager.displayWhenFreeFocus({
            focus: "VillageBuildForce",
            control: "hammer",
            action: function (f) {
                cleverapps.forces.create(this.hammer, {
                    text: "Tutorial.CoinKingdom.HammerForce"
                });
                cleverapps.forces.onceForceClosed = f;
            }.bind(this)
        });
    },

    getBackgroundStyles: function () {
        var bundleName = this.village.getBackgroundBundle();
        return {
            bundle: bundleName,
            backgroundId: "background"
        };
    },

    getVillage: function () {
        if (aisensia && aisensia.villageManager) {
            return aisensia.villageManager.getVillage();
        }
    },

    isInCurrentLocation: function () {
        return this.village.isStateBuild();
    },

    listBundles: function () {
        var bundles = ["village"];

        if (this.village) {
            bundles.push(this.village.getBackgroundBundle());
            bundles.push(this.village.getBundleId());
        }

        return bundles;
    },

    _closeAction: MainScene.prototype._closeAction
});

cleverapps.styles.Exclamations.base = {
    width: 800,
    height: 250
};

cleverapps.styles.VillageScene = {
    exclamation: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    },

    title: {
        height: 80,
        padding: {
            x: 60
        },
        position: [{
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 280 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 265 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 265 }
        }]
    },

    hammer: {
        position: [{
            x: { align: "left", dx: 31 },
            y: { align: "bottom", dy: 20 }
        }, {
            x: { align: "left", dx: 103 },
            y: { align: "bottom", dy: 34 }
        }, {
            x: { align: "left", dx: 103 },
            y: { align: "bottom", dy: 34 }
        }]
    },

    progress: {
        position: [
            {
                x: { align: "center", dx: 10 },
                y: { align: "bottom", dy: 180 }
            }, {
                x: { align: "center", dx: 25 },
                y: { align: "bottom", dy: 140 }
            }, {
                x: { align: "center", dx: 25 },
                y: { align: "bottom", dy: 140 }
            }
        ]
    },

    shields: {
        position: [
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: -180 }
            },
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 0 }
            },
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 0 }
            }
        ]
    }
};

RestoreProgressButton.OverlappingNodes = RestoreProgressButton.OverlappingNodes.concat(["hammer", "VillageTitle", "village"]);
