/**
 * Created by Mikhail Menshenin on 20.08.2024
 */

cleverapps.Plot.onStartup = function (f, returnClassOnly) {
    var village = cleverapps.meta.location;
    if (village.isFirstInit()) {
        return this.onFirstInit(f, returnClassOnly);
    }

    if (returnClassOnly) {
        return CoinKingdomScene;
    }

    cleverapps.meta.wantsToPlay(f);
};

cleverapps.Plot.onFirstInit = function (f, returnClassOnly) {
    if (returnClassOnly) {
        return VillageScene;
    }

    cleverapps.meta.gotoMainLocation(f);
};