/**
 * Created by Mikhail Menshenin on 03.07.2024
 */

var SlotMachineButtonView = cc.Node.extend({
    ctor: function (slotMachine) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.slotMachine = slotMachine;

        this.background = new cleverapps.Spine(bundles.slotmachine.jsons.button_json);
        this.setContentSize(this.background.getContentSize());
        this.background.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.background);

        if (!this.slotMachine.autoSpin) {
            this.background.setAnimation(0, "idle", true);
            this.idle = "idle";
            this.state = SlotMachineButtonView.IDLE;
            this.animateIdle();
        } else {
            this.background.setAnimation(0, "blocked", true);
            this.idle = "blocked";
            this.state = SlotMachineButtonView.HOLD;
        }

        cleverapps.UI.onPressed(this, this.onPressed.bind(this), {
            interactiveScale: false
        });
        cleverapps.UI.onClick(this, this.onClicked.bind(this), {
            interactiveScale: false
        });
        cleverapps.UI.applyHover(this, {
            onMouseOver: this.onMouseOver.bind(this),
            onMouseOut: this.onMouseOut.bind(this)
        });

        this.slotMachine.on("spin", this.animatePressButton.bind(this));
    },

    animateUpButton: function () {
        this.state = SlotMachineButtonView.IDLE;
        this.slotMachine.autoSpin = false;
        this.background.stopAllActions();
        this.background.setAnimation(0, "up", false);
        this.idle = "idle";
        this.animateIdle();
    },

    animatePressButton: function (cb) {
        this.background.stopAllActions();
        this.background.runAction(new cc.Sequence(
            new cc.CallFunc(function() {
                if (!(this.state === SlotMachineButtonView.HOLD)) {
                    this.background.setAnimation(0, "down", false);
                }
            }.bind(this)),
            new cc.DelayTime(this.background.getAnimationDuration("down")),
            new cc.CallFunc(function () {
                if (cb) {
                    this.state = SlotMachineButtonView.IDLE;
                    cb();
                }
            }.bind(this)),
            new cc.DelayTime(1 - this.background.getAnimationDuration("down")),
            new cc.CallFunc(function () {
                if (this.state === SlotMachineButtonView.PRESSED) {
                    this.state = SlotMachineButtonView.HOLD;
                    this.slotMachine.autoSpin = true;
                    this.background.setAnimation(0, "change", true);
                    this.idle = "blocked";
                    this.animateIdle();
                }
            }.bind(this))
        ));
    },

    animateIdle: function () {
        this.background.stopAllActions();
        this.background.setAnimation(0, this.idle, true);

        if (this.idle === "idle") {
            this.background.runAction(new cc.repeatForever(
                new cc.Sequence(
                    new cc.CallFunc(function () {
                        this.background.setAnimation(0, this.idle, true);
                    }.bind(this)),
                    new cc.DelayTime(cleverapps.styles.SlotMachineButtonView.delay),
                    new cc.CallFunc(function() {
                        this.background.setAnimation(0, "inaction", false);
                    }.bind(this)),
                    new cc.DelayTime(this.background.getAnimationDuration("inaction"))
                )
            ));
        }
    },

    onClicked: function () {
        if (this.state === SlotMachineButtonView.PRESSED) {
            this.state = SlotMachineButtonView.IDLE;
            this.slotMachine.autoSpin = false;
            this.animateUpButton();
        }
    },

    onPressed: function () {
        if (this.state === SlotMachineButtonView.HOLD) {
            this.animateUpButton();
            return;
        }

        this.state = SlotMachineButtonView.PRESSED;
        var isForce = cleverapps.forces.isRunningForce();

        if (this.slotMachine.spin(isForce)) {
            if (isForce) {
                cleverapps.force.closeRunningForce();
            }
            cleverapps.audio.playSound(bundles.game.urls.slot_click);
        }
    },

    onMouseOver: function () {
        if (this.state === SlotMachineButtonView.IDLE) {
            this.idle = "hover";
            this.animateIdle();
        }
    },

    onMouseOut: function () {
        if (this.state === SlotMachineButtonView.IDLE) {
            this.idle = "idle";
            this.animateIdle();
        }
    }
});

SlotMachineButtonView.IDLE = 0;
SlotMachineButtonView.PRESSED = 1;
SlotMachineButtonView.HOLD = 2;

cleverapps.styles.SlotMachineButtonView = {
    delay: 3
};