/**
 * Created by Mikhail Menshenin on 23.01.2025
 */

cleverapps.User.prototype.initializeNewPlayerSoft = function () {
    this.soft = 7500;
};

levels.User.prototype.getFloatLevel = function () {
    var village = cleverapps.meta.location || new Village(0);
    var level = parseInt(village.locationId);
    var progress = village.getProgress();

    return level + Math.min(1, progress.current / progress.goal);
};

levels.User.prototype.parseFloatLevel = function (floatingLevel) {
    var data = { episodeNo: 0, levelNo: 0 };
    data.levelNo = Math.floor(floatingLevel);
    return data;
};