/**
 * Created by Mikhail Menshenin on 18.07.2024
 */

SettingsWindow.prototype.onWindowLoaded = cleverapps.extendFunc(SettingsWindow.prototype.onWindowLoaded, function () {
    var typeOrigin = cleverapps.config.type;
    if (levels.user.checkAvailable({ level: 0.07 })) {
        cleverapps.config.type = "match3"; // To add Give Up button
    }
    this._super();
    cleverapps.config.type = typeOrigin;
});