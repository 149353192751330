/**
 * Created by Vladislav on 04.09.2024.
 */

RewardTypes.stickers = {
    flyingAnimation: Reward.WINDOW_ANIMATION,

    getIcon: function () {
        return bundles.reward_icons.frames.stickers_png;
    },

    handler: function (value, options) {
        options = options || {};
        var source;

        if (options.event === cleverapps.EVENTS.EARN.LEVEL_REWARD || options.event === cleverapps.EVENTS.EARN.BONUS_LEVEL_REWARD) {
            source = Sticker.SOURCES.level;
        } else if (options.event && options.event.indexOf("stickerspack") !== -1) {
            source = Sticker.SOURCES.buy;
        }

        var stickers = cleverapps.stickersBook.givePrize(value, source);

        return function () {
            new StickersRewardWindow(stickers);
        };
    }
};