/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var Village = function (locationId) {
    var level = parseInt(("" + locationId).split("_")[0]);
    var config = this.config = cleverapps.meta.logic.getConfig(level);

    this.counter = new Counter();

    this.state = Village.STATE_BUILD;
    this.gold = 0;
    this.attention = 0;
    this.level = level;
    this.progress = 0;
    this.cost = Village.calculateVillageTotalCost(level);
    this.upgradeTool = false;

    this.name = config.name;
    this.huts = config.huts ? config.huts.map(function (hutData, index) {
        return new Hut(this, hutData, index);
    }, this) : [];

    this.crosses = cleverapps.rangeArray(0, 3).map(function (index) {
        return new Cross(this, index);
    }, this);

    BaseLocation.call(this, locationId);

    this.checkAttention();
    this.huts.forEach(function (hut) {
        hut.on("changeUpgradeable", this.checkAttention.bind(this));
    }.bind(this));

    if (cleverapps.config.debugMode) {
        this.checkHutExists();
    }
};

Village.prototype = Object.create(BaseLocation.prototype);
Village.prototype.constructor = Village;

Village.prototype.getBundleId = function () {
    return "village_" + this.level;
};

Village.prototype.openUpgradeWindow = function () {
    cleverapps.focusManager.display({
        focus: "VillageUpgradeWindow",
        control: ["MenuBarCoinsItem"],
        actions: [
            function (f) {
                new VillageUpgradeWindow(this);
                cleverapps.focusManager.onceNoWindowsListener = f;
            }.bind(this),
            function (f) {
                f();
            }
        ]
    });
};

Village.prototype.changeUpgradeTools = function (isOpen) {
    this.upgradeTool = isOpen;
    this.huts.forEach(function (hut) {
        hut.setUpgradeTool(isOpen);
    });
};

Village.prototype.getInfo = function () {
    var huts = [];
    this.huts.forEach(function (hut) {
        var info = hut.getInfo();
        huts.push(info);
        return huts;
    });

    var crosses = this.crosses.map(function (cross) {
        return cross.getInfo();
    }, {});

    return {
        huts: huts,
        crosses: crosses,
        raids: this.raids,
        gold: this.gold,
        goldCollected: this.goldCollected,
        level: this.level,
        locationId: this.locationId,
        state: this.state
    };
};

Village.prototype.load = function (data) {
    if (!data || !Object.keys(data).length) {
        if (!Number.isInteger(this.locationId) && this.locationId.indexOf("_") !== -1) {
            data = Village.generate(this.config);
        } else {
            return;
        }
    }

    this.gold = data.gold || this.gold;
    this.goldCollected = data.goldCollected || this.goldCollected;
    this.state = data.state || this.state;
    this.level = data.level || this.level;

    if (data.huts && data.huts.length) {
        this.huts = data.huts.map(function (hutData, index) {
            return new Hut(this, hutData, index);
        }, this);

        this.huts.forEach(function (hut) {
            hut.on("changeUpgradeable", this.checkAttention.bind(this));
        }.bind(this));
    }

    var hutNames = data.huts && Object.keys(data.huts) || [];
    hutNames.forEach(function (hutName) {
        var hutData = data.huts[hutName];
        var hut = this.huts.find(function (hut) {
            return hut.id === data.huts[hutName].id;
        });
        if (hut) {
            hut.load(hutData);
        }
    }.bind(this));

    var crosses = data.crosses || [];
    crosses.forEach(function (cross, index) {
        this.crosses[index].raided = cross.raided;
    }.bind(this));

    this.raids = data.raids || this.raids;
    this.progress = this.getCurrentProgress();
};

Village.prototype.getCurrentPlayer = function () {
    var playerInfo = cleverapps.user.getPlayerInfo();

    return {
        player: true,
        id: connector.platform.getUserID(),
        name: playerInfo.name,
        avatar: playerInfo.avatar
    };
};

Village.prototype.prepareToAttack = function () {
    this.state = Village.STATE_ATTACK;
};

Village.prototype.prepareToRaid = function () {
    var player = cleverapps.villagePlayers.getRaidPlayer();
    this.gold = player.gold;
    this.state = Village.STATE_RAID;
    this.raids = Village.MAX_RAIDS;
    this.goldCollected = 0;
    var smallGold = Math.floor(this.gold / 1000 / 6) * 1000;
    var bigGold = this.gold - 2 * smallGold;
    var crossesGold = cleverapps.Random.shuffle([0, smallGold, smallGold, bigGold]);
    this.perfectRaid = true;

    this.crosses.forEach(function (cross, index) {
        cross.raided = false;
        cross.gold = crossesGold[index];
    });
};

Village.prototype.getCurrentProgress = function () {
    return this.huts.reduce(function (progress, hut) {
        return progress + hut.getCurrentLevel();
    }, 0);
};

Village.prototype.getGoal = function () {
    return this.huts.reduce(function (goal, hut) {
        return goal + hut.getMaxLevel();
    }, 0);
};

Village.prototype.checkAttention = function () {
    var attention = this.huts.reduce(function (attention, hut) {
        hut.checkUpgradeable();
        return attention + (hut.upgradeable ? 1 : 0);
    }, 0);

    if (this.attention !== attention) {
        this.attention = attention;
        this.trigger("changeAttention");
    }
};

Village.prototype.onHutClick = function (hut) {
    if (this.counter.isActive()) {
        return;
    }

    if (this.isStateBuild() && !hut.upgradeTool) {
        this.changeUpgradeTools(true);
        return;
    }

    if (hut.upgradeTool) {
        this.counter.inc();
        hut.upgrade(function () {
            this.counter.dec();
        }.bind(this));
        return;
    }

    if (this.canAttack() && hut.canAttack()) {
        this.attack(hut);
    }
};

Village.prototype.onHutDoubleClick = function (hut) {
    if (this.counter.isActive()) {
        return;
    }

    if (hut.isComplete()) {
        return;
    }

    if (this.isStateBuild()) {
        this.counter.inc();
        hut.upgrade(function () {
            this.counter.dec();
        }.bind(this));
    }
};

Village.prototype.attack = function (hut) {
    var p = Math.random();

    if (cleverapps.config.debugMode) {
        if (cleverapps.flags.get("villageAttackMode")) {
            if (cleverapps.flags.get("villageAttackMode") === "assault") {
                p = Village.DEFENSE_PROBABILITY + 1;
            } else {
                p = Village.DEFENSE_PROBABILITY - 1;
            }
            cleverapps.flags.set("villageAttackMode", undefined);
        }
    }

    if (p <= Village.DEFENSE_PROBABILITY) {
        this.defense();
    } else {
        this.assault(hut);
    }
};

Village.prototype.assault = function (hut) {
    this.state = Village.STATE_ASSAULT;
    var villageLevel = this.level;
    this.goldCollected = Village.GOLD_BY_ATTACK[villageLevel] || Village.GOLD_BY_ATTACK[Village.GOLD_BY_ATTACK.length - 1];

    hut.setDamage(hut.damage + 1, true);

    cleverapps.focusManager.display({
        focus: "VillageAttackAnimation",
        control: ["village"],
        actions: [
            function (f) {
                this.counter.inc();
                this.trigger("assault", f);
            }.bind(this),
            function (f) {
                hut.attack(f);
            },
            function (f) {
                this.counter.dec();
                f();
            }.bind(this)
        ]
    });
};

Village.prototype.defense = function () {
    this.state = Village.STATE_DEFENSE;
    var villageLevel = this.level;
    this.goldCollected = Village.GOLD_BY_DEFEND[villageLevel] || Village.GOLD_BY_DEFEND[Village.GOLD_BY_DEFEND.length - 1];

    cleverapps.focusManager.display({
        focus: "VillageDefenseAnimation",
        control: ["village"],
        actions: [
            function (f) {
                this.counter.inc();
                this.trigger("defense", f);
            }.bind(this),

            function (f) {
                this.counter.dec();
                f();
            }.bind(this)
        ]
    });
};

Village.prototype.isAttacked = function () {
    return this.state === Village.STATE_ASSAULT;
};

Village.prototype.onCrossClick = function (cross) {
    if (this.counter.isActive()) {
        return;
    }

    if (!cross.raided && this.canRaid()) {
        this.dig(cross);
    }
};

Village.prototype.dig = function (cross) {
    var gold = cross.gold;
    this.perfectRaid = this.perfectRaid && !!gold;

    cleverapps.focusManager.display({
        focus: "VillageDigAnimation",
        control: ["village"],
        actions: [
            function (f) {
                this.counter.inc();
                this.raids--;
                this.trigger("changeRaids");
                f();
            }.bind(this),
            function (f) {
                cross.dig(f);
            },
            function (f) {
                this.trigger("goldDigged", gold, f);
            }.bind(this),
            function (f) {
                if (gold) {
                    this.goldCollected += gold;
                    this.trigger("changeGoldCollected", f);
                    this.trigger("animateGoldHighlight");
                } else {
                    f();
                }
            }.bind(this),
            function (f) {
                this.counter.dec();
                f();
            }.bind(this)
        ]
    });
};

Village.prototype.updateProgress = function () {
    var progress = this.getCurrentProgress();

    if (this.progress !== progress) {
        this.progress = progress;

        this.save();

        this.trigger("changeProgress", this.checkGoal.bind(this));
    }
};

Village.prototype.checkGoal = function () {
    var goal = this.getGoal();

    if (this.progress >= goal) {
        Village.moveNextLocation();
    }
};

Village.prototype.isRaidFinished = function () {
    return !this.raids;
};

Village.prototype.isStateBuild = function () {
    return this.state === Village.STATE_BUILD;
}

Village.prototype.isStateAttack = function () {
    return this.state === Village.STATE_ATTACK;
}

Village.prototype.isStateRaid = function () {
    return this.state === Village.STATE_RAID;
}

Village.prototype.defended = function () {
    return this.state === Village.STATE_DEFENSE;
}

Village.prototype.checkHutExists = function () {
    var bundle = bundles[this.getBundleId()];
    var frames = cleverapps.unique(Object.keys(bundle.frames).map(function (frame) { return frame.slice(0, frame.lastIndexOf("_")); }));
    var jsons = Object.keys(bundle.jsons).filter(function (json) { return json.indexOf("vertical_") === 0; }).map(function (json) { return json.slice(9); });
    var existHuts = this.huts.filter(function (hut) {
        return frames.indexOf(hut.name) !== -1 || jsons.indexOf(hut.name) !== -1;
    });
    var existHutNames = existHuts.map(function (hut) { return hut.name; });
    var addHuts = cleverapps.unique(cleverapps.substract(frames.concat(jsons), existHutNames));
    this.huts = existHuts.concat(addHuts.map(function (name, index) {
        return new Hut(this, { name: name }, index);
    }.bind(this)));
    this.huts.forEach(function (hut, index) {
        hut.id = index;
    });
};

Village.prototype.canAttack = function () {
    return this.isStateAttack();
};

Village.prototype.canRaid = function () {
    return this.isStateRaid() && this.raids > 0;
};

Village.prototype.receiveGold = function () {
    var reward = {
        soft: this.goldCollected
    };
    var event = this.isStateRaid() ? cleverapps.EVENTS.EARN.VILLAGE_RAID : cleverapps.EVENTS.EARN.VILLAGE_ATTACK;
    var rewardList = new RewardsList(reward, { event: event });
    rewardList.receiveRewards();
    rewardList.onAnimationFinished();
};

if (cleverapps.config.debugMode) {
    Village.prototype.attackSomeone = function () {
        var hut = cleverapps.Random.choose(this.huts.filter(function (hut) {
            return hut.canAttack();
        }));

        this.attack(hut);
    };
}

Village.prototype.getSlotId = function () {
    return this.locationId;
};

Village.calculateVillageTotalCost = function (levelNo) {
    if (levelNo < 14) {
        return (3900000 + levelNo * 4500000) / 10;
    }

    levelNo = levelNo + 1;
    var price = -37100000 + 17400000 * levelNo - 1140000 * Math.pow(levelNo, 2) + 35467 * Math.pow(levelNo, 3) - 466 * Math.pow(levelNo, 4) + 2.87 * Math.pow(levelNo, 5) - 0.00434 * Math.pow(levelNo, 6)
    return price / 10;
};

Village.MAX_RAIDS = 3;

Village.STATE_BUILD = 1;
Village.STATE_ATTACK = 2;
Village.STATE_ASSAULT = 21;
Village.STATE_DEFENSE = 22;
Village.STATE_RAID = 3;

Village.DEFENSE_PROBABILITY = 0.5;

Village.GOLD_BY_ATTACK = [
    20000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 55000, 60000,
    65000, 65000, 70000, 70000, 75000, 80000, 82500, 82500, 82500, 85000,
    85000, 85000, 85000, 85000, 87500, 87500, 87500, 87500, 87500, 90000,
    90000, 90000, 90000, 90000, 95000, 95000, 95000, 95000, 95000, 100000,
    100000, 100000, 100000, 100000, 100000, 110000, 110000, 110000, 110000, 120000,
    120000, 120000, 120000, 120000, 120000, 120000, 120000, 120000, 120000, 130000,
    130000, 130000, 130000, 130000, 130000, 130000, 130000, 130000, 130000, 135000,
    135000, 135000, 135000, 135000, 135000, 135000, 135000, 135000, 135000, 135000,
    135000, 135000, 135000, 135000, 140000, 140000, 140000, 140000, 140000, 140000,
    140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000,
    140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000,
    140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000,
    145000, 145000, 145000, 145000, 145000, 145000, 145000, 145000, 145000, 145000,
    145000, 145000, 145000, 145000, 150000, 150000, 150000, 150000, 150000, 150000,
    150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 360000,
    360000, 360000, 360000, 360000, 400000, 400000, 400000, 400000, 460000, 460000,
    460000, 460000, 460000, 460000, 540000, 540000, 540000, 540000, 540000, 600000,
    600000, 600000, 600000, 600000, 700000, 700000, 700000, 700000, 700000, 800000,
    800000, 800000, 800000, 800000, 900000, 900000, 900000, 900000, 900000, 1000000,
    1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000];

Village.GOLD_BY_DEFEND = [
    5000, 5000, 7500, 10000, 10000, 10000, 10000, 15000, 15000, 20000,
    20000, 20000, 20000, 20000, 20000, 20000, 22500, 22500, 22500, 25000,
    25000, 25000, 25000, 25000, 25000, 25000, 25000, 25000, 25000, 25000,
    27500, 27500, 27500, 27500, 27500, 27500, 27500, 27500, 27500, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 35000, 35000, 35000, 35000, 35000, 40000,
    40000, 40000, 40000, 40000, 50000, 50000, 50000, 50000, 50000, 100000,
    100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000,
    100000, 100000, 100000, 100000, 200000, 200000, 200000, 200000, 200000, 200000,
    200000, 200000, 200000, 200000, 200000, 200000, 200000, 200000, 200000, 300000,
    300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000,
    300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000
];

Village.prototype.amountLevelStars = function (level) {
    return level.isHard() ? 3 : 1;
};

Village.prototype.getProgress = function () {
    return {
        current: this.getCurrentProgress(),
        goal: this.getGoal()
    };
};

Village.prototype.gamePlayed = function (outcome, game) {
    throw new Error("SlotMachine game never ends. Are you sure about game played?");
};

Village.prototype.getConfig = function () {
    return this.config;
};

Village.prototype.processChatMessage = function (message) {
    console.warn("chat message processing doesn't implemented");
};

Village.prototype.onOpen = function (f) {
    cleverapps.scenes.replaceScene(new VillageScene(), f);
};

Village.generate = function (config) {
    var data = {};

    data.huts = [];
    config.huts.forEach(function (hut) {
        var level = cleverapps.Random.random(0, Hut.MAX_LEVEL);

        var dataHut = {
            level: level,
            name: hut.name
        };
        if (level) {
            var damage = cleverapps.Random.random(0, level - 1);
            if (damage) {
                dataHut.damage = damage;
            }
        }
        data.huts.push(dataHut);
    });

    return data;
};

Village.prototype.getBackgroundBundle = function () {
    return (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? "village_background_vertical_" : "village_background_horizontal_") + this.level;
}

Village.prototype.getPlayer = function () {
    var locationId = "" + this.locationId;
    var playerId = locationId.split("_").slice(1).join("_");
    return playerId ? cleverapps.villagePlayers.getPlayer(playerId) : this.getCurrentPlayer();
};

Village.prototype.isFirstInit = function () {
    return this.locationId == 0 && this.progress === 0;
};

Village.prototype.isFirstUpgrade = function () {
    return this.locationId == 0 && this.progress === 1;
};

Village.prototype.upgradeHut = function (hut, price, cb) {
    cleverapps.focusManager.displayWhenFreeFocus({
        focus: "VillageHutUgrade",
        control: ["MenuBarCoinsItem", "village"],
        actions: [
            function (f) {
                hut.trigger("upgrade", price, f);
            },
            function (f) {
                if (this.isFirstUpgrade()) {
                    var scene = cleverapps.scenes.getRunningScene();
                    cleverapps.forces.create(ForceView.NO_ELEMENT, {
                        text: "Tutorial.CoinKingdom.FirstUpgradeForce"
                    }, {
                        highlights: [scene.villageView]
                    });
                    cleverapps.forces.onceForceClosed = f;
                } else {
                    f();
                }
            }.bind(this),
            function (f) {
                cb();
                f();
            }
        ]
    });
};

Village.getMaxLevel = function () {
    return Math.max.apply(Math, Object.keys(cleverapps.meta.logic.getConfigs()));
};

Village.canMoveNext = function () {
    var village = cleverapps.meta.location;

    return village.level < this.getMaxLevel();
};

Village.moveNextLocation = function (reset) {
    if (!this.canMoveNext()) {
        cleverapps.focusManager.display({
            focus: "NextVillageScene",
            control: [],
            action: function (f) {
                new AllDoneWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
        return;
    }

    cleverapps.focusManager.display({
        focus: "NextVillageScene",
        control: [],
        actions: [
            function (f) {
                if (reset) {
                    cleverapps.meta.reset();
                } else {
                    cleverapps.meta.moveNextLocation();
                }
                cleverapps.meta.location.save();
                f();
            },
            function (f) {
                cleverapps.scenes.replaceScene(new VillageScene(), f);
            }
        ]
    });
};
