/**
 * Created by Mikhail Menshenin on 25.06.2024
 */

cleverapps.override(cleverapps.Availables, {
    DAILY_TASKS: {
        level: 1.1
    },
    PROLONGATION_OFFER: {
        level: 0.2
    },
    YATZY_COMBO: {
        level: 0.2
    },
    FREE_PROLONGATION_FORCE: {
        level: 0.2
    },
    STICKERS_BOOK: {
        level: 1.1
    }
});

if (cleverapps.MiniGame) {
    cleverapps.MiniGame.AVAILABLE = {
        level: 0.5,
        registered: "1 hour",
        epicart: {
            disabled: true
        }
    };
}
