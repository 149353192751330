/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var HutView = cc.Node.extend({
    avoidNode: "HutView",

    ctor: function (hut) {
        this._super();
        this.hut = hut;

        this.setAnchorPoint(0.5, 0.5);

        this.createImage();

        if (this.hut.canBuild()) {
            this.createIcon();
        }

        if (this.hut.village.canAttack() && this.hut.canAttack()) {
            this.createScope();
        }

        cleverapps.UI.wrap(this);

        this.hut.setUpgradeTool(false);
        this.checkUpgradeable();
        this.setupChildren();

        hut.on("changeLevel", this.updateImage.bind(this), this);
        hut.on("upgrade", this.upgradeAnimation.bind(this), this);
        hut.on("attack", this.attackAnimation.bind(this), this);
        hut.on("changeUpgradeable", this.checkUpgradeable.bind(this), this);
        hut.on("setUpgradeTool", this.updateUpgradeTool.bind(this), this);
    },

    createIcon: function () {
        var styles = cleverapps.styles.HutView;
        var icon = this.icon = new cc.Sprite(bundles.village.frames.build_png);
        icon.setPositionRound(styles.icon);
        icon.setScale2(styles.icon.scale);
        this.addChild(icon, 10);
        this.icon = new HidingNode(icon, HidingNode.DIR.NONE);
        cleverapps.focusManager.registerControl("buildIcon" + this.hut.id, this.icon);
    },

    checkClick: function (touch, isDouble) {
        var icon = this.icon || this.targetIcon;
        if (icon) {
            var size = icon.getContentSize();
            var rect = cc.rect(0, 0, size.width, size.height);
            if (cc.rectContainsPoint(rect, icon.convertToNodeSpace(touch.getLocation())) || cc.rectContainsPoint(rect, icon.convertToNodeSpace(touch.getStartLocation()))) {
                if (isDouble) {
                    this.hut.onDoubleClick();
                } else {
                    this.hut.onClick();
                }
                return true;
            }
        }
        return false;
    },

    updateUpgradeTool: function (isOpen) {
        if (!isOpen && this.bg) {
            this.bg.runAction(new cc.Sequence(
                new cc.Spawn(
                    new cc.ScaleTo(0.2, 0),
                    new cc.MoveBy(0.2, 0, -this.bg.height / 2)
                ),
                new cc.CallFunc(function () {
                    this.bg.removeFromParent();
                    delete this.bg;
                }.bind(this))
            ));
        }
        if (isOpen && !this.bg) {
            var bg = this.bg = this.createUpgradeTool();

            bg.setScale(0);
            bg.runAction(new cc.Spawn(
                new cc.MoveBy(0, 0, -bg.height / 2),
                new cc.ScaleTo(0.2, 1),
                new cc.MoveBy(0.2, 0, bg.height / 2)
            ));
        }
    },

    iconAnimation: function () {
        this.icon.setScale(0);
        this.icon.runAction(new cc.Spawn(
            new cc.MoveBy(0, 0, -this.icon.height / 2),
            new cc.ScaleTo(0.2, 1),
            new cc.MoveBy(0.2, 0, this.icon.height / 2)
        ));
    },

    createUpgradeTool: function () {
        var styles = cleverapps.styles.HutView;
        var bg = cleverapps.UI.createScale9Sprite(bundles.village.frames.upgrade_bg);
        bg.setContentSize(styles.upgrade);
        bg.setPositionRound(styles.upgrade);
        this.addChild(bg, 10);

        var stars = [];
        var starFrame = bundles.village.frames.star_png;
        var starShadowFrame = bundles.village.frames.star_shadow_png;
        for (var i = 0; i < Hut.MAX_LEVEL; i++) {
            var star = new cc.Sprite(i < this.hut.level ? starFrame : starShadowFrame);
            star.setAnchorPoint(0.5, 0.5);
            star.setScale(styles.upgrade.stars.scale);
            stars.push(star);
        }
        var layout = new cleverapps.Layout(stars, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.upgrade.stars.margin
        });
        layout.setPositionRound(styles.upgrade.stars);
        bg.addChild(layout);

        var price = this.hut.getPrice();
        var priceText = TextWithIcon.ICONS_BY_NAME[price.type] + aisensia.getNumberWithCommas(price.amount);
        var priceNode = new TextWithIcon(priceText, {
            margin: styles.upgrade.text.margin,
            font: cleverapps.styles.FONTS.HUT_PRICE_TEXT
        });
        priceNode.fitTo(styles.upgrade.width - styles.upgrade.padding);
        priceNode.setPositionRound(styles.upgrade.text);
        bg.addChild(priceNode);

        return bg;
    },

    createScope: function () {
        var styles = cleverapps.styles.HutView;
        var targetIcon = this.targetIcon = new HutScopeView(this.hut);
        targetIcon.setPositionRound(styles.scope);
        this.addChild(targetIcon);
        var control = new HidingNode(targetIcon);
        cleverapps.focusManager.registerControl("villageScope" + this.hut.id, control);
    },

    setupChildren: function () {
        var styles = cleverapps.styles.HutView;
        var positions = styles.villages[this.hut.village.level] || styles.villages[this.hut.village.level % 2];
        var position = positions[this.hut.id];
        this.setPositionRound(position);
    },

    createImage: function () {
        var styles = cleverapps.styles.HutView.image;
        var frame = this.hut.getFrame();
        if (frame) {
            this.image = new cc.Sprite(frame);
            this.image._debugExclude = true;
            this.image.setPositionRound(styles);
            this.addChild(this.image);
            cleverapps.UI.wrap(this);
        } else {
            this.image = undefined;
        }
    },

    updateImage: function () {
        if (this.image) {
            this.image.removeFromParent();
        }

        this.createImage();
    },

    upgradeAnimation: function (price, f) {
        this.animateChangeLevel(f);

        if (price) {
            var icon = TextWithIcon.ICONS_BY_NAME[price.type];
            cleverapps.aims.showDelta("-" + price.amount + " " + icon, this, {
                x: this.image.width / 2,
                y: this.image.height,
                font: cleverapps.styles.FONTS.HUT_UPGRADE_PRICE_TEXT
            })
        }
    },

    animateChangeLevel: function (callback, withoutCloud) {
        var oldImage = this.image;
        this.createImage();

        if (!oldImage) {
            oldImage = new cc.Node();
            oldImage.setContentSize2(this.image.getContentSize());
            oldImage.setPosition(this.image.getPosition());
            this.addChild(oldImage);
            oldImage.setVisible(false);
        }

        if (this.hut.isDestroyed()) {
            this.image.setVisible(false);
            oldImage.runAction(new cc.Spawn(
                    AnimationsLibrary.disappear(oldImage, {
                        dust: true,
                        keepVisible: false
                    }),
                    new cc.Sequence(
                        new cc.DelayTime(0.6),
                        new cc.CallFunc(function () {
                            this.image.setVisible(true);
                        }.bind(this))
                    )
            ));
        } else {
            this.icon && this.icon.toggle(false);
            oldImage.runAction(AnimationsLibrary.upgrade(oldImage, this.image, {
                cloud: !withoutCloud
            })).setFinalize(function () {
                this.icon && this.icon.toggle(!this.hut.isComplete());
                this.icon && this.iconAnimation();
                if (this.hut.village.upgradeTool) {
                    this.hut.setUpgradeTool(true);
                }
                callback && callback();
            }.bind(this));
        }
    },

    attackAnimation: function (f) {
        this.animateChangeLevel(f, true);

        var styles = cleverapps.styles.HutView;
        var spine = new cleverapps.Spine(bundles.village.jsons.small_lightning_json);
        spine.setPositionRound(styles.lightning);
        this.addChild(spine);
        spine.setAnimation(0, "animation", false);
        spine.setCompleteListenerRemove();

        var smoke = new cleverapps.Spine(bundles.village.jsons.smoke_json);
        smoke.setPositionRound(styles.smoke);
        smoke.setLocalZOrder(-1);
        this.addChild(smoke);
        smoke.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(0.2),
            new cc.Show(),
            new cc.CallFunc(function () {
                smoke.setAnimation(0, "smoke", true);
            })
        ));
    },

    checkUpgradeable: function () {
        if (this.icon) {
            if (this.hut.upgradeable) {
                this.icon.target.setSpriteFrame(bundles.village.frames.build_png);
            } else {
                this.icon.target.setSpriteFrame(bundles.village.frames.build_grey_png);
            }
            this.icon.toggle(!this.hut.isComplete(), true);
        }
    },

    spawn: function () {
        this.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(this.hut.id * 0.2),
            AnimationsLibrary.showUp(this)
        ));
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    HUT_LEVEL_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.LIFE_GREEN
    },

    HUT_UPGRADE_PRICE_TEXT: {
        name: "lightstroke",
        size: 40,
        color: new cc.Color(255, 204, 0),
        stroke: {
            color: new cc.Color(110, 23, 0),
            size: 3
        },
        shadow: undefined
    },

    HUT_DAMAGE_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.COLOR_RED
    },

    HUT_PRICE_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        name: "nostroke"
    }
});

cleverapps.styles.HutView = {
    width: [300, 390, 420],
    height: [300, 252, 252],

    icon: {
        x: { align: "center" },
        y: { align: "center" },
        scale: [0.73, 0.57, 0.57],
    },

    upgrade: {
        x: { align: "center" },
        y: { align: "center", dy: 100 },
        width: 225,
        height: 100,
        padding: 30,

        stars: {
            margin: 5,
            x: { align: "center" },
            y: { align: "center", dy: -15 },
            scale: 0.6
        },

        text: {
            x: { align: "center" },
            y: { align: "center", dy: 20 },
            margin: 10
        }
    },

    villages: [],

    attention: {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -20 },

        scale: 0.5
    },

    image: {
        x: { align: "center" },
        y: { align: "center" }
    },

    scope: {
        x: { align: "center" },
        y: { align: "center" }
    },

    lightning: {
        x: { align: "center" },
        y: { align: "center" }
    },

    smoke: {
        x: { align: "center" },
        y: { align: "center" }
    }
};

RestoreProgressButton.OverlappingNodes = RestoreProgressButton.OverlappingNodes.concat("HutView");