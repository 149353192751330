/**
 * Created by olga on 05.02.2025
 */

cleverapps.BorderRenderer = {
    place: function (area, imagePlacement, options) {
        options = options || {};
        this.areaObject = this._createAreaObject(area);
        this.AxisMultiplier = options.yAxisUp ? -1 : 1;

        (options.part || area).forEach(function (cell) {
            var borders = [];
            Object.keys(cleverapps.BorderRenderer.DIRS).forEach(function (dir) {
                if (!this.hasNeighbor(cell, dir)) {
                    borders.push(dir);
                }
            }.bind(this));

            var allCorners = cleverapps.BorderRenderer.CORNERS.concat(cleverapps.BorderRenderer.INNER_CORNERS);

            for (var i = 0; i < allCorners.length; i++) {
                var corner = allCorners[i];
                var isValid = true;

                if (corner.isNeighbor) {
                    isValid = corner.isNeighbor.every(function (dir) {
                        return this.hasNeighbor(cell, dir);
                    }.bind(this));
                }

                if (isValid && corner.noNeighbor) {
                    isValid = corner.noNeighbor.every(function (dir) {
                        return !this.hasNeighbor(cell, dir);
                    }.bind(this));
                }

                if (isValid) {
                    borders.push(corner.name);
                }
            }

            if (borders.length) {
                imagePlacement(borders, cell, options);
            }
        }.bind(this));
    },

    hasNeighbor: function (cell, direction) {
        var dir = this.getDirection(direction);

        var neighbor = {
            x: cell.x + dir.x,
            y: cell.y + dir.y * this.AxisMultiplier
        };
        return this.areaObject[neighbor.x] && this.areaObject[neighbor.x][neighbor.y];
    },

    getDirection: function (name) {
        return cleverapps.BorderRenderer.DIRS[name] || cleverapps.BorderRenderer.DIAGONAL_DIRS[name];
    },

    _createAreaObject: function (area) {
        var areaObject = {};
        area.forEach(function (cell) {
            areaObject[cell.x] = areaObject[cell.x] || {};
            areaObject[cell.x][cell.y] = true;
        });
        return areaObject;
    }
};

cleverapps.BorderRenderer.DIRS = {
    up: { y: -1, x: 0 },
    right: { y: 0, x: 1 },
    down: { y: 1, x: 0 },
    left: { y: 0, x: -1 }
};

cleverapps.BorderRenderer.DIAGONAL_DIRS = {
    up_right: { y: -1, x: 1 },
    up_left: { y: -1, x: -1 },
    down_right: { y: 1, x: 1 },
    down_left: { y: 1, x: -1 }
};

cleverapps.BorderRenderer.CORNERS = [
    { name: "up_left", noNeighbor: ["up", "left", "up_left"] },
    { name: "up_right", noNeighbor: ["up", "right", "up_right"] },
    { name: "down_left", noNeighbor: ["down", "left", "down_left"] },
    { name: "down_right", noNeighbor: ["down", "right", "down_right"] }
];

cleverapps.BorderRenderer.INNER_CORNERS = [
    { name: "inner_up_left", noNeighbor: ["up"], isNeighbor: ["up_left"] },
    { name: "inner_up_right", noNeighbor: ["up"], isNeighbor: ["up_right"] },
    { name: "inner_down_left", noNeighbor: ["down"], isNeighbor: ["down_left"] },
    { name: "inner_down_right", noNeighbor: ["down"], isNeighbor: ["down_right"] }
];

// corners types:  https://gyazo.com/f801034658bc513c86021e831a15a6e9
