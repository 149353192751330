/**
 * Created by Mikhail Menshenin on 21.08.2024
 */

var CoinMasterView = cc.Node.extend({
    ctor: function () {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        var styles = cleverapps.styles.CoinMasterView;
        this.setContentSize2(styles.size);

        var name = this.name = cleverapps.UI.generateOnlyText("Jonny", cleverapps.styles.FONTS.COINMASTER_NAME_TEXT);
        name.setAnchorPoint(0, 0.5);
        name.setPositionRound(styles.name);
        this.addChild(name);

        var gold = this.gold = cleverapps.UI.generateOnlyText("876,000", cleverapps.styles.FONTS.COINMASTER_GOLD_TEXT);
        gold.setAnchorPoint(0, 0.5);
        gold.setPositionRound(styles.gold);
        this.addChild(gold);

        cleverapps.villagePlayers.on("update", this.updateRaidPlayer.bind(this), this);
        this.updateRaidPlayer();
    },

    updateRaidPlayer: function () {
        var styles = cleverapps.styles.CoinMasterView;
        var player = cleverapps.villagePlayers.getRaidPlayer();
        var name = player.name;
        var gold = player.gold;

        if (this.avatar) {
            this.avatar.removeFromParent();
        }

        var avatar = this.avatar = new cleverapps.UI.Avatar(player);
        avatar.setPositionRound(styles.avatar);
        avatar.setScale(styles.avatar.scale);
        this.addChild(avatar);

        this.name.setString(cleverapps.UI.cutPlayerName(name, 7));
        this.gold.setString(aisensia.getNumberWithCommas(gold));
    },

    animateRaidPlayer: function (target) {
        cleverapps.audio.playSound(bundles.game.urls.slot_vs);

        var flyIcon = this.avatar;
        var rect = flyIcon.getSceneBoundingBox();
        var scale = Math.max(target.width / rect.width, target.height / rect.height);
        flyIcon.runAction(new cc.Sequence(
            new cc.DelayTime(1),
            new cc.ScaleTo(0.1, 1.3),
            new cc.CallFunc(function () {
                var shine = cleverapps.UI.createScale9Sprite(bundles.avatar.frames.avatar_shine);
                shine.setContentSize(flyIcon.getContentSize());
                shine.setPosition(flyIcon.width / 2, flyIcon.height / 2);
                flyIcon.addChild(shine);
                shine.setOpacity(0);
                shine.runAction(new cc.Sequence(
                    new cc.FadeTo(0.15, 150),
                    new cc.FadeOut(0.15)
                ));
            }),
            new cc.DelayTime(0.4),
            AnimationsLibrary.animateCollect(flyIcon, target, {
                duration: 0.5,
                path: true,
                scale: scale
            }),
            new cc.RemoveSelf()
        ));
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    COINMASTER_NAME_TEXT: {
        size: 31,
        color: cleverapps.styles.COLORS.WHITE
    },
    COINMASTER_GOLD_TEXT: {
        name: "nostroke",
        size: 35,
        color: new cc.Color(253, 214, 47, 255)
    }
});

cleverapps.styles.CoinMasterView = {
    size: {
        width: 241,
        height: 104
    },

    avatar: {
        scale: 0.81,
        x: { align: "left", dx: 0 },
        y: { align: "center", dy: 2 }
    },

    name: {
        x: { align: "left", dx: 110 },
        y: { align: "center", dy: 25 }
    },

    gold: {
        x: { align: "left", dx: 110 },
        y: { align: "center", dy: -20 }
    }
};