/**
 * Created by Mikhail Menshenin on 20.09.2024
 */

cleverapps.overrideStyles(cleverapps.styles.HutView, {
    villages: {
        0: [
            [
                { "x": -70, "y": 460 },
                { "x": -399, "y": 140 },
                { "x": -399, "y": 140 }
            ],
            [
                { "x": 238, "y": 197 },
                { "x": 433, "y": 173 },
                { "x": 433, "y": 173 }
            ],
            [
                { "x": -163, "y": -1 },
                { "x": -205, "y": -168 },
                { "x": -205, "y": -168 }
            ],
            [
                { "x": 224, "y": -180 },
                { "x": -18, "y": 162 },
                { "x": -18, "y": 162 }
            ],
            [
                { "x": -115, "y": -383 },
                { "x": 201, "y": -144 },
                { "x": 201, "y": -144 }
            ]
        ],
        1: [
            [
                { "x": 210, "y": -90 },
                { "x": 534, "y": -154 },
                { "x": 534, "y": -154 }
            ],
            [
                { "x": 200, "y": 230 },
                { "x": -323, "y": 179 },
                { "x": -323, "y": 179 }
            ],
            [
                { "x": -170, "y": 60 },
                { "x": -43, "y": -66 },
                { "x": -43, "y": -66 },
            ],
            [
                { "x": -85, "y": 470 },
                { "x": 329, "y": 225 },
                { "x": 329, "y": 225 }
            ],
            [
                { "x": -122, "y": -388 },
                { "x": -574, "y": -283 },
                { "x": -574, "y": -283 }
            ]
        ],
        2: [
            [
                { "x": { "align": "center", "dx": 200 }, "y": { "align": "center", "dy": 150 } },
                { "x": { "align": "center", "dx": -72 }, "y": { "align": "center", "dy": 154 } },
                { "x": { "align": "center", "dx": -72 }, "y": { "align": "center", "dy": 154 } }
            ],
            [
                { "x": { "align": "center", "dx": 133 }, "y": { "align": "center", "dy": -290 } },
                { "x": { "align": "center", "dx": 284 }, "y": { "align": "center", "dy": -166 } },
                { "x": { "align": "center", "dx": 284 }, "y": { "align": "center", "dy": -166 } }
            ],
            [
                { "x": { "align": "center", "dx": -215 }, "y": { "align": "center", "dy": -500 } },
                { "x": { "align": "center", "dx": -254 }, "y": { "align": "center", "dy": -169 } },
                { "x": { "align": "center", "dx": -254 }, "y": { "align": "center", "dy": -169 } }
            ],
            [
                { "x": { "align": "center", "dx": -53 }, "y": { "align": "center", "dy": 470 } },
                { "x": { "align": "center", "dx": 416 }, "y": { "align": "center", "dy": 174 } },
                { "x": { "align": "center", "dx": 416 }, "y": { "align": "center", "dy": 174 } }
            ],
            [
                { "x": { "align": "center", "dx": -160 }, "y": { "align": "center", "dy": 10 } },
                { "x": { "align": "center", "dx": -470 }, "y": { "align": "center", "dy": 155 } },
                { "x": { "align": "center", "dx": -470 }, "y": { "align": "center", "dy": 155 } }
            ]
        ]
    }
});

cleverapps.overrideStyles(cleverapps.styles.CrossView, {
    villages: {
        0: [
            [
                { "x": 210, "y": 390 },
                { "x": -430, "y": -205 },
                { "x": -430, "y": -205 }
            ],
            [
                { "x": -150, "y": 230 },
                { "x": 210, "y": 65 },
                { "x": 210, "y": 65 }
            ],
            [
                { "x": 0, "y": -200 },
                { "x": 540, "y": -80 },
                { "x": 540, "y": -80 }],
            [
                { "x": 260, "y": -320 },
                { "x": -80, "y": 15 },
                { "x": -80, "y": 15 }
            ]
        ],
        1: [
            [
                { "x": 190, "y": 400 },
                { "x": -270, "y": -55 },
                { "x": -270, "y": -55 }
            ],
            [
                { "x": -160, "y": 240 },
                { "x": 30, "y": 130 },
                { "x": 30, "y": 130 }],
            [
                { "x": -30, "y": -110 },
                { "x": 580, "y": 80 },
                { "x": 580, "y": 80 }
            ],
            [
                { "x": 240, "y": -290 },
                { "x": 230, "y": -140 },
                { "x": 230, "y": -140 }
            ]
        ]
    }
});