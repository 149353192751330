/**
 * Created by Mikhail Menshenin on 16.07.2024
 */

function setShieldsAction() {
    displayToolPrompt(function (value) {
        var shields = parseInt(value || "0");
        cleverapps.shields.set(shields);
    }, "Set shields (0..." + Village.MAX_SHIELDS + ")", 3);
}

cleverapps.override(cleverapps.Tool.game, {
    attack: function () {
        SlotMachineActions.attack();
    },

    "attack village level": function () {
        var maxVillageLevel = Village.getMaxLevel();
        displayToolPrompt(function (villageLevel) {
            villageLevel = villageLevel < 0 ? 0 : villageLevel;
            villageLevel = villageLevel > maxVillageLevel ? maxVillageLevel : villageLevel;
            var player = cleverapps.villagePlayers.getAttackPlayer();
            cleverapps.meta.saveLocation(player.locationId, {});
            player.villageLevel = villageLevel;
            cleverapps.villagePlayers.save();
            cleverapps.villagePlayers.attackPlayer = player;
            SlotMachineActions.attack();
            cleverapps.villagePlayers.attackPlayer = undefined;
        }, "raid village level (0..." + maxVillageLevel + ")", 0);
    },

    raid: function () {
        SlotMachineActions.raid();
    },

    "raid village level": function () {
        var maxVillageLevel = Village.getMaxLevel();
        displayToolPrompt(function (villageLevel) {
            villageLevel = villageLevel < 0 ? 0 : villageLevel;
            villageLevel = villageLevel > maxVillageLevel ? maxVillageLevel : villageLevel;
            var player = cleverapps.villagePlayers.getRaidPlayer();
            cleverapps.meta.saveLocation(player.locationId, {});
            player.villageLevel = villageLevel;
            cleverapps.villagePlayers.save();
            SlotMachineActions.raid();
        }, "raid village level (0..." + maxVillageLevel + ")", 0);
    },

    "set shields": setShieldsAction,

    "set energy": function () {
        var slotMachine = aisensia.slotMachine;
        var maxEnergy = slotMachine.getMaxEnergy();
        displayToolPrompt(function (value) {
            value = parseInt(value || "0");
            slotMachine.setEnergy(value);
        }, "Set shields (0..." + maxEnergy + ")", maxEnergy);
    },

    paylines: ToolFolder.create(function () {
        return SlotMachine.PAYLINES.reduce(function (actions, config) {
            actions[config.payline.join("_")] = function () {
                var scene = cleverapps.scenes.getRunningScene();
                var slotMachine = scene.slotMachine;

                SlotMachine.PREDEFINED_PAYLINE = config.payline;
                slotMachine.spin();
                SlotMachine.PREDEFINED_PAYLINE = undefined;
            };

            return actions;
        }, {});
    })
});

cleverapps.override(cleverapps.Tool, {
    village: ToolFolder.create(function () {
        var scene = cleverapps.scenes.getRunningScene();
        var actions = {};

        actions["go to next village"] = function () {
            Village.moveNextLocation();
        };

        if (scene instanceof VillageScene) {
            var village = scene.village;
            actions["finish one hut"] = function () {
                var hut = village.huts.find(function (hut) {
                    return !hut.isComplete();
                });
                if (hut) {
                    hut.damage = 0;
                    hut.setLevel(hut.getMaxLevel());
                }
            };

            actions["show all huts"] = function () {
                village.huts.forEach(function (hut) {
                    if (!hut.level) {
                        hut.setLevel(1);
                    }
                });
            };

            actions["copy hut positions"] = function () {
                var msg = village.level + ": " + JSON.stringify(cleverapps.styles.HutView.villages[village.level]);
                console.log(msg);
                cleverapps.copyToClipboard(msg, function () {
                    cleverapps.notification.create("hut positions copied to clipboard");
                });
            };

            actions["toggle crosses"] = function () {
                cleverapps.flags.toggle("villageCrossEnabled");
            };

            actions["copy cross positions"] = function () {
                var msg = village.level + ": " + JSON.stringify(cleverapps.styles.CrossView.villages[village.level]);
                console.log(msg);
                cleverapps.copyToClipboard(msg, function () {
                    cleverapps.notification.create("cross positions copied to clipboard");
                });
            };

            if (village.debugHutId === undefined) {
                village.debugHutId = -1;
            }
            actions["select next hut"] = function () {
                if (village.debugHutId !== -1) {
                    village.huts[village.debugHutId].cleanupBorder();
                }
                village.debugHutId = (village.debugHutId + 1) % village.huts.length;
                village.huts[village.debugHutId].debugBorder();
            };

            actions["set shields"] = setShieldsAction;

            actions["reset"] = function () {
                if (window.confirm("Reset village progress?")) {
                    Village.moveNextLocation(true);
                }
            };

            return actions;
        }
    }),

    village_attack: ToolFolder.create(function () {
        var actions = {};
        var scene = cleverapps.scenes.getRunningScene();

        if (scene instanceof VillageAttackScene) {
            var village = scene.village;

            actions["show assault animation"] = function () {
                cleverapps.flags.set("villageAttackMode", "assault");
                village.attackSomeone();
            };
            actions["show defense animation"] = function () {
                cleverapps.flags.set("villageAttackMode", "defense");
                village.attackSomeone();
            };
        }

        return actions;
    })
});

cleverapps.whenAllInitialized(function () {
    if (cleverapps.config.debugMode) {
        ToolModel.AUTONAVIGATE.push({
            windows: [VillageUpgradeWindow],
            path: ["village"]
        });
        ToolModel.AUTONAVIGATE.push({
            filter: function () {
                return cleverapps.toolModel.path === cleverapps.toolModel.root && cleverapps.scenes.getRunningScene() instanceof VillageAttackScene;
            },
            path: ["village_attack"]
        });
        ToolModel.AUTONAVIGATE.push({
            filter: function () {
                return cleverapps.toolModel.path === cleverapps.toolModel.root && cleverapps.scenes.getRunningScene() instanceof VillageScene;
            },
            path: ["village"]
        });
    }
});

cleverapps.Tool.Navigation = undefined;
