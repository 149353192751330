/**
 * Created by Ivan on 01.01.2025
 */

var CupTimer = cc.Node.extend({
    ctor: function (options) {
        this._super();
        options = options || {};

        var styles = cleverapps.styles.CupTimer;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles);

        this.timer = new Timer(options.timeLeft, function () {});
        this.addChild(this.timer);
        this.timer.setPositionRound(this.width / 2, this.height / 2);

        this.finishedText = this.createFinishedText(options.text);
        this.addChild(this.finishedText);
        this.finishedText.setPositionRound(this.width / 2, this.height / 2);
    },

    createFinishedText: function (label) {
        var styles = cleverapps.styles.CupTimer;

        var textBg = new cc.Scale9Sprite(bundles.timer.frames.timer_bg_png);
        textBg.setContentSize2(styles.finishedText);

        var text = cleverapps.UI.generateOnlyText(label, cleverapps.styles.FONTS.WHITE_TEXT);
        textBg.addChild(text);
        text.setVerticalAlignment(cc.VERTICAL_TEXT_ALIGNMENT_CENTER);
        text.fitTo(textBg.width - 2 * styles.finishedText.padding.x, textBg.height - 2 * styles.finishedText.padding.y);
        text.setPositionRound(textBg.width / 2, textBg.height / 2);

        return textBg;
    },

    showTimer: function (timeLeft) {
        this.finishedText.setVisible(false);
        this.timer.setVisible(true);
        this.timer.resetTimeLeft(timeLeft);
    },

    showText: function () {
        this.finishedText.setVisible(true);
        this.timer.setVisible(false);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    DAILY_CUP_TIMER_TEXT: {
        name: "default",
        color: cleverapps.styles.COLORS.WHITE,
        size: 40,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.CupTimer = {
    width: 300,
    height: 100,

    timer: {
        bg: {
            width: 270,
            height: 55
        }
    },

    finishedText: {
        width: 400,
        height: 80,

        padding: {
            x: 10,
            y: 5
        }
    }
};