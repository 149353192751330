/**
 * Created by slava on 8/8/18
 */

var RowView = cc.Scale9Sprite.extend({
    ctor: function (row, options) {
        options = options || {};

        this.row = row;
        this.options = options;

        row.view = this;

        var styles = cleverapps.styles.RowView;

        this.clickableArea = options.clickableArea;

        var background = this.row.player ? bundles.table.frames.row_you_png : bundles.table.frames.row_player_png;
        this._super(background, cleverapps.UI.getScale9Rect(background, cleverapps.UI.Scale9Rect.TwoPixelXY));

        var width = options.width || styles.width;
        this.setContentSize2(width, styles.height);

        if (this.row.player && bundles.table.frames.row_you_frame_png) {
            var frame = cleverapps.UI.createScale9Sprite(bundles.table.frames.row_you_frame_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
            frame.setContentSize2(width, styles.height);
            frame.setPositionRound({ align: "center" }, { align: "center" });
            frame.setLocalZOrder(1);
            this.addChild(frame);
        }

        this.updatePlace(row.place);

        var place = this.createPlace(row.place);
        var avatar = this.createAvatar();
        var name = this.createName();
        name.setPositionRound(styles.name);

        var prize;

        if (options.prizes !== false) {
            prize = this.createPrize(row.place);
        }

        var score = this.createScore();

        cleverapps.tooltipManager.create(score, {
            text: this.options.scoreTooltip || "ResultTooltip",
            location: cleverapps.UI.Tooltip.LOCATION_BELOW,
            clickableArea: this.clickableArea
        });

        var nameWidth = width - 2 * styles.paddingX - [place, avatar, prize, score].filter(Boolean).reduce(function (width, item) {
            return width + item.width * item.scaleX + styles.content.margin;
        }, 0);

        cleverapps.UI.fitToBox(name, {
            width: nameWidth,
            height: styles.name.height
        });

        var nameWrapper = new cc.Node();
        nameWrapper.setContentSize2(nameWidth, styles.height);
        nameWrapper.setAnchorPoint(0.5, 0.5);
        nameWrapper.addChild(name);

        this.content = new cleverapps.Layout([place, avatar, nameWrapper, prize, score].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.content.margin
        });

        this.addChild(this.content);
        this.content.setPositionRound(styles.content);
    },

    createPlace: function (place) {
        var styles = cleverapps.styles.RowView;

        var placeNode = this.placeNode = new cc.Node();
        placeNode.setAnchorPoint(0.5, 0.5);

        var content;
        var badgeIcon = bundles.table.frames["badge_" + place];
        if (this.options.badges !== false && badgeIcon) {
            content = new cc.Sprite(badgeIcon);
        } else {
            var text = this.row.notInTop ? "-" : place;
            content = cleverapps.UI.generateImageText(text, cleverapps.styles.FONTS.ROW_PLACE_TEXT);

            if (styles.place.background) {
                var image = this.row.player ? bundles.table.frames.amount_bg_you_png : bundles.table.frames.amount_bg_png;
                var background = new cc.Scale9Sprite(image);
                background.setContentSize2(styles.place.background.width, styles.place.background.height);
                background.addChild(content);

                content.setPositionRound(background.width / 2, background.height / 2);
                content = background;
            }
        }

        placeNode.setContentSize2(
            Math.max(
                this.options.badges !== false && styles.place.withBadge.width || 0,
                styles.place.background ? styles.place.background.width : styles.place.width
            ),
            styles.content.height
        );

        placeNode.addChild(content);
        content.setPositionRound(placeNode.width / 2, placeNode.height / 2);

        return placeNode;
    },

    createAvatar: function () {
        var styles = cleverapps.styles.RowView;

        var avatar = new cleverapps.UI.Avatar(this.row.avatar, {
            defaultAvatar: this.row.player ? bundles.table.frames.table_user_avatar : bundles.table.frames.table_default_avatar,
            frame: this.row.player ? bundles.table.frames.table_avatar_frame2 : bundles.table.frames.table_avatar_frame
        });
        cleverapps.UI.fitToBox(avatar, {
            height: styles.content.height
        });
        return avatar;
    },

    createName: function () {
        var userName = cleverapps.UI.cutPlayerName(this.row.text
            || typeof this.row.id === "string" && "Player_" + this.row.id.substring(this.row.id.length - 3) || "", 12);

        var font = this.row.player ? cleverapps.styles.FONTS.ROW_PLAYER_TEXT : cleverapps.styles.FONTS.ROW_TEXT;

        return cleverapps.UI.generateOnlyText(userName, font);
    },

    createPrize: function (place) {
        var styles = cleverapps.styles.RowView;

        var prizeNode = this.prizeNode = new cc.Node();
        prizeNode.setAnchorPoint(0.5, 0.5);
        prizeNode.setContentSize2(styles.prize.width, styles.content.height);

        var prize = this.createPrizeIcon(place);
        if (prize) {
            cleverapps.UI.fitToBox(prize, {
                height: styles.content.height
            });
            prizeNode.addChild(prize);
            prize.setPositionRound(prizeNode.width / 2, prizeNode.height / 2);

            var tooltipText = this.getPrizeTooltipText();
            cleverapps.tooltipManager.create(prizeNode, {
                rewards: this.getRewards(),
                text: tooltipText,
                clickableArea: this.clickableArea
            });
        }

        return prizeNode;
    },

    createPrizeIcon: function (place) {
        var iconsAmount = cleverapps.config.type === "merge" ? 3 : 5;
        if (place <= iconsAmount) {
            return new cc.Sprite(bundles.table.frames["prize_" + place]);
        }
    },

    getPrizeTooltipText: function () {
        return "PrizeTooltip";
    },

    getRewards: function () {
        return undefined;
    },

    createScore: function () {
        var styles = cleverapps.styles.RowView.score;

        var image = this.row.player ? bundles.table.frames.amount_bg_you_png : bundles.table.frames.amount_bg_png;

        var background = new cc.Scale9Sprite(image);
        background.setContentSize2(styles.width, styles.height);

        var icon;

        if (this.options.dataIcon) {
            icon = this.scoreIcon = new cc.Sprite(this.options.dataIcon);
            icon.setPositionRound(styles.icon);
            background.addChild(icon);
        }

        var amount = this.scoreAmount = cleverapps.UI.generateImageText("222", cleverapps.styles.FONTS.ROW_SCORE_TEXT);
        amount.setPositionRound((background.width + (icon ? icon.x + icon.width / 4 : 0)) / 2, background.height / 2);
        amount.fitToSize = {
            width: (background.width - (icon ? icon.x + icon.width / 4 : 0)) * 0.9,
            height: background.height * 0.9
        };
        background.addChild(amount);

        this.updateAmount(this.row.amount, { silent: true });

        return background;
    },

    updatePlace: function (place) {
        if (this.place === place) {
            return;
        }
        this.place = place;

        this.refreshPlace(this.place);
        this.refreshPrize(this.place);
        this.updateLocalZOrder();
    },

    updateLocalZOrder: function () {
        this.setLocalZOrder(this.row.player ? 1000 : this.place);
    },

    updateAmount: function (amount, options) {
        options = options || {};
        var callback = options.callback || function () {};

        if (!this.scoreAmount || this.amount === amount) {
            callback();
            return;
        }

        this.amount = amount;
        this.scoreAmount.stopAllActions();
        this.scoreAmount.setString(this.amount);
        this.scoreAmount.fitTo(this.scoreAmount.fitToSize.width, this.scoreAmount.fitToSize.height);

        if (options.silent) {
            callback();
            return;
        }

        callback = cleverapps.wait(2, callback);

        this.scoreAmount.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 1.5),
            new cc.ScaleTo(0.2, 1)
        )).setFinalize(callback);

        if (this.scoreIcon && this.options.dataIconAnimation && this.row.player) {
            var animation = new cleverapps.Spine(this.options.dataIconAnimation);
            animation.setAnimation(0, "animation");
            animation.setCompleteListenerRemove(callback);
            animation.setPosition(this.convertToNodeSpace(this.scoreIcon.parent.convertToWorldSpace(this.scoreIcon.getPosition())));
            animation.setLocalZOrder(100);
            this.addChild(animation);

            var movingNode = cleverapps.scenes.getMovingNode();
            animation.replaceParentSamePlace(movingNode, { keepScale: true });

            cleverapps.audio.playSound(this.options.dataIconAnimationSound);
        } else {
            callback();
        }
    },

    refreshPrize: function (place) {
        if (!this.prizeNode) {
            return;
        }

        var position = this.prizeNode.getPosition();
        this.prizeNode.removeFromParent(true);

        this.prizeNode = this.createPrize(place);
        this.prizeNode.setPositionRound(position);
        this.content.addChild(this.prizeNode);
    },

    refreshPlace: function (place) {
        if (!this.placeNode) {
            return;
        }

        var position = this.placeNode.getPosition();

        this.placeNode.removeFromParent(true);

        this.placeNode = this.createPlace(place);
        this.content.addChild(this.placeNode);
        this.placeNode.setPositionRound(position);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ROW_PLACE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    ROW_SCORE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    ROW_PLAYER_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    ROW_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.RowView = {
    width: 700,
    height: 82,

    paddingX: 15,

    content: {
        height: 62,
        margin: 10,
        x: { align: "center" },
        y: { align: "center" }
    },

    place: {
        withBadge: {
            width: 100
        },

        width: 50
    },

    name: {
        height: 50,

        x: { align: "center" },
        y: { align: "center", dy: -3 }
    },

    prize: {
        width: 80
    },

    score: {
        width: 140,
        height: 60,

        icon: {
            x: 10,
            y: { align: "center" }
        }
    }
};