/**
 * Created by vladislav on 29.04.2022
 */

var WindowTitle = cc.Node.extend({
    ctor: function (window, options, help) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setLocalZOrder(2);
        this.setVisible(false);

        this.window = window;
        this.type = this.window.withBg ? cleverapps.styles.WindowTitle.Types.bg_window : cleverapps.styles.WindowTitle.Types.nobg_window;
        this.help = help;

        if (this.type.background) {
            this.background = cleverapps.UI.createScale9Sprite(this.type.background.image);
            this.addChild(this.background);

            if (this.window.decors) {
                this.window.decors.initTitleDecorators();
            }
        }

        this.text = cleverapps.UI.generateOnlyText("", this.window.withBg ? cleverapps.styles.FONTS.WINDOW_TITLE_TEXT : cleverapps.styles.FONTS.NOBG_WINDOW_TITLE_TEXT);
        this.maxTextHeight = this.text.getFontSize() * 1.5;
        this.addChild(this.text, 1);

        var text = options;
        if (options.text) {
            text = options.text;
        }

        var toReplace = {};
        if (options.toReplace) {
            toReplace = options.toReplace;
        }

        if (this.help) {
            this.helpButton = new cleverapps.UI.HelpButton({
                type: cleverapps.styles.UI.Button.Images.help_button_title,
                callback: this.help
            });
            this.addChild(this.helpButton);
        }

        this.setTitle(text, toReplace);

        this.setCascadeOpacityEnabledRecursively(true);
    },

    adjustPosition: function () {
        if (this.window.withBg) {
            this.setAnchorPoint(0.5, 0.5);
            this.setPositionRound(this.type.position.x, this.type.position.y);
        }
    },

    adjustScale: function () {
        if (!this.window.withBg && !cleverapps.oneOf(this.window.closeButton, CloseRoundButton)) {
            this.setScale(Math.min(1.2, 1 / this.window.windowScale));
        }
    },

    setTitle: function (msg, toReplace) {
        this.text.setString(msg, toReplace);

        var maxTextWidth = cleverapps.resolution.getSceneSize().width;
        if (this.window.withBg) {
            maxTextWidth = this.window.window.width - 2 * this.type.titleSideOffset.x;
            if (this.help) {
                maxTextWidth -= 2 * this.type.helpButton.padding.x;
            }
        } else {
            maxTextWidth -= this.type.scenePaddingX * 2;
        }
        this.text.fitTo(maxTextWidth, this.maxTextHeight);

        if (this.background) {
            var paddingX = 2 * this.type.padding.x;
            if (this.helpButton) {
                paddingX += 2 * this.type.helpButton.padding.x;
            }
            var width2 = Math.max(this.text.width + paddingX, this.type.background.minWidth);
            this.background.setContentSize2(width2, this.type.background.height);

            this.setContentSize2(this.background.getContentSize());

            this.background.setPositionRound(this.width / 2, this.height / 2);

            if (this.window.decors) {
                this.window.decors.updateAll();
            }
        } else {
            this.setContentSize2(this.text.getContentSize());
        }

        this.text.setPositionRound(this.type.textPosition);
        if (this.helpButton) {
            this.text.setPositionRound(this.text.x + this.type.helpButton.padding.x, this.text.y);
            this.helpButton.setPositionRound(this.type.helpButton);
        }
    },

    show: function () {
        if (this.window.withBg) {
            this.setVisible(true);
        } else {
            this.setOpacity(255);
            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.1),
                new cc.MoveBy(0, 0, -this.type.offsetY),
                new cc.Show(),
                new cc.MoveBy(0.4, 0, this.type.offsetY).easing(cc.easeBackOut())
            ));
        }
    },

    hide: function () {
        if (this.window.withBg) {
            this.setVisible(false);
        } else {
            this.runAction(new cc.Spawn(
                new cc.ScaleTo(0.4, 0.6 * this.scale).easing(cc.easeBackIn()),
                new cc.FadeOut(0.4)
            ));
        }
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    WINDOW_TITLE_TEXT: {
        size: 70,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },

    NOBG_WINDOW_TITLE_TEXT: {
        size: 70,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    }
});

cleverapps.styles.WindowTitle = {
    Types: {
        bg_window: {
            background: {
                image: bundles.windows.frames.window_title_bg_png,
                height: 147,
                minWidth: 350
            },
            titleSideOffset: {
                x: 190
            },
            padding: {
                x: 60
            },
            textPosition: {
                x: { align: "center" },
                y: { align: "center", dy: 12 }
            },
            position: {
                x: { align: "center" },
                y: { align: "top", anchor: false, dy: -2 }
            },
            helpButton: {
                x: { align: "left", dx: 20 },
                y: { align: "center", dy: 3 },
                padding: {
                    x: 30
                }
            }
        },

        nobg_window: {
            background: {
                image: bundles.windows.frames.window_title_bg_secondary_png,
                height: 122,
                minWidth: 600
            },
            padding: {
                x: 195
            },
            textPosition: {
                x: { align: "center" },
                y: { align: "center", dy: 8 }
            },
            scenePaddingX: 150,
            offsetY: 50
        }
    }
};
