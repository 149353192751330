/**
 * Created by andrey on 31.05.17.
 */

var cleverappsEvents = {
    GENERAL: {
        LEVEL_UP: "level_up"
    },

    CONVERSIONS: {
        // % players (new installs) who triggered event eg DAY7_EVERYAD_5 at least once
        // avg sum value e.g. 3.5 - only for DAY7_EVERYAD_5 != 1

        VISIT: [{
            conversion_window: 1,
            accumulate: "sum"
        }, {
            conversion_window: 2,
            accumulate: "sum"
        }, {
            conversion_window: 3,
            accumulate: "sum"
        }, {
            conversion_window: 7,
            accumulate: "sum"
        }, {
            conversion_window: 14,
            accumulate: "sum"
        }, {
            conversion_window: 30,
            accumulate: "sum"
        }, {
            conversion_window: 180,
            accumulate: "sum"
        }],

        AD: [{
            old_name: "day1_watch",
            conversion_window: 1,
            reach: [5, 10, 20, 30]
        }, {
            conversion_window: 3,
            reach: [5, 10, 20, 30]
        }, {
            old_name: "day7_watch",
            conversion_window: 7,
            reach: [5, 10, 20, 30]
        }, {
            conversion_window: 14,
            reach: [5, 10, 20, 30]
        }, {
            conversion_window: 30,
            reach: [5, 10, 20, 30]
        }, {
            conversion_window: 180,
            reach: [5, 10, 20, 30]
        }],

        EVERY_5_AD: [{
            conversion_window: 1,
            accumulate: "count"
        }, {
            conversion_window: 3,
            accumulate: "count"
        }, {
            old_name: "day7_every_ad_5",
            conversion_window: 7,
            accumulate: "count"
        }, {
            conversion_window: 14,
            accumulate: "count"
        }, {
            conversion_window: 30,
            accumulate: "count"
        }, {
            conversion_window: 180,
            accumulate: "count"
        }],

        // gameLevel for merge and episodeNo for other games
        LEVEL: [{
            conversion_window: 1,
            reach: [1, 2, 3, 4, 5, 6, 7, 8, 10, 13, 15, 20, 22, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100]
        }, {
            conversion_window: 3,
            reach: [1, 2, 3, 4, 5, 6, 7, 8, 10, 13, 15, 20, 22, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100]
        }, {
            old_name: "day7_reach",
            conversion_window: 7,
            reach: [1, 2, 3, 4, 5, 6, 7, 8, 10, 13, 15, 20, 22, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100]
        }, {
            conversion_window: 14,
            reach: [1, 2, 3, 4, 5, 6, 7, 8, 10, 13, 15, 20, 22, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100]
        }, {
            conversion_window: 30,
            reach: [1, 2, 3, 4, 5, 6, 7, 8, 10, 13, 15, 20, 22, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100]
        }, {
            old_name: "day180_reach",
            conversion_window: 180,
            reach: [1, 2, 3, 4, 5, 6, 7, 8, 10, 13, 15, 20, 22, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100]
        }, {
            conversion_window: 30,
            accumulate: "count"
        }, {
            conversion_window: 180,
            accumulate: "count"
        }],

        PAYMENTS: [{
            conversion_window: 1,
            accumulate: "sum"
        }, {
            conversion_window: 3,
            accumulate: "sum"
        }, {
            old_name: "day7_payment",
            conversion_window: 7,
            accumulate: "sum"
        }, {
            conversion_window: 14,
            accumulate: "sum"
        }, {
            conversion_window: 30,
            accumulate: "sum"
        }, {
            conversion_window: 180,
            accumulate: "sum"
        }],

        TOTAL: [{
            conversion_window: 1,
            accumulate: "sum"
        }, {
            conversion_window: 3,
            accumulate: "sum"
        }, {
            conversion_window: 7,
            accumulate: "sum"
        }, {
            conversion_window: 14,
            accumulate: "sum"
        }, {
            conversion_window: 30,
            accumulate: "sum"
        }, {
            conversion_window: 180,
            accumulate: "sum"
        }]
    },

    EARN: {
        HARD: "ehard",
        PAYER_HARD: "payer_ehard",
        SOFT: "esoft",
        FLYING: "flying",
        PROMO: "promo",
        GROWTH_FUND: "fund",
        COOKIE_JAR: "cookie",
        CHEST: "chest",
        BOARD_COIN: "board",
        CLAN: "clan",
        MAGNIFIER: "magnifier",
        FRIEND_REQUEST: "request",
        RETURN: "return",
        SLOTMACHINE: "slotmachine",
        LEVEL_REWARD: "reward",
        LIVES: "lives",
        BONUS_LEVEL_REWARD: "bonus_level",
        SIMPLE_REWARD: "simple_reward",

        LEVEL_BASE_REWARD: "base_reward",
        LEVEL_EXTRA_REWARD: "extra_reward",

        PURCHASE: "purchase",
        DAILY: "daily",
        MISSION: "mission",
        VIDEO: "video",
        OTHER: "other",
        STAR_CHEST: "star_chest",
        WEEKLY_TOURNAMENT: "weekly_tournament"
    },

    SPENT: {
        HARD: "shard",
        PAYER_HARD: "payer_shard",
        BOOSTER: "booster",
        BOOSTER_BEFORE: "before",
        BARREL: "barrel",
        RUIN: "ruin",
        THIRD_ELEMENT: "third_element",
        PIXEL_OFFER: "pixel_offer",
        PAY_ISLAND: "island",
        WORKER: "worker",
        SKIP_VIP: "skip_vip",
        MAGNET: "magnet",
        DYNAMITE: "dynamite",

        LIVES: "lives",
        SOFT: "ssoft",
        UNIT: "unit",
        PAID_UNIT: "paid_unit",
        REFRESH: "refresh",
        SEASON: "season",
        CLAN: "clan",

        CONTINUE: "continue",

        TACTICAL_EXTEND: "tactical_extend",
        HERO_UPGRADE: "upgrade",
        BOMB_MOVES: "bomb",
        SLOT_MACHINE: "slot",
        HOMEFIX_CHOICE: "choice",

        INGREDIENT: "ingredient",
        OFFER: "offer",
        FOG: "fog",
        FOGBUY: "fogbuy",
        REFILL_TANK: "tank",
        GENIE_ENERGY: "genie",
        MINESWEEPER: "miner",
        PACK: "pack_",
        ORDER: "order",
        ENERGY_LOTTERY: "lottery",
        BATTLE_PASS: "battlepass",
        LAST_CHANCE: "lastchance",
        SPEED_UP: "speedup",
        FRUIT: "fruit",
        RESET: "reset",
        UNLOCK: "unlock",
        CARAVAN: "caravan",
        CUSTOMER: "customer",
        RECIPE_INGREDIENT: "ingred",
        RECIPE_INGREDIENT_ALL: "ingredall",
        FACTORY: "factory",
        WAREHOUSE: "warehouse",

        TROOP_CARDS: "troop",
        PIGGY_BANK: "piggy",
        LUCKY_BONUS: "lucky_bonus",
        PRIZE_CALENDAR: "prize_calendar",
        OCTOPUS_HUNT: "octopus_hunt"
    },

    ADS: {
        START: "ad_start",
        FINISH: "ad_finish",
        SESSION_FINISH_REWARDED: "ad_session_finish_rewarded",
        COST: "ad_cost",
        COST_COUNTRY: "adcost_country_",
        COUNTRY: "ad_country_",
        COST_CHANNEL: "adcost_channel_",
        CACHE: "ad_cache",
        READY: "ad_ready",

        AD_BLOCK_WINDOW_DAU: "ad_block_window_dau",

        AD_BLOCK_REWARDED_SUCCESS: "ad_block_r_success",
        AD_BLOCK_REWARDED_FAIL: "ad_block_r_fail",

        AD_BLOCK_STICKY_SUCCESS: "ad_block_s_success",
        AD_BLOCK_STICKY_FAIL: "ad_block_s_fail",

        TYPE: "ad-",
        ERROR_REWARDED: "ad_error-r-",
        ERROR_INTERSTITIAL: "ad_error-i-"
    },

    LOCATION: {
        ACTIVE: "active_",
        PLAYED: "played_",
        SPEND: "payments_",
        TOTAL: "total_"
    },

    WEBGL: {
        CONTEXT_LOST: "context_lost",
        CONTEXT_LOST_INACTIVE: "context_lost_inactive",
        CONTEXT_LOST_PAYER: "context_lost_payer",
        CONTEXT_LOST_PAYER_INACTIVE: "context_lost_payer_inactive",

        CONTEXT_LOST_DAU: "context_lost_dau",
        CONTEXT_LOST_INACTIVE_DAU: "context_lost_inactive_dau",
        CONTEXT_LOST_PAYER_DAU: "context_lost_payer_dau",
        CONTEXT_LOST_PAYER_INACTIVE_DAU: "context_lost_payer_inactive_dau",

        CONTEXT_LOST_MULTIPLE: "context_lost_2"
    },

    STATS: {
        APP_INITIALIZE: "app_initialize",
        LOADER_PROGRESS: "loader_progress_",
        LEVEL_START: "level_start",
        SHOP_OPEN: "shop_open",
        SHOP_CLOSE: "shop_close",
        PRODUCT_BUY: "product_bought", // deprecated; kept for backward compatibility
        BUY_VIRTUAL_ITEM: "buy_virtual_item",
        BUY_ITEM: "buy_item",
        BUY: "buy-",
        PAYMENTS: "payments",
        PAYMENTS_TERMINATED: "payments_terminated",
        PAYMENTS_COUNTRY: "payments_country_",
        PAYMENTS_CHANNEL: "payments_channel_",
        PAYMENTS_PLATFORM: "payments_platform_",
        PAYMENTS_CLASSIFY: "payments_classify_",
        PAYMENTS_COUNTRY_PLATFORM: "payments_cplatform_",
        VIDEO_PRODUCT_BUY: "video_product_buy",
        RATE_WINDOW: "rate_window",
        RATE_SEND: "rate_send",
        SOCIAL_REVIEW: "social_review_",
        NATIVE_REVIEW: "native_review_",
        TUTORIAL_STEP: "tutorial_",
        LEVEL_FINISH: "level_finish",
        LEVEL_LEFT_MOVES: "level_left_moves",
        LEVEL_PASSED_TIME: "level_passed_time",
        LEVEL_STARS: "level_stars",
        UNIQUE_FINISH: "lev_finish",
        UNIQUE_START: "lev_start",
        USE_ENERGY: "use_energy",
        PROMO: "promo_",
        PROMO_SHOW: "promo_show_",
        PROMO_ERROR: "promo_error_",
        LOGIN: "login",

        REGISTERED: "registered",
        CHANNEL: "channel_",
        CHANNEL_OS: "channel_os_",
        CHANNEL_COUNTRY: "channel_country_",
        CHANNEL_ACTIVE_TIME: "channel_atime_",
        CHANNEL_APP_INITIALIZE: "channel_appinit_",

        TIMESTART: "timestart",

        // Metrics
        DAU: "dau",
        DAU_COUNTRY: "dau_country_",
        DAU_PLATFORM: "dau_platform_",
        DAU_COUNTRY_PLATFORM: "dau_cplatform_",
        DAU30_COUNTRY_PLATFORM: "dau30_cplatform_",
        DAU_VERSION: "dau_version_",
        PAYER_DAU: "payer_dau",
        NEW_USERS_DAU: "day_1_dau",
        REWARDED_AVAILABLE_DAU: "rewarded_available_dau",
        REWARDED_LOADED_DAU: "rewarded_loaded_dau",
        REWARDED_NOADS_DAU: "rewarded_noads_dau",
        NOADS_ERROR: "noads_error-",
        REWARDED_DAU: "rewarded_dau",
        REWARDED_ERROR_DAU: "rewarded_error_dau",
        VIP_REWARDED_WATCHED: "vip_rewarded_watched",
        VIP_REWARDED_DAU: "vip_rewarded_dau",
        RETENTION_0: "retention_0",
        RETENTION_1: "retention_1",
        RETENTION_2: "retention_2",
        RETENTION_3: "retention_3",
        RETENTION_7: "retention_7",
        RETENTION_14: "retention_14",
        RETENTION_30: "retention_30",
        ACTIVE_TIME: "active_time",
        ACTIVE_TIME_DAU: "active_time_dau",
        GAME_LEVEL_CONVERSION_DAYS: "gamelevel_conversion_d",
        USER_WANDS_DAU: "user_wands_dau",
        USER_WANDS_DAU_AMOUNT: "user_wands_dau_amount",

        RETURNS: "returns_",

        ERROR_AMOUNT: "error_amount",

        FPS_RATE: "fps_rate",
        FPS_COUNT: "fps_count",

        RESOLUTION: "resolution_",
        WEBP: "webp_",
        OS: "os_",
        PLATFORM: "platform_",
        ANDROID_VERSION: "androidv_",
        APP_TRACKING: "app_tracking_",
        APP_LOVIN_ADS: "app_lovin_ads",
        ADMOB_ADS: "admob_ads",
        YANDEX_APP_ADS: "yandex_app_ads",

        INSTANT: {
            BOT_WANT: "instabot_want",
            BOT_SHOW: "instabot_show",
            BOT: "instabot",

            REQUEST: "instant_request",
            PAYMENT: "instant_payment-",
            CATALOG: "instant_catalog-",
            SUBSCRIPTION_CATALOG: "instant_subs_cat-",
            CONSUME: "instant_consume-",
            LIST_PURCHASES: "instant_list-",

            SUBSCRIPTION: "instant_subscr-",
            SUBSCRIPTION_LIST: "instant_sublist-",
            SUBSCRIPTION_API: "instant_subscr_api",

            LOCALE: "instant_locale-",
            PLATFORM: "instant_platform-",
            ENTRY_POINT: "instant_epoint-",

            ADS_ERROR_REWARDED: "instantr",
            ADS_ERROR_INTERSTITIAL: "instanti"
        },

        MSSTART: {
            ADS_LOAD: "msstart_load-",
            ADS_COUNTRY_LOAD: "msstart_cload-",
            ADS_PLAY: "msstart_play-",
            ADS_COUNTRY_PLAY: "msstart_cplay-"
        },

        PUSHES: {
            SEND: "push_send-",
            CLICK: "push_click-",
            PERMITTED_DAU: "push_permitted_dau",
            PERMISSION_WANT: "prm_want",
            PERMISSION_SHOW: "prm_show",
            PERMISSION_GOT: "prm_got"
        },

        PRODUCT: {
            OPEN: "product_open",
            SUCCESS: "product_success",
            RESTORE: "product_restore",
            CONSUME_START: "product_consume_start",
            CONSUME_SUCCESS: "product_consume_success",
            CONSUME_FAILURE: "product_consume_failure"
        },

        YOOKASSA: {
            PAID_COUNT: "yookassa_paid_count",
            PAID_AMOUNT: "yookassa_paid_amount"
        },

        BANNER: {
            DAU: "banner_dau",
            LOADING: "banner_loading",
            SHOWN: "banner_shown",
            HIDDEN: "banner_hidden"
        },

        XSOLLA: {
            INIT_WIDGET_FAILED: "xsolla_init_widget_failed",
            OPEN_WIDGET: "xsolla_open_widget"
        },

        CONFIG: {
            FETCH_START: "config_f_",
            FETCH_SUCCESS: "config_fs_",
            FETCH_FAILURE: "config_ff_"
        },

        CRM: {
            POST: "crm_post",
            POST_FAILED: "crm_post_failed",
            REPLY: "crm_reply",
            LATE_REPLY: "crm_late_reply",
            RESOLVED: "crm_resolved",
            UNANSWERED: "crm_unanswered",
            AVAILABLE_DAU: "crm_available_dau",

            POST_PAYER: "crm_post_payer",
            POST_FAILED_PAYER: "crm_post_failed_payer",
            REPLY_PAYER: "crm_reply_payer",
            LATE_REPLY_PAYER: "crm_late_reply_payer",
            RESOLVED_PAYER: "crm_resolved_payer",
            UNANSWERED_PAYER: "crm_unanswered_payer",
            AVAILABLE_DAU_PAYER: "crm_available_dau_payer"
        }
    },

    WECHAT: {
        PAY_RESULT: "pay_result"
    },

    DEBUG: {
        CLEARANCE: {
            DATALOADER_CHECKSUM_MATCH: "dataloader_checksum_match",
            DATALOADER_CHECKSUM_DIFFERENT: "dataloader_checksum_different",
            LOCALSTORAGE_CLEARED: "localstorage_cleared",
            LOCALSTORAGE_ERROR_SET_ITEM: "localstorage_error_set_item",
            LOCALSTORAGE_SESSION_EXPIRED: "localstorage_session_expired"
        },

        FAIL_BUNDLE: "fail_bundle_",
        WEBVIEW_ONERROR_RELOAD_SILENT: "webview_onerror_reload_silent",
        WEBVIEW_ONERROR_RELOAD_WINDOW: "webview_onerror_reload_window",
        GRAVITY_INIT_ERROR: "gravity_init_error",
        GRAVITY_RECONNECT: "gravity_reconnect",

        MERGE: {
            RESTORE_MULTICELL_BODY: "restore_multicellbody",
            RESTORE_KICKOUT: "restore_kickout_",
            UNITS_RESET: "units_reset",
            UNITS_RESET2: "units_reset2",
            UNKNOWN_UNIT_DAU: "uu_dau",
            UNKNOWN_UNIT_SOLD: "uu_sold"
        },

        UNKNOWN_MISSION_DAU: "unknown_mission_dau",
        PENDING_REQUESTS_FREEZE: "pending_requests_freeze",
        SECOND_LOGIN: "second_login",

        RENDER_TYPE: "render_type_",

        AUDIO_PLAY_PAUSED_GAME: "audio_play_paused_game",

        MISSION_REPLACED: "mission_replaced-",
        MISSION_REPLACED_REMOVED: "mission_replaced_removed-",
        MISSION_REPLACED_UNAVAILABLE: "mission_replaced_unavailable-",
        MISSION_REPLACED_PAST: "mission_replaced_past-",

        OK_SESSION_EXPIRED: "ok_session_expired",
        OK_SESSION_RESTORED: "ok_session_restored",

        AD_FROZEN: "ad_frozen-"
    },

    // Missions
    MISSION_DAU: "mission_dau",
    MISSION_ROUND: "_mission_round",
    MISSION_OPEN: "_mission_open",
    MISSION_REWARD_COST: "_mission_reward_cost",
    MISSION_REWARD: "_mission_reward_received",
    MISSION_PROLONGATION: "_mission_prolongation",

    OFFER_DAU: "offer_dau",
    OFFER_OPEN: "offer_open",
    OFFER: "offer_",
    OFFER_COST: "offer_cost_",

    PASS_FINISH_TASK: "pass_finish_task",
    PASS_RECEIVED: "pass_received",
    PASS_RECEIVED_PREMIUM: "pass_received_premium",
    PASS_PURCHASED_TICKET: "pass_ticket_purchased",

    MISSION_START: "mission_start",
    MISSION_FINISH: "mission_finish",
    TOURNAMENT_LEVEL_PLACE: "tournament_level_place",

    OPEN_FOG: "fog-",
    OPEN_FOG_TIME: "fog_time-",

    BONUS_WORLD_LEVEL: "bonus_world_level",
    BONUS_WORLD_DAU: "bonus_world_dau",
    BONUS_WORLD_LEVEL_DAU: "bonus_world_level_dau",
    BONUS_WORLD_REWARD: "BONUS_WORLD_REWARD",
    BONUS_WORLD_PAYMENTS: "bonus_world_payments_",

    TREASURE_SEARCH_REWARD: "tsearch_reward",
    TREASURE_SEARCH_REWARD_COST: "tsearch_reward_cost",
    TREASURE_SEARCH_RECEIVE_SHOVELS: "tsearch_receive_shovels",

    PINBALL_REWARD: "pb_reward",
    PINBALL_REWARD_COST: "pb_reward_cost",
    PINBALL_RECEIVE_CHIPS: "pb_receive_chips",
    PINBALL_RECEIVE_TICKETS: "pb_receive_tickets",
    PINBALL_PLAYED_GAMES: "pb_played_games",

    // Features
    META_LOCATION: "meta_location",
    METHA_ITEM: "metha_item",
    METHA_LEVEL: "metha_level",
    SIMPLE_METHA_LEVELUP: "simple_metha_background",

    MAKE_CHOICE: "make_choice",
    REDO_CHOICE: "redo_choice",
    WIN_KNOCKOUT: "win_knockout",
    LOSE_KNOCKOUT: "lose_knockout",

    MINIGAME_FINISH: "minigame_finish",
    MINIGAME_REWARD: "minigame_reward",
    MINESWEEPER_START: "minesweeper_start",
    MINESWEEPER_VICTORY: "minesweeper_victory",
    DAILY_BONUS: "daily_bonus",

    TACTICAL_EXTEND_SHOW: "tactical_extend_show",

    MESSAGE_SENT_FRIEND: "message_friend",
    MESSAGE_SENT_INVITE: "message_invite",
    MESSAGE_SENT_PREFIX: "message_sent_",
    MESSAGE_RECEIVED_PREFIX: "message_receive_",
    MESSAGE_CANCEL_PREFIX: "message_cancel_",
    MESSAGE_ERROR: "message_error",

    FRIEND_BALLOON_SEND: "friend_balloon_sent",
    FRIEND_BALLOON_RECEIVED: "friend_balloon_receive",

    INVITE_ICON_SHARE: "invite_icon_share",

    SESSION_DAILY_TASK_AVAILABLE: "session_daily_task_available",
    SESSION_DAILY_TASK_FINISH: "session_daily_task_finish",
    SESSION_DAILY_TASK_REWARD: "session_daily_task_reward",
    SESSION_DAILY_TASK_OPEN_WINDOW: "session_daily_task_open_window",
    DAILY_TASK_REWARD: "daily_task_rewarded",
    DAILY_TASK_FINISH_AMOUNT: "daily_task_finish_amount",
    DAILY_TASKS_OPEN_WINDOW: "daily_tasks_open_window",
    DAILY_TASKS_START: "daily_tasks_start",

    SUBSCRIPTION_WINDOW_OPEN: "subscription_window_open",
    SUBSCRIPTION_REWARD: "subscription_reward",
    SUBSCRIPTION_BOUGHT: "subscription_bought",
    SUBSCRIPTION_CANCELLED: "subscription_cancelled",

    CUP_REWARD: "cup_reward_",
    CUP_DAU: "cup_dau_",
    CUP_PLAYERS: "cup_players_",

    FUND_BUY: "bought_fund",
    FUND_DAU: "growth_fund_dau",
    FUND_GOLD: "growth_fund_gold",

    SHARE_PROPOSED: "share_proposed_",
    SHARE: "share_",

    LEADERS_WINDOW_OPEN: "leaders_window_open",
    NEW_LEAGUE: "new_league",

    MOVES_SHOW: "moves_show",
    MOVES_BOUGHT: "moves_bought",
    MOVES_USED_FORCE: "moves_used_force",

    BOOSTERS_EXECUTE: "boosters_execute",

    HINT_USED: "hint_used",

    PIGGY_BANK: "pig",

    BUTTERFLY_END_COLLECTED: "butterfly_end_collected_",
    BUTTERFLY_END_STAGE: "butterfly_end_stage_",
    BUTTERFLY_LVLUP: "butterfly_lvlup",
    BUTTERFLY_COLLECTED: "butterfly_collected",

    PRIZECAL_STAGE: "prizecal_stage",
    PRIZECAL_COMPLETE: "prizecal_complete",

    RETURNBONUS_CHECK: "returnbonus_check",
    RETURNBONUS_REWARD: "returnbonus_reward",
    PROMO_REWARD: "promo_reward_",
    PROMOCODE_REWARD: "promcode_",

    // match3
    STAR_CHEST_OPEN: "star_chest_open",
    PLAY_TIMES: "play_times",
    BOOSTER_CELL_ADDED: "booster_cell_added",
    BOOSTER_CELL_COLLECTED: "booster_cell_collected",
    BOOSTER_CELL_RECEIVED: "booster_cell_recevied",
    BOOSTER_COMBO_NO_PLACE: "booster_combo_no_place",
    BOOSTER_MULTICOLOR_NO_PLACE: "booster_multicolor_no_place",

    // tripeaks
    UNDO_USED_FORCE: "undo_force",

    // merge
    MERGE_START: "merge_start",
    CURRENT_RESOURCE: "current_resource_",
    COMPLETE_RESOURCES: "complete_resources",
    CURRENT_ORDER: "current_order_",
    COMPLETE_ORDERS: "complete_orders",
    SEASON_START: "season_start",
    CURRENT_SEASON: "current_season_",
    COMPLETE_SEASON: "complete_season_",
    CURRENT_HERO: "current_hero_",
    COMPLETE_HEROES: "complete_heroes",
    CAMPAIGN: "campaign_",
    CURRENT_PET: "curr_pet_",
    CURRENT_PETRARE: "curr_petr_",
    CURRENT_PETLEGEND: "curr_petl_",
    COMPLETE_PET: "comp_pet",
    COMPLETE_PETRARE: "comp_petr",
    COMPLETE_PETLEGEND: "comp_petl",
    PET_RATE: "pet_rate_",
    CURRENT_EVENT: "curr_event_",
    COMPLETE_EVENT: "comp_event_",

    UNITS_SHOP_COINS: "units_shop_coins",
    UNITS_SHOP_ITEM: "units_shop_item",

    SOFT_CURRENCY_BUY: "soft_buy",
    SOFT_CURRENCY_COST: "soft_cost",
    UNITS_SOLD: "units_sold",

    SPEND_WANDS: "spend_wands",

    MONSTER_SPAWN: "monster_spawn",
    MONSTER_DIE: "monster_die",

    HUNGRY_END: "hungry_end",
    HUNGRY_DAU: "hungry_dau",

    BANK_COINS: "bank_coins",

    BATTLE_REGULAR: "battle_regular",
    BATTLE_BONUS: "battle_bonus",

    THIRD_ELEMENT_COUNT: "third_element_count",

    UNIT_VALUE: "value_",

    CLANS_DAU: "clans_dau",

    UNITS_AMOUNT: "units_amount",
    UNITS_AMOUNT_DAU: "units_amount_dau",
    EARNED_COINSPLANT: "earned_coinsplant",
    EARNED_RUBIESPLANT: "earned_rubiesplant",
    EARNED_ENERGYPLANT: "earned_energyplant",
    EARNED_MAGICPLANT: "earned_magicplant",
    CASTLE_PRIZES: "castle_prizes",

    TRADE_WINDOW_OPEN: "trade_windowopen",
    TRADE_EXCHANGE: "trade_exchange_",

    BONUS_LEVEL_FINISH: "bonus_level_finish",

    LANDMARKS: {
        OPEN: "landmark_open_",
        REPLACE: "landmark_replace"
    },

    NOADS_PRODUCT: {
        SIDEBAR_CLICK: "noads_sidebar_click",
        WINDOW_OPEN: "noads_window_open",
        SIDEBAR_PURCHASE: "noads_sidebar_purchase",
        WINDOW_PURCHASE: "noads_window_purchase"
    },

    PLATFORM: {
        CONNECT: "connect",
        DISCONNECT: "disconnect"
    }
};

(function () {
    function giveName(conf, name) {
        if (Array.isArray(conf)) {
            conf.forEach(function (item) {
                giveName(item, name);
            });
        } else {
            conf.name = name.toLowerCase();
        }
    }
    Object.keys(cleverappsEvents.CONVERSIONS).forEach(function (name) {
        giveName(cleverappsEvents.CONVERSIONS[name], name.toLowerCase().replace(/_/g, ""));
    });
}());

if (typeof module !== "undefined") {
    module.exports = cleverappsEvents;
}
