/**
 * Created by Slava on 08.04.2024
 */

VictoryMessageAction = cleverapps.extendFunc(VictoryMessageAction, function (f) {
    if (Game.currentGame.outcome !== GameBase.OUTCOME_VICTORY) {
        f();
        return;
    }

    cleverapps.audio.playSound(bundles.main.urls.congratulate_sfx);

    var game = Game.currentGame;
    var scene = cleverapps.scenes.getRunningScene();

    game.counter.inc();
    cleverapps.timeouts.setTimeout(function () {
        var messageView = new GameMessageView("GameMessage.Victory");
        scene.addChild(messageView);

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            messageView.content.setFont(cleverapps.styles.FONTS.GAME_MESSAGE_TEXT_VERTICAL);
            messageView.content.setupChildren = function () {
                messageView.content.setPositionRound(cleverapps.styles.VictoryMessage.position.vertical.text);
            };
            messageView.content.setupChildren();
        }

        var animation = new cleverapps.Spine(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? bundles.victory_window.jsons.victory_message_animation_vertical : bundles.victory_window.jsons.victory_message_animation);
        animation.setAnimation(0, "animation_3", false);
        animation.setPositionRound(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.styles.VictoryMessage.position.vertical : cleverapps.styles.VictoryMessage.position.horizontal);
        messageView.background.addChild(animation);

        cleverapps.timeouts.setTimeout(function () {
            console.log("GameMessage.Victory callback");
            game.counter.dec();
            f();
        }, 1500);
    }, 200);
});

GameMessageView.prototype.createBackground = cleverapps.extendFunc(GameMessageView.prototype.createBackground, function () {
    var styles = cleverapps.styles.VictoryMessage.bg;
    var background = this.background = new cc.Node();
    background.setAnchorPoint(0.5, 0.5);
    background.setLocalZOrder(-1);
    this.createPatternLayer(bundles.gamemessage.frames.gamemessage_pattern_png, background);

    var gradient = cleverapps.UI.createScale9Sprite(bundles.gamemessage.frames.gamemessage_bg_gradient);
    gradient.setContentSize(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.gradient.width_vertical : this.patternLayout.getContentSize().width, this.patternLayout.getContentSize().height - styles.gradient.height_diff);
    gradient.setPositionRound(this.patternLayout.x + styles.gradient.dx, this.patternLayout.y + styles.gradient.dy);
    background.addChild(gradient);

    return background;
});

GameMessageView.prototype.updateSize = cleverapps.extendFunc(GameMessageView.prototype.updateSize, function () {
    var sceneSize = cleverapps.scenes.getRunningScene();
    this.background.setContentSize2(sceneSize.width, this.background.height);
});

GameMessageView.prototype.createPatternLayer = cleverapps.extendFunc(GameMessageView.prototype.createPatternLayer, function (pattern, parent) {
    var styles = cleverapps.styles.VictoryMessage.bg;
    var patternWidth = new cc.Sprite(pattern).width * styles.scale;
    var patternAmount = Math.ceil(cleverapps.scenes.getRunningScene().width / patternWidth) + 1;
    var patterns = [];

    for (var i = 0; i <= patternAmount; i++) {
        var patternSprite = new cc.Sprite(pattern);
        patternSprite.setScale(styles.scale);
        patterns.push(patternSprite);
    }

    var patternLayer = this.patternLayout = new cleverapps.Layout(patterns, { direction: cleverapps.UI.HORIZONTAL });
    patternLayer.setLocalZOrder(0);
    patternLayer.setPositionRound(cleverapps.scenes.getRunningScene().width / 2, parent.height / 2);
    parent.addChild(patternLayer);
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    GAME_MESSAGE_TEXT_VERTICAL: {
        size: 45,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.VictoryMessage = {
    position: {
        vertical: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 12 },
            text: {
                x: { align: "center", dx: 90 },
                y: { align: "center" }
            }
        },

        horizontal: {
            x: { align: "center", dx: -50 },
            y: { align: "center", dy: 62 }
        }
    },
    bg: {
        scale: 2,

        gradient: {
            dy: 6,
            dx: 0,
            width_vertical: 1500,
            height_diff: 55
        }
    }
};