/**
 * Created by Andrey Popov on 5/15/24.
 */

var NoAds = function () {
    this.state = NoAds.STATE_DISABLED;

    this.load();

    connector.ads.on("changeStatus", this.updateSideBarIcon.bind(this));

    this.updateSideBarIcon();
};

NoAds.prototype.load = function () {
    this.state = cleverapps.dataLoader.load(DataLoader.TYPES.NO_ADS) || NoAds.STATE_DISABLED;
};

NoAds.prototype.getState = function () {
    return this.state;
};

NoAds.prototype.updateSideBarIcon = function () {
    var icon = cleverapps.sideBar.findIconByClassName(NoAdsIcon);
    var available = NoAdsIcon.IsAvailable();

    if (icon && !available) {
        cleverapps.sideBar.removeTemporaryIcon(icon);
    }

    if (!icon && available) {
        cleverapps.sideBar.addTemporaryIcon(new NoAdsIcon());
        cleverapps.sideBar.resetByClassName(NoAdsIcon);
    }
};

NoAds.prototype.enable = function (fromServer) {
    this.state = NoAds.STATE_ENABLED;
    if (cleverapps.bannerAd) {
        cleverapps.bannerAd.check(true);
    }
    this.updateSideBarIcon();
    this.save(fromServer);
};

NoAds.prototype.reset = function () {
    this.state = NoAds.STATE_DISABLED;
    if (cleverapps.bannerAd) {
        cleverapps.bannerAd.check(true);
    }
    this.updateSideBarIcon();
    this.save();
};

NoAds.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.NO_ADS, this.state);

    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("noAds");
    }
};

NoAds.prototype.canBuy = function () {
    return connector.platform.oneOf(connector.WECHAT, connector.INSTANT, connector.MSSTART, connector.CRAZY, connector.OK, connector.VK, connector.YANDEX, connector.CLEVERAPPS, connector.TEST)
        && connector.payments.getProduct("noAds")
        && this.state === NoAds.STATE_DISABLED
        && connector.ads.isInterstitialSupported;
};

NoAds.STATE_DISABLED = 0;
NoAds.STATE_ENABLED = 1;

NoAds.NUDGE_TIMEOUT = cleverapps.parseInterval("6 hour");
if (cleverapps.config.debugMode) {
    NoAds.NUDGE_TIMEOUT = cleverapps.parseInterval("1 minute");
}