/**
 * Created by Andrey Popov on 09.02.21.
 */

var FakeGame = function (level) {
    Game.currentGame = this;

    this.counter = {
        isActive: function () {
            return true; 
        },
        registerStage: function () {},
        trigger: function () {},
        setTimeout: function () {}
    };

    var bundle = bundles[level.name || AdminEpisode.BundleId(level.episodeNo)];
    var meta = bundle.meta.episode ? Object.assign({}, bundle.meta.episode.levels && bundle.meta.episode.levels[level.levelNo] || {}) : bundle.meta;

    if (!meta.expedition) {
        meta.expedition = "main";
    }

    this.levelContent = level;
    this.level = {
        quick: true,
        content: level,
        episodeNo: level.episodeNo,
        meta: meta,
        getHumanReadableNumber: function () {
            return cleverapps.humanReadableNumber(level.episodeNo, level.levelNo);
        },
        getLevelEnemies: function () {
            var enemies = {};
            if (meta.enemies) {
                meta.enemies.forEach(function (enemy) {
                    enemies[enemy] = Game.GOAL_ENEMY_TYPES[enemy];
                });
            }
            return enemies;
        },
        isBonusRound: function () {
            return false;
        },
        isBonusWorldLevel: function () {
            return false;
        },
        bundleId: function () {
            return AdminEpisode.BundleId(level.episodeNo);
        },
        getTag: function () {
            return meta.tag;
        },
        isHard: function () {
            return this.getTag() === Level.TAGS.HARD;
        },
        isBonus: function () {
            return this.getTag() === Level.TAGS.BONUS;
        },
        isTricky: function () {
            return this.getTag() === Level.TAGS.TRICKY;
        },
        getImageBundle: function () {
            return meta.bundleName;
        }
    };

    if (["solitaire", "tile3"].indexOf(cleverapps.config.type) !== -1) {
        this.pagination = new cleverapps.Pagination(this.levelContent.screens.length);

        this.generator = new TileGenerator();
        this.table = {
            cards: []
        };

        this.updateClovers = function () {

        };
    }

    if (cleverapps.config.type === "match3") {
        var loader = new Loader();
        this.field = new Field(loader);
        this.loader = loader;
        this.goals = new Goals(level);
        this.score = {
            updatePoints: function () {}
        };
        this.moves = this.levelContent.moves;
        this.leftMoves = this.moves;
        this.beginMoves = this.moves;

        this.hasDanger = function () {
            return false;
        };

        Match3.counter = this.counter;
    }

    if (cleverapps.config.type === "board") {
        this.board = new cleverapps.Board(this, [], []);
        this.board.addHint = function () {};
        this.hintBooster = {
            addHint: function () {}
        };
        this.keypad = new Keypad(this, {});
        this.current = new cleverapps.Current(this, this.keypad);
        this.extra = new cleverapps.Extra(this.levelContent, []);
    }

    if (cleverapps.config.rpg) {
        this.enemies = Game.SelectEnemies(this.level);
    }

    if (cleverapps.config.type === "battlefield") {
        this.battlefield = new Battlefield(this.level.content.army.size, this.level.content.army.formation);
    }
};

FakeGame.prototype.getMissionType = function () {
    return undefined;
};

FakeGame.prototype.stop = function () {
    if (this.stopped) {
        return;
    }
    this.stopped = true;
};

FakeGame.prototype.on = function () {

};