/**
 * Created by slava on 14/8/17
 */

var AvailableMove = function () {
    cleverapps.EventEmitter.call(this);

    this.hint = undefined;
    this.cooldown = undefined;

    cleverapps.eventBus.on("changeFocus", function () {
        if (cleverapps.focusManager.isFocused()) {
            this.pause();
        } else {
            this.resume();
        }
    }.bind(this));

    cleverapps.userStatus.on("active", this.pause.bind(this));
};

AvailableMove.prototype = Object.create(cleverapps.EventEmitter.prototype);
AvailableMove.prototype.constructor = AvailableMove;

AvailableMove.prototype.pause = function () {
    this.hide();
};

AvailableMove.prototype.resume = function () {
    if (this.cooldown) {
        clearTimeout(this.cooldown);
    }

    this.cooldown = setTimeout(function () {
        if (this.hint) {
            this.trigger("changeVisible", true);
        }
    }.bind(this), AvailableMove.COOLDOWN);
};

AvailableMove.prototype.hide = function () {
    this.trigger("changeVisible", false);
    if (this.cooldown) {
        clearTimeout(this.cooldown);
    }
};

AvailableMove.prototype.showHint = function (move, skipCooldown) {
    this.hint = move;

    if (skipCooldown) {
        this.trigger("changeVisible", true);
    } else {
        this.resume();
    }
};

AvailableMove.prototype.getMoveType = function () {
    return this.hint.type;
};

AvailableMove.prototype.getMoveOptions = function () {
    return this.hint.options;
};

AvailableMove.COOLDOWN = 3000;