/**
 * Created by andrey on 20.01.2025
 */

var Row = function (table, data) {
    cleverapps.EventEmitter.call(this);

    this.table = table;

    this.updateData(data);
};

Row.prototype = Object.create(cleverapps.EventEmitter.prototype);
Row.prototype.constructor = Row;

Row.prototype.updateData = function (data) {
    this.id = data.id;
    this.player = !!data.player;
    this.text = data.text;
    this.avatar = data.avatar;
    this.emblem = data.emblem;
    this.amount = data.amount;
    this.place = data.place;

    this.cup = data.cup;
    this.notInTop = data.notInTop;

    this.firstPlace = data.firstPlace;
    this.secondPlace = data.secondPlace;
    this.thirdPlace = data.thirdPlace;

    this.leader = !!data.leader;
    this.clan = data.clan;
    this.level = data.level;
};

Row.prototype.removeView = function () {
    if (this.view) {
        this.view.removeFromParent();
        this.view = undefined;
    }
};

Row.prototype.getView = function () {
    return this.view;
};

Row.prototype.updateAmountView = function (options) {
    if (this.view && this.view.updateAmount) {
        this.view.updateAmount(this.amount, options);
    } else if (options && options.callback) {
        options.callback();
    }
};

Row.prototype.getViewAmount = function () {
    return this.view && this.view.amount;
};

Row.prototype.getViewPlace = function () {
    return this.view && this.view.place;
};

Row.prototype.getViewPosition = function () {
    return this.view && this.view.getPosition();
};

Row.prototype.isViewVisible = function () {
    return this.view && this.view.isVisible();
};

Row.prototype.updateViewPlace = function (place, options) {
    if (this.view) {
        this.view.updatePlace(place, options);
    } else {
        options && options.callback && options.callback();
    }
};
