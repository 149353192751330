/**
 * Created by Mikhail Menshenin on 15.08.2024
 */

var SlotMachineActions = {
    attack: function (cb) {
        cb = cb || function () { };
        cleverapps.meta.switchLocation(cleverapps.villagePlayers.getAttackPlayer().locationId);
        var scene = new VillageAttackScene();
        cleverapps.focusManager.displayWhenFreeFocus({
            focus: "VillageAttackScene",
            control: ["slotMachine", "button"],
            actions: [
                function (f) {
                    scene.load(f);
                },
                function (f) {
                    scene.transitionType = Transition.TYPE_ATTACK;
                    cleverapps.scenes.replaceScene(scene, f);
                    cb();
                }
            ]
        });
    },

    raid: function (cb) {
        cb = cb || function () { };
        cleverapps.meta.switchLocation(cleverapps.villagePlayers.getRaidPlayer().locationId);
        cleverapps.focusManager.displayWhenFreeFocus({
            focus: "VillageRaidScene",
            control: ["slotMachine", "button"],
            action: function (f) {
                var scene = new VillageRaidScene();
                scene.transitionType = Transition.TYPE_RAID;
                cleverapps.scenes.replaceScene(scene, f);
                cleverapps.villagePlayers.resetRaidPlayer();
                cb();
            }
        });
    }
};