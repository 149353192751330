/**
 * Created by mac on 4/8/18
 */

var RewardedAdsManager = function () {
    this.baseWatchCounter = 0;
    this.totalWatchCounter = cleverapps.dataLoader.load(DataLoader.TYPES.AD_WATCH_COUNTER) || 0;
    this.lastAdStarter = undefined;

    connector.ads.on("changeStatus", this.onChangePluginStatus.bind(this));

    connector.ads.on("rewarded:impression", this.onRewardedImpression.bind(this));
    connector.ads.on("interstitial:impression", this.onInterstitialImpression.bind(this));
    connector.ads.on("sticky:impression", this.onStickyImpression.bind(this));
    connector.ads.on("impressionTest", this.onImpressionTest.bind(this));

    connector.ads.on("applovin:displayed", this.onApplovinDisplayed.bind(this));

    connector.ads.on("rewarded:loading", this.createLoadingOverlay.bind(this));
    connector.ads.on("interstitial:loading", this.createLoadingOverlay.bind(this));

    connector.ads.on("rewarded:loaded", this.removeLoadingOverlay.bind(this));
    connector.ads.on("interstitial:loaded", this.removeLoadingOverlay.bind(this));

    connector.ads.on("rewarded:start", this.createAdOverlay.bind(this, "rewarded"));
    connector.ads.on("interstitial:start", this.createAdOverlay.bind(this, "interstitial"));

    connector.ads.on("rewarded:close", this.removeAdOverlay.bind(this));
    connector.ads.on("interstitial:close", this.removeAdOverlay.bind(this));

    connector.ads.on("rewarded:reward", this.onReward.bind(this));

    connector.ads.on("rewarded:caching", function (plugin) {
        this.logAdCaching("rewarded", plugin);
    }.bind(this));
    connector.ads.on("interstitial:caching", function (plugin) {
        this.logAdCaching("interstitial", plugin);
    }.bind(this));

    connector.ads.on("rewarded:cached", this.onRewardedCached.bind(this));
    connector.ads.on("interstitial:cached", this.onInterstitialCached.bind(this));

    connector.ads.on("rewarded:start", function (plugin) {
        this.logAdStarted("rewarded", plugin);
    }.bind(this));
    connector.ads.on("interstitial:start", function (plugin) {
        this.logAdStarted("interstitial", plugin);
    }.bind(this));

    connector.ads.on("rewarded:close", function (success, plugin) {
        if (success) {
            this.logAdClosed("rewarded", plugin);

            this.logRewardedClosed();
        }
    }.bind(this));
    connector.ads.on("interstitial:close", function (success, plugin) {
        if (success) {
            this.logAdClosed("interstitial", plugin);
        }
    }.bind(this));

    connector.ads.on("rewarded:start:expired", function () {
        this.logExpiredStart("rewarded");
    }.bind(this));
    connector.ads.on("interstitial:start:expired", function () {
        this.logExpiredStart("interstitial");
    }.bind(this));
    connector.ads.on("rewarded:close:expired", function (success) {
        if (success) {
            this.logExpiredClose("rewarded");
        }
    }.bind(this));
    connector.ads.on("interstitial:close:expired", function (success) {
        if (success) {
            this.logExpiredClose("interstitial");
        }
    }.bind(this));

    connector.ads.on("error:showRewarded", this.onRewardedError.bind(this));
    connector.ads.on("error:showInterstitial", this.onInterstitialError.bind(this));

    var frozenAd = cleverapps.dataLoader.load(DataLoader.TYPES.AD_FROZEN);
    if (frozenAd) {
        cleverapps.dataLoader.remove(DataLoader.TYPES.AD_FROZEN);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.AD_FROZEN + frozenAd.networkName);
    }
};

RewardedAdsManager.prototype.onInterstitialCached = function (code, plugin) {
    if (code) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.ERROR_INTERSTITIAL + code);
    } else {
        this.logAdCached("interstitial", plugin);
    }
};

RewardedAdsManager.prototype.onRewardedCached = function (code, plugin) {
    if (code) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.ERROR_REWARDED + code);
    } else {
        this.logAdCached("rewarded", plugin);

        this.logRewardedCached();
    }
};

RewardedAdsManager.prototype.onRewardedError = function (code) {
    cleverapps.notification.create("AdError." + code);

    cleverapps.playSession.set(cleverapps.EVENTS.STATS.REWARDED_ERROR_DAU, true);

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.ERROR_REWARDED + code);
};

RewardedAdsManager.prototype.onInterstitialError = function (code) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.ERROR_INTERSTITIAL + code);
};

RewardedAdsManager.prototype.logExpiredClose = function (type) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.START + "_expired");
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.START + "_" + type + "_expired");
};

RewardedAdsManager.prototype.logExpiredStart = function (type) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.START + "_expired");
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.START + "_" + type + "_expired");
};

RewardedAdsManager.prototype.logRewardedClosed = function () {
    cleverapps.playSession.set(cleverapps.EVENTS.STATS.REWARDED_DAU, true);
    cleverapps.playSession.inc(cleverapps.EVENTS.ADS.SESSION_FINISH_REWARDED);

    cleverapps.abTest.allLogEvent(cleverapps.EVENTS.ADS.FINISH);
};

RewardedAdsManager.prototype.logAdClosed = function (type, plugin) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.FINISH + "_" + type);
    if (plugin) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.FINISH + "_" + type + "_plugin_" + plugin);
    }

    if (Game.currentGame && Game.currentGame.getMode() === GameBase.MODE_HIGHSCORE) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.FINISH + "_" + type + "_" + Game.currentGame.getMode());
    }
};

RewardedAdsManager.prototype.logAdStarted = function (type, plugin) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.START + "_" + type);
    cleverapps.country.whenReady(function (country) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.START + "_" + type + "_country_" + country);
    });
    if (plugin) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.START + "_" + type + "_plugin_" + plugin);
    }

    if (type === "interstitial") {
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.WATCH_ADS);
    }
};

RewardedAdsManager.prototype.logRewardedCached = function () {
    if (this.noadsTimeout) {
        clearTimeout(this.noadsTimeout);
        delete this.noadsTimeout;
    }

    cleverapps.playSession.set(cleverapps.EVENTS.STATS.REWARDED_LOADED_DAU, true);
};

RewardedAdsManager.prototype.logAdCached = function (type, plugin) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.READY);
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.READY + "_" + type);
    cleverapps.country.whenReady(function (country) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.READY + "_" + type + "_country_" + country);
    });
    if (plugin) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.READY + "_" + type + "_plugin_" + plugin);
    }
    cleverapps.abTest.allLogEvent(cleverapps.EVENTS.ADS.READY);
};

RewardedAdsManager.prototype.logAdCaching = function (type, plugin) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.CACHE);
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.CACHE + "_" + type);
    cleverapps.country.whenReady(function (country) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.CACHE + "_" + type + "_country_" + country);
    });
    if (plugin) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.CACHE + "_" + type + "_plugin_" + plugin);
    }
    cleverapps.abTest.allLogEvent(cleverapps.EVENTS.ADS.CACHE);
};

RewardedAdsManager.prototype.onChangePluginStatus = function (status) {
    if (status === connector.STATUS_CONNECTED) {
        cleverapps.playSession.set(cleverapps.EVENTS.STATS.REWARDED_AVAILABLE_DAU, true);

        clearTimeout(this.noadsTimeout);

        this.noadsTimeout = new cleverapps.LongTimeout((function () {
            cleverapps.playSession.set(cleverapps.EVENTS.STATS.REWARDED_NOADS_DAU, true);
        }), cleverapps.parseInterval(RewardedAdsManager.REWARDED_NOADS_TIMEOUT));
    }
};

RewardedAdsManager.prototype.onRewardedImpression = function (impressionCost, plugin) {
    this.logImpressionCost(impressionCost);

    cleverapps.eventLogger.logAdRevenue("rewarded", impressionCost, { source: plugin });

    cleverapps.abTest.allLogEvent(cleverapps.EVENTS.ADS.COST, { value: impressionCost });

    if (!this.lastAdStarter && cleverapps.config.debugMode) {
        throw "No ad starter placement defined!";
    }

    var channel = cleverapps.user.channel || "";
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.COST_CHANNEL + channel, { value: impressionCost });

    cleverapps.conversionManager.processConversion(cleverapps.EVENTS.CONVERSIONS.TOTAL, impressionCost);

    cleverapps.country.whenReady(function (country) {
        cleverapps.eventLogger.logEvent(this.lastAdStarter, { value: impressionCost, country: country });
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.TYPE + "rewarded", { value: impressionCost, country: country });

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.COST_COUNTRY + plugin + "-rewarded-" + country, { value: impressionCost });
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.COUNTRY + plugin + "-rewarded-" + country);
    }.bind(this));
};

RewardedAdsManager.prototype.onInterstitialImpression = function (impressionCost, plugin) {
    this.logImpressionCost(impressionCost);

    cleverapps.eventLogger.logAdRevenue("interstitial", impressionCost, { source: plugin });

    cleverapps.abTest.allLogEvent(cleverapps.EVENTS.ADS.COST, { value: impressionCost });

    if (!this.lastAdStarter && cleverapps.config.debugMode) {
        throw "No ad starter placement defined!";
    }

    var channel = cleverapps.user.channel || "";
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.COST_CHANNEL + channel, { value: impressionCost });

    cleverapps.conversionManager.processConversion(cleverapps.EVENTS.CONVERSIONS.TOTAL, impressionCost);

    cleverapps.country.whenReady(function (country) {
        cleverapps.eventLogger.logEvent(this.lastAdStarter, { value: impressionCost, country: country });
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.TYPE + "interstitial", { value: impressionCost, country: country });

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.COST_COUNTRY + plugin + "-interstitial-" + country, { value: impressionCost });
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.COUNTRY + plugin + "-interstitial-" + country);
    }.bind(this));
};

RewardedAdsManager.prototype.onStickyImpression = function (impressionCost, plugin) {
    this.logImpressionCost(impressionCost);

    cleverapps.eventLogger.logAdRevenue("sticky", impressionCost, { source: plugin });

    cleverapps.conversionManager.processConversion(cleverapps.EVENTS.CONVERSIONS.TOTAL, impressionCost);

    var channel = cleverapps.user.channel || "";
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.COST_CHANNEL + channel, { value: impressionCost });

    if (cleverapps.bannerAd) {
        cleverapps.bannerAd.onPaidEvent(impressionCost);
    } else {
        cleverapps.throwAsync("BannerAd onPaidEvent before initialize");
    }
};

RewardedAdsManager.prototype.logImpressionCost = function (impressionCost) {
    if (connector.ads.oneOf(connector.AD_MOB)) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.ADMOB_ADS, { value: impressionCost });
    } else if (connector.ads.oneOf(connector.APP_LOVIN)) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.APP_LOVIN_ADS, { value: impressionCost });
    } else if (connector.ads.oneOf(connector.YANDEX_APP_ADS)) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.YANDEX_APP_ADS, { value: impressionCost });
    }
};

RewardedAdsManager.prototype.onImpressionTest = function (name, impressionCost) {
    if (connector.ads.oneOf(connector.YANDEX_APP_ADS)) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.YANDEX_APP_ADS + "_test", { value: impressionCost });
    }
};

RewardedAdsManager.prototype.onApplovinDisplayed = function (name, adInfo) {
    if (connector.ads.oneOf(connector.APP_LOVIN)) {
        cleverapps.dataLoader.save(DataLoader.TYPES.AD_FROZEN, {
            networkName: adInfo.networkName
        });
    }
};

RewardedAdsManager.prototype.onRewardedRefresh = function (callback, target) {
    var handler = connector.ads.on("rewarded:refresh", callback);

    addCleaner(target, handler.clear);
};

RewardedAdsManager.prototype.isRewardedSupported = function () {
    return connector.ads.isRewardedSupported;
};

RewardedAdsManager.prototype.isInterstitialSupported = function () {
    return connector.ads.isInterstitialSupported;
};

RewardedAdsManager.prototype.createLoadingOverlay = function () {
    cleverapps.focusManager.display({
        stack: true,
        focus: "LoadingAds",
        action: function (f) {
            this.loadingWaitWindow = new WaitWindow();
            this.onceLoadingWaitWindowClosed = f;
        }.bind(this)
    });
};

RewardedAdsManager.prototype.removeLoadingOverlay = function () {
    if (this.loadingWaitWindow) {
        this.loadingWaitWindow.close();
        this.loadingWaitWindow = undefined;
    }

    if (this.onceLoadingWaitWindowClosed) {
        this.onceLoadingWaitWindowClosed();
        this.onceLoadingWaitWindowClosed = undefined;
    }
};

RewardedAdsManager.prototype.createAdOverlay = function (name) {
    cleverapps.audio.mute();

    cleverapps.focusManager.display({
        stack: true,
        focus: "AdOverlay",
        action: function (f) {
            this.rewardOverlay = new RewardedAdOverlay(name);
            cleverapps.scenes.getRunningScene().addChild(this.rewardOverlay);

            this.onceRewardOverlayClosed = f;
        }.bind(this)
    });
};

RewardedAdsManager.prototype.removeAdOverlay = function () {
    var isPlatformOff = connector.platform.hasAudioControls && !connector.platform.isAudioEnabled;
    if (!isPlatformOff) {
        cleverapps.audio.unmute();
    }

    if (this.rewardOverlay) {
        this.rewardOverlay.removeFromParent();
        this.rewardOverlay = undefined;
    }

    if (this.onceRewardOverlayClosed) {
        this.onceRewardOverlayClosed();
        this.onceRewardOverlayClosed = undefined;
    }
};

RewardedAdsManager.prototype.adIsRunning = function () {
    return this._isAdRunning;
};

RewardedAdsManager.prototype.getLastAdStarter = function (adLimit) {
    adLimit = adLimit || "none";
    if ([AdsLimits.TYPES.ENERGY_SHORT, AdsLimits.TYPES.ENERGY_1_SHORT, AdsLimits.TYPES.ENERGY_2_SHORT].includes(adLimit)) {
        adLimit = "lives_short";
    }
    if ([AdsLimits.TYPES.ENERGY, AdsLimits.TYPES.ENERGY_1, AdsLimits.TYPES.ENERGY_2].includes(adLimit)) {
        adLimit = AdsLimits.TYPES.LIVES;
    }
    var expeditionPrefix = cleverapps.meta.getSelectedLocation().isExpedition() ? cleverapps.meta.selectedLocationId() + "_" : "";

    return cleverapps.EVENTS.ADS.TYPE + expeditionPrefix + adLimit;
};

RewardedAdsManager.prototype.removeFrozen = function () {
    cleverapps.dataLoader.remove(DataLoader.TYPES.AD_FROZEN);
};

RewardedAdsManager.prototype.onReward = function () {
    this.baseWatchCounter++;
    this.totalWatchCounter++;

    if (this.baseWatchCounter % 5 === 0) {
        cleverapps.conversionManager.processConversion(cleverapps.EVENTS.CONVERSIONS.EVERY_5_AD, this.baseWatchCounter);
    }

    cleverapps.conversionManager.processConversion(cleverapps.EVENTS.CONVERSIONS.AD, this.totalWatchCounter);

    cleverapps.dataLoader.save(DataLoader.TYPES.AD_WATCH_COUNTER, this.totalWatchCounter);

    cleverapps.eventBus.trigger("taskEvent", DailyTasks.WATCH_ADS);
};

RewardedAdsManager.prototype.isRewardedAvailable = function () {
    return connector.ads.isRewardedAvailable;
};

RewardedAdsManager.prototype.playRewarded = function (adLimit, onSuccessCallback, onCancelCallback) {
    onSuccessCallback = onSuccessCallback || function () {};
    onCancelCallback = onCancelCallback || function () {};

    if (cleverapps.config.adminMode || cleverapps.config.editorMode) {
        onSuccessCallback();
        return;
    }

    if (this.adIsRunning()) {
        onCancelCallback();

        cleverapps.notification.create("AdsRunning");
        return;
    }

    this.lastAdStarter = this.getLastAdStarter(adLimit);

    this._isAdRunning = true;

    new ActionPlayer([
        function (f) {
            cleverapps.whenGameActive(f);
        },

        function (f) {
            connector.ads.showRewardedVideoCallback(function (success) {
                this.removeFrozen();

                this._isAdRunning = false;

                if (success) {
                    if (connector.ads.isAdBlockEnabled) {
                        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.AD_BLOCK_REWARDED_SUCCESS);
                    }

                    onSuccessCallback();
                } else {
                    if (connector.ads.isAdBlockEnabled) {
                        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.AD_BLOCK_REWARDED_FAIL);
                    }

                    onCancelCallback();
                }
            }.bind(this));

            f();
        }.bind(this)
    ]).play();
};

RewardedAdsManager.prototype.showInterstitial = function (adLimit, callback) {
    callback = callback || function () {};

    if (cleverapps.config.adminMode || cleverapps.config.editorMode || cleverapps.config.wysiwygMode || cleverapps.environment.isAtlasAnalyzerScene()) {
        callback();
        return;
    }

    if (cleverapps.adsLimits.state(adLimit) !== AdsLimits.STATE_READY) {
        callback();
        return;
    }

    if (!this.isInterstitialSupported() || this.adIsRunning()) {
        callback();
        return;
    }

    if (cleverapps.noAds.getState() === NoAds.STATE_ENABLED) {
        callback();
        return;
    }

    this.lastAdStarter = this.getLastAdStarter(adLimit);

    this._isAdRunning = true;

    new ActionPlayer([
        function (f) {
            cleverapps.whenGameActive(f);
        },

        function (f) {
            connector.ads.showInterstitialCallback(function () {
                this.removeFrozen();

                this._isAdRunning = false;

                cleverapps.adsLimits.watch(adLimit);

                f();
            }.bind(this));

            if (connector.ads.oneOf(connector.TEST)) {
                var overlay = document.getElementsByClassName("video-ads-type")[0];
                overlay.innerText = adLimit + " is playing";
            }
        }.bind(this),

        function (f) {
            if (NoAdsWindow.needToNudge() && adLimit !== AdsLimits.TYPES.PREROLL) {
                cleverapps.focusManager.display({
                    focus: "NoAdsWindowAfterInterstitialForNoOutcomeGames",
                    stack: true,
                    action: function (f1) {
                        new NoAdsWindow();
                        cleverapps.focusManager.onceNoWindowsListener = function () {
                            f1();
                            f();
                        };
                    }
                });
            } else {
                f();
            }
        }
    ]).play(callback);
};
RewardedAdsManager.REWARDED_NOADS_TIMEOUT = "3 minutes";
RewardedAdsManager.REWARDED = "rewarded";
RewardedAdsManager.PREROL_SHOWING = "showing";
RewardedAdsManager.PREROL_SHOWN = "shown";