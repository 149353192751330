/**
 * Created by Denis Kuzin on 16 june 2023
 */

var UpMenuContainer = cc.Node.extend({
    ctor: function () {
        this._super();

        this.setLocalZOrder(UpMenuContainer.ZORDER);
        this.updateSize();
        this.setupChildren();
    },

    addMenuBar: function () {
        if (!cleverapps.environment.isWysiwygScene() && !cleverapps.environment.isAtlasAnalyzerScene()
            && !cleverapps.environment.isComicsScene()) {
            this.menuBar = new MenuBarView();
            this.addChild(this.menuBar);
        }
    },

    addControlButtons: function () {
        var buttons = new ControlButtonsView();
        this.addChild(buttons);

        this.controlButtons = new HidingNode(buttons, cleverapps.UI.VERTICAL);
        cleverapps.focusManager.registerControl("ControlButtonsView", this.controlButtons);

        this.updateSize();
        this.setupChildren();
    },

    isOutsideMenu: function () {
        return ["heroes", "riddles"].indexOf(cleverapps.config.name) !== -1
            && cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL && cleverapps.environment.isGameScene()
            && Game.currentGame && !Game.currentGame.hasDanger();
    },

    updateControlButtonsPosition: function () {
        var styles = cleverapps.styles.UpMenuContainer.controlButtons;

        if (this.isOutsideMenu()) {
            if (this.controlButtons.parent !== this.parent) {
                this.controlButtons.replaceParent(this.parent);
            }
            this.controlButtons.setScale(styles.outsideMenu.scale);
            this.controlButtons.setPositionRound(styles.outsideMenu);
        } else {
            if (this.controlButtons.parent !== this) {
                this.controlButtons.replaceParent(this);
            }
            this.controlButtons.setScale(1);
            this.controlButtons.setPositionRound(styles);
        }
    },

    updateSize: function () {
        var elements = [this.controlButtons, this.menuBar].filter(Boolean);
        
        var styles = cleverapps.styles.UpMenuContainer;
        var targetScale = styles.scale[cleverapps.resolution.mode];

        var safeWidth = cleverapps.resolution.getSceneSize().width;
        safeWidth -= styles.padding[cleverapps.resolution.mode].x * 2;

        if (this.menuBar) {
            var maxMenuBarWidth = safeWidth;
            if (this.controlButtons) {
                var controlButtonsWidth = this.controlButtons.width + styles.margin;
                maxMenuBarWidth -= controlButtonsWidth;
            }
            this.menuBar.reshape(maxMenuBarWidth / targetScale, (safeWidth / targetScale) / 2);
            if (this.menuBar.width * targetScale > maxMenuBarWidth) {
                targetScale = safeWidth / (this.menuBar.width + controlButtonsWidth);
            }
        }

        this.setScale(targetScale);

        var height = 0;
        for (var i = 0; i < elements.length; i++) {
            height = Math.max(height, elements[i].height);
        }

        this.setContentSize(safeWidth / targetScale, height);
    },

    setupChildren: function () {
        var styles = cleverapps.styles.UpMenuContainer;

        var alignment = {
            x: { align: "left", dx: styles.padding[cleverapps.resolution.mode].x },
            y: { align: "top" }
        };

        if (this.parent) {
            var position = this.calculatePositionRound(alignment);
            if (isNaN(position.x) || isNaN(position.y)) {
                cleverapps.throwAsync("NaN alignment position " + JSON.stringify({
                    alignment: alignment,
                    position: position,
                    size: this.getContentSize(),
                    parentSize: this.parent && this.parent.getContentSize()
                }));
                alignment = cc.p(0, 0);
            }
        }

        this.setPositionRound(alignment);

        if (this.menuBar) {
            this.menuBar.setPositionRound(styles.menuBar);
        }

        if (this.controlButtons) {
            this.updateControlButtonsPosition();
        }
    }
});

UpMenuContainer.ZORDER = BaseWindow.WINDOWS_ZORDER + 1;

cleverapps.styles.UpMenuContainer = {
    scale: [0.8, 0.8, 1],

    margin: 14,

    padding: [{
        x: 0
    }, {
        x: -10
    }, {
        x: -10
    }],

    menuBar: {
        x: { align: "left" },
        y: { align: "center" }
    },

    controlButtons: {
        x: { align: "right" },
        y: { align: "center", dy: -5 },

        outsideMenu: {
            x: { align: "left", dx: 10 },
            y: { align: "bottom", dy: 10 },
            scale: 0.8
        }
    }
};