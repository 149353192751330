/**
 * Created by Ivan on 04.02.2025
 */

var BoosterTutorial = function (booster) {
    return new TutorialScenario("tutorial_booster_" + booster.id, {
        listSteps: BoosterTutorial.listSteps.bind(this, booster),
        isAvailable: BoosterTutorial.isAvailable.bind(this, booster)
    });
};

BoosterTutorial.listSteps = function (booster) {
    console.log(booster);
    return [new TutorialStep({
        name: "clickBoosterButton",
        onStart: function () {
            booster.showForce();
        },

        onExecution: function () {
            Game.currentGame.counter.trigger();
        }
    })];
};

BoosterTutorial.isAvailable = function (booster) {
    return Game.currentGame && booster && booster.isForceAvailable();
};