/**
 * Created by Slava on 10.09.2024
 */

VirtualProducts.initialize = function () {
    if (cleverapps.config.lives) {
        VirtualProducts.refillLives = {
            icon: "refillLives_5_png",
            title: "LivesShopWindow.products.refillLives",
            spentEvent: cleverapps.EVENTS.SPENT.LIVES,
            currency: "hard",
            price: 99,
            TileModelClass: RefillLivesTileModel,
            ViewClassName: LivesProductTile
        };

        VirtualProducts.livesForVideo = {
            icon: "lives_for_video_png",
            title: "LivesShopWindow.products.livesForVideo",
            description: "LivesForVideoProduct.description",
            reward: {
                lives: 1
            },
            adsLimit:
                function () {
                    return AdsLimits.TYPES.LIVES;
                },
            TileModelClass: LimitedVideoTileModel,
            ViewClassName: LivesProductTile
        };

        VirtualProducts.askLives = {
            icon: "askLives_png",
            title: "LivesShopWindow.products.askLives",
            TileModelClass: AskLivesTileModel,
            ViewClassName: LivesProductTile
        };

        if (cleverapps.config.type === "tile3") {
            VirtualProducts.unlimitedLives = {
                title: "UnlimitedLives.Title",
                description: "UnlimitedLives.Description",
                spentEvent: cleverapps.EVENTS.SPENT.LIVES,
                currency: "hard",
                price: 290,
                reward: {
                    unlimitedLives: "3 days"
                },
                icon: "unlimitedLives_png",
                animation: "animation",
                ViewClassName: LivesProductTile,
                TileModelClass: BuyLivesTileModel
            };

            VirtualProducts.buyLife = {
                icon: "life_1_png",
                title: "LivesShopWindow.products.buyLive",
                spentEvent: cleverapps.EVENTS.SPENT.LIVES,
                currency: "soft",
                price: 249,
                reward: {
                    lives: 1
                },
                TileModelClass: BuyLivesTileModel,
                ViewClassName: LivesProductTile
            };
        }
    }

    if (cleverapps.config.energy) {
        VirtualProducts.buyEnergy350 = {
            icon: "energy_for_coins_png",
            title: "LivesShopWindow.products.buyEnergy",
            rewardsIcon: "shop_energy_png",
            currency: "soft",
            price: 350,
            reward: 90,
            discount: "66%",
            timeout: cleverapps.parseInterval("24 hour"),
            TileModelClass: BuyEnergyTileModel,
            ViewClassName: LivesProductTile
        };

        VirtualProducts.buyEnergy700 = {
            icon: "energy_for_coins_png",
            title: "LivesShopWindow.products.buyEnergy",
            rewardsIcon: "shop_energy_png",
            currency: "soft",
            price: 700,
            reward: 90,
            discount: "33%",
            disableProductIds: ["buyEnergy350"],
            timeout: cleverapps.parseInterval("24 hour"),
            TileModelClass: BuyEnergyTileModel,
            ViewClassName: LivesProductTile
        };

        VirtualProducts.buyEnergy = {
            icon: "energy_for_coins_png",
            title: "LivesShopWindow.products.buyEnergy",
            rewardsIcon: "shop_energy_png",
            currency: "soft",
            price: 1050,
            reward: 90,
            disableProductIds: ["buyEnergy350", "buyEnergy700"],
            TileModelClass: BuyEnergyTileModel,
            ViewClassName: LivesProductTile
        };

        VirtualProducts.energyLottery = {
            icon: "energy_lottery_png",
            title: "LivesShopWindow.products.energyLottery",
            attention: true,
            TileModelClass: EnergyLotteryTileModel,
            ViewClassName: LivesProductTile
        };

        VirtualProducts.energyForVideo = {
            icon: "energy_for_video_png",
            title: "LivesShopWindow.products.energyForVideo",
            description: "EnergyForVideoProduct.description",
            rewardsIcon: "shop_energy_png",
            reward: {
                energy: 30
            },
            adsLimit: function () {
                switch (cleverapps.lives && cleverapps.lives.slot) {
                    case CustomSyncers.EXPEDITION_SLOT1: return AdsLimits.TYPES.ENERGY_1;
                    case CustomSyncers.EXPEDITION_SLOT2: return AdsLimits.TYPES.ENERGY_2;
                    case CustomSyncers.EXPEDITION_SLOT3: return AdsLimits.TYPES.ENERGY_3;
                    case CustomSyncers.EXPEDITION_SLOT4: return AdsLimits.TYPES.ENERGY_4;
                    default: return AdsLimits.TYPES.ENERGY;
                }
            },
            TileModelClass: LimitedVideoTileModel,
            ViewClassName: LivesProductTile
        };

        VirtualProducts.energyForVideoShort = {
            icon: "energy_for_video_png",
            title: "LivesShopWindow.products.energyForVideoShort",
            description: "EnergyForVideoProduct.description",
            rewardsIcon: "shop_energy_png",
            reward: {
                energy: 5
            },
            adsLimit: function () {
                switch (cleverapps.lives && cleverapps.lives.slot) {
                    case CustomSyncers.EXPEDITION_SLOT1: return AdsLimits.TYPES.ENERGY_1_SHORT;
                    case CustomSyncers.EXPEDITION_SLOT2: return AdsLimits.TYPES.ENERGY_2_SHORT;
                    case CustomSyncers.EXPEDITION_SLOT3: return AdsLimits.TYPES.ENERGY_3_SHORT;
                    case CustomSyncers.EXPEDITION_SLOT4: return AdsLimits.TYPES.ENERGY_4_SHORT;
                    default: return AdsLimits.TYPES.ENERGY_SHORT;
                }
            },
            TileModelClass: LimitedVideoTileModel,
            ViewClassName: LivesProductTile
        };
    }

    VirtualProducts.softCurrencyVerySmall = {
        icon: "shop_coins_very_small_png",
        title: "LivesShopWindow.products.softCurrencyVerySmall",
        rewardsIcon: "shop_coin_png",
        realProductId: "coins350",
        currency: "hard",
        price: 80,
        reward: { soft: 350 },
        TileModelClass: SoftCurrencyTileModel,
        ViewClassName: SoftCurrencyTile
    };

    VirtualProducts.softCurrencySmall = {
        icon: "shop_coins_small_png",
        title: "LivesShopWindow.products.softCurrencySmall",
        rewardsIcon: "shop_coin_png",
        realProductId: "coins1100",
        currency: "hard",
        price: 250,
        reward: { soft: 1100 },
        TileModelClass: SoftCurrencyTileModel,
        ViewClassName: SoftCurrencyTile
    };

    VirtualProducts.softCurrencyMedium = {
        icon: "shop_coins_medium_png",
        title: "LivesShopWindow.products.softCurrencyMedium",
        rewardsIcon: "shop_coin_png",
        realProductId: "coins3000",
        currency: "hard",
        price: 600,
        reward: { soft: 3000 },
        TileModelClass: SoftCurrencyTileModel,
        ViewClassName: SoftCurrencyTile
    };

    VirtualProducts.softCurrencyLarge = {
        icon: "shop_coins_large_png",
        title: "LivesShopWindow.products.softCurrencyLarge",
        rewardsIcon: "shop_coin_png",
        realProductId: "coins7000",
        currency: "hard",
        price: 1400,
        reward: { soft: 7000 },
        TileModelClass: SoftCurrencyTileModel,
        ViewClassName: SoftCurrencyTile
    };

    VirtualProducts.hardForVideo = {
        icon: "gold_for_video_png",
        attention: true,
        title: "TileShop.products.hardForVideo",
        rewardsIcon: cleverapps.config.soft !== false ? "shop_gold_png" : "reward_gold_png",
        reward: {
            hard: cleverapps.config.soft !== false ? [1, 3] : 10
        },
        mainMonetizationReward: {
            hard: cleverapps.config.soft !== false ? [3, 8] : 10
        },
        adsLimit: function () {
            return AdsLimits.TYPES.HARD;
        },
        TileModelClass: LimitedVideoTileModel,
        ViewClassName: CoinsProductTile
    };

    if (cleverapps.config.features.includes("stickers_book")) {
        Object.assign(VirtualProducts, {
            stickers0: {
                title: "StickersBook.products.specialPack",
                currency: "hard",
                spentEvent: cleverapps.EVENTS.SPENT.STICKERS_3,
                price: 1000,
                icon: bundles.stickers_shop.frames.stickers_chest_0,
                availableAfterCollection: 2,
                reward: {
                    stickers: 2
                }
            },

            stickers1: {
                title: "StickersBook.products.mediumPack",
                currency: "hard",
                spentEvent: cleverapps.EVENTS.SPENT.STICKERS_2,
                price: 500,
                icon: bundles.stickers_shop.frames.stickers_chest_1,
                reward: {
                    stickers: 6
                }
            },

            stickers2: {
                title: "StickersBook.products.smallPack",
                currency: "hard",
                spentEvent: cleverapps.EVENTS.SPENT.STICKERS_1,
                price: 200,
                icon: bundles.stickers_shop.frames.stickers_chest_2,
                reward: {
                    stickers: 15
                }
            }
        });
    }

    if (cleverapps.config.name === "coinkingdom") {
        VirtualProducts.softCurrencyVerySmall.reward.soft = 15600;
        VirtualProducts.softCurrencySmall.reward.soft = 48750;
        VirtualProducts.softCurrencyMedium.reward.soft = 117000;
        VirtualProducts.softCurrencyLarge.reward.soft = 273000;
    }

    for (var key in VirtualProducts) {
        VirtualProducts[key].itemId = key;
    }
};