/**
 * Created by Mikhail Menshenin on 06.08.2024
 */

var VillageUpgradeToolbarItemView = ToolbarItemView.extend({
    ctor: function (model) {
        this._super(model);

        this.model = model;

        var styles = cleverapps.styles.VillageUpgradeToolbarItemView;
        var attention = new DualAttention({
            model: model.village,
            key: "attention",
            event: "changeAttention",
            scale: styles.attention.scale
        });
        attention.setPositionRound(styles.attention);
        this.addChild(attention);

        this.icon.setContentSize2(styles.size);

        var hammer = new cc.Sprite(bundles.buttons.frames.button_hammer);
        hammer.setPositionRound(styles.hammer);
        this.addChild(hammer);

        model.village.on("changeAttention", this.updateButton.bind(this));
        this.updateButton();

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            this.baseScale = styles.mobileScale;
            this.setScale(styles.mobileScale);
        }
    },

    updateButton: function () {
        var value = this.model.village["attention"] || false;

        if (value) {
            this.icon.setSpriteFrame(bundles.buttons.frames.button_green_bg);
        } else {
            this.icon.setSpriteFrame(bundles.buttons.frames.button_grey_bg);
        }
    }
});

cleverapps.styles.VillageUpgradeToolbarItemView = {
    size: {
        width: 150,
        height: 150
    },
    mobileScale: 0.75,
    attention: {
        x: { align: "right", dx: 36 },
        y: { align: "top", dy: 36 },
        scale: 1.4
    },
    hammer: {
        x: { align: "center", dx: 3 },
        y: { align: "center", dy: 0 }
    }
};