/**
 * Created by Mikhail Menshenin on 14.12.2024
 */

var LevelMasteryRewardWindow = RewardWindow.extend({
    onShow: function () {
        this._super();

        this.replaceRewardIcon();
    },

    replaceRewardIcon: function () {
        var flyIcon = cleverapps.persist.acceptNode("levelMasteryReward");
        var shine = cleverapps.persist.acceptNode("levelMasteryRewardShine");
        var windowReward = this.rewardsList.targets[0];

        windowReward.icon.removeFromParent(true);
        windowReward.shine.removeFromParent();
        windowReward.icon = flyIcon;
        cleverapps.timeouts.setTimeout(function () {
            windowReward.shine = shine;
        }, 1000);

        var text = windowReward.text;
        var textParentOrigin = text.parent;
        text.replaceParent(flyIcon);
        text.setPosition(flyIcon.width / 2, -40);
        text.replaceParentSamePlace(textParentOrigin);
    },

    onClose: function () {
        this._super();

        this.styles.shadow = true;
    }
});