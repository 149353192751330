/**
 * Created by evgenijsenkevic on 04.09.2024
 */

var AdminLocationDataView = cc.Node.extend({
    ctor: function (activeLocation, options) {
        this._super();

        this.activeLocation = activeLocation;
        this.setContentSize(options.width, options.height);
        this.createBg();
        this.createContent();

        this.setAnchorPoint(0.5, 0.5);
    },

    createBg: function () {
        var bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.lvl_miniature_bg);
        bg.setContentSize(this.width, this.height);
        bg.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(bg);
    },

    createDropDown: function (title, items, value, callback) {
        var styles = cleverapps.styles.AdminLocationDataView;

        var label = cleverapps.UI.generateOnlyText(title, cleverapps.styles.FONTS.ADMIN_DATA_TEXT);

        var dropdown = new DropDown({
            availableItems: items,
            value: value,
            size: cc.size(styles.dropdown.width, label.height),
            font: cleverapps.styles.FONTS.ADMIN_DATA_TEXT
        });

        dropdown.setDelegate({
            editBoxEditingDidEnd: function (dropdown) {
                callback(dropdown.value);
            }
        });

        var layout = new cleverapps.Layout([label, dropdown], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.dropdown.margin
        });

        cleverapps.UI.fitToBox(layout, {
            width: this.availableWidth
        });

        return layout;
    },

    createMapTilesChooser: function () {
        var availableTiles = Map2d.AVAILABLE_TILES.map(function (tilesTexture) {
            return tilesTexture.replace("tiles_", "");
        });
        availableTiles.sort(function (a, b) {
            return a.localeCompare(b);
        });

        return this.createDropDown("Map tiles:", availableTiles, this.activeLocation.tiles, function (value) {
            this.activeLocation.tiles = value;
            cleverapps.administrator.adminLocations.save(this.activeLocation);
        }.bind(this));
    },

    createMapUnitsChooser: function () {
        var availableUnits = Map2d.AVAILABLE_UNITS.map(function (tilesTexture) {
            return tilesTexture.replace("units_", "");
        });
        availableUnits.sort(function (a, b) {
            return Boolean(b === "main") - Boolean(a === "main") || a.localeCompare(b);
        });

        return this.createDropDown("Unit skin:", availableUnits, this.activeLocation.units, function (value) {
            this.activeLocation.units = value;
            cleverapps.administrator.adminLocations.save(this.activeLocation);
        }.bind(this));
    },

    createExpeditionChooser: function () {
        var locations = cleverapps.meta.listAvailableLocations();

        locations.sort(function (a, b) {
            return Boolean(b === "main") - Boolean(a === "main") || a.localeCompare(b);
        });

        return this.createDropDown("Expedition:", locations, this.activeLocation.expedition, function (value) {
            this.activeLocation.expedition = value;
            cleverapps.administrator.adminLocations.save(this.activeLocation);
        }.bind(this));
    },

    createContent: function () {
        var styles = cleverapps.styles.AdminLocationDataView;

        var mapTilesChooser = this.createMapTilesChooser();
        var mapUnitsChooser = this.createMapUnitsChooser();
        var expeditionChooser = this.createExpeditionChooser();

        var items = [mapTilesChooser, mapUnitsChooser, expeditionChooser].filter(function (item, index, array) {
            if (item) {
                item.setAnchorPoint(0, 0.5);
                item.setLocalZOrder(array.length - index);
                return true;
            }
            return false;
        });

        var layout = new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        layout.setAnchorPoint(0.5, 0.5);
        layout.setPositionRound(styles.layout);
        layout.setContentSize(this.width - 10, this.height - 10);
        this.addChild(layout);
    }
});

cleverapps.styles.AdminLocationDataView = {
    margin: 10,
    layout: {
        x: { align: "center" },
        y: { align: "center" }
    },
    dropdown: {
        width: 180,
        margin: 10
    }
};