/**
 * Created by Mikhail Menshenin on 09.08.2024
 */

var SlotMachineEnergyView = cc.Node.extend({
    ctor: function (slotMachine) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.SlotMachineEnergyView;
        var isFull = slotMachine.isFullEnergy();

        var bg = cleverapps.UI.createScale9Sprite(bundles.slotmachine.frames.progressbar_bg_gold_png);
        bg.setContentSize2(styles.progress.width, bg.getContentSize().height);
        bg.setAnchorPoint(0.5, 0.5);
        bg.setPositionRound(styles.progress.position);
        this.addChild(bg);

        this.setContentSize2(bg.getContentSize());

        var progressBar = this.progressBar = new ScaledProgressBar(styles.progress.options);
        progressBar.setPositionRound(styles.progress.position);
        progressBar.setLength(styles.progress.width);
        progressBar.setGoal(slotMachine.getMaxEnergy());
        progressBar.setPercentage(slotMachine.getEnergy());
        this.addChild(progressBar);

        var progressEffect = this.progressEffect = new ScaledProgressBar(styles.progress_effect.options);
        progressEffect.setPositionRound(styles.progress.position);
        progressEffect.setLength(styles.progress.width);
        progressEffect.setGoal(slotMachine.getMaxEnergy());
        progressEffect.setPercentage(slotMachine.getEnergy());
        progressEffect.progress.setOpacity(0);
        this.addChild(progressEffect);

        var labelFull = this.labelFull = cleverapps.UI.generateOnlyText("SlotMachine.Energy.Full", cleverapps.styles.FONTS.SLOTMACHINE_ENERGY_LABEL_TEXT);
        labelFull.fitTo(styles.label.width - 2 * styles.label.text.padding, styles.label.height);
        labelFull.setPositionRound(styles.label.text.full);
        this.addChild(labelFull);
        labelFull.setVisible(isFull);

        var timer = this.timer = new cleverapps.CountDownView(new cleverapps.CountDown(slotMachine.getEnergyRestoreTimeLeft()), {
            font: cleverapps.styles.FONTS.SLOTMACHINE_ENERGY_TIMER_TEXT,
            showHours: false
        });
        timer.setPositionRound(styles.timer);
        this.addChild(timer);
        timer.setVisible(!isFull);

        var labelTimer = this.labelTimer = cleverapps.UI.generateOnlyText("SlotMachine.Energy.Timer", cleverapps.styles.FONTS.SLOTMACHINE_ENERGY_LABEL_TEXT);
        labelTimer.fitTo(styles.label.width - 3 * styles.label.text.padding - timer.width, styles.label.height);
        labelTimer.setPositionRound(styles.label.text.timer);
        this.addChild(labelTimer);
        labelTimer.setVisible(!isFull);

        this.updateAmount(slotMachine, true);
        slotMachine.on("changeEnergy", this.updateAmount.bind(this, slotMachine), this);
        slotMachine.on("iconsRecieve", this.animateProgressEffect.bind(this), this);
        slotMachine.on("deltaEnergy", this.animateEnergyDelta.bind(this), this);
    },

    updateAmount: function (slotMachine, silent) {
        var energy = slotMachine.getEnergy() || 0;
        var isFull = slotMachine.isFullEnergy();
        var energyOver = slotMachine.getEnergyOver();

        this.progressBar.setPercentage(energy, { animated: !silent });
        this.progressEffect.setPercentage(energy, { animated: !silent });

        this.timer.setVisible(!isFull);

        if (silent) {
            if (isFull) {
                this.labelFull.setVisible(true);
                this.labelTimer.setVisible(false);
                this.energyOver = energyOver;
                if (energyOver) {
                    this.labelFull.setString("SlotMachine.Energy.Over", { over: energyOver });
                } else {
                    this.labelFull.setString("SlotMachine.Energy.Full");
                }
            } else {
                this.labelFull.setVisible(false);
                this.labelTimer.setVisible(true);
                this.timer.countDown.resetTimeLeft(slotMachine.getEnergyRestoreTimeLeft());
                this.energyOver = 0;
            }
            return;
        }

        if (isFull) {
            this.labelFull.setVisible(true);
            this.labelTimer.setVisible(false);
            var actions = [];
            if (energyOver) {
                actions.push(new cc.DelayTime(1));
                if (this.energyOver) {
                    actions.push(new cc.Spawn(
                        AnimationsLibrary.countTo(this.labelFull, energyOver, {
                            duration: 0.2,
                            from: this.energyOver,
                            getString: function (value) {
                                return Messages.get("SlotMachine.Energy.Over", { over: value });
                            }
                        }),
                        new cc.Sequence(
                            new cc.ScaleTo(0.2, 1.1).easing(cc.easeBackIn()),
                            new cc.ScaleTo(0.1, 1)
                        )
                    ));
                } else {
                    actions.push(new cc.CallFunc(function () {
                        this.labelFull.setString("SlotMachine.Energy.Over", { over: energyOver });
                    }.bind(this)));
                }
            } else {
                actions.push(new cc.CallFunc(function () {
                    this.labelFull.setString("SlotMachine.Energy.Full");
                }.bind(this)));
            }
            this.labelFull.runAction(new cc.Sequence(actions));
            this.energyOver = energyOver;
        } else {
            this.labelFull.setVisible(false);
            this.labelTimer.setVisible(true);
            this.timer.countDown.resetTimeLeft(slotMachine.getEnergyRestoreTimeLeft());
            this.energyOver = 0;
        }
    },

    animateProgressEffect: function (options) {
        var animation = options.icon_animation || options.animation || "animation";
        if (animation === "energy_reward") {
            this._setGlobalZOrder(true);
            this.progressEffect.progress.runAction(new cc.Sequence(
                new cc.DelayTime(0.3),
                new cc.FadeIn(0.1),
                new cc.FadeOut(0.1),
                new cc.FadeIn(0.1),
                new cc.FadeOut(0.1),
                new cc.DelayTime(0.1),
                new cc.FadeIn(0.1),
                new cc.FadeOut(0.1),
                new cc.CallFunc(function () {
                    this._setGlobalZOrder(false);
                }.bind(this))
            ));
        }
    },

    animateEnergyDelta: function (value) {
        cleverapps.aims.showDelta(value, this, {
            icon: TextWithIcon.ICONS_BY_NAME.energy,
            font: cleverapps.styles.FONTS.SCENE_ANIMATE_DELTA_TEXT,
            x: this.width / 2,
            y: this.height + cleverapps.styles.SlotMachineEnergyView.progress.delta
        });
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SLOTMACHINE_ENERGY_PROGRESSBAR_TEXT: {
        name: "nostroke",
        size: 35,
        color: cleverapps.styles.COLORS.WHITE
    },
    SLOTMACHINE_ENERGY_LABEL_TEXT: {
        size: 40,
        color: new cc.Color(236, 168, 155, 255)
    },
    SLOTMACHINE_ENERGY_TIMER_TEXT: {
        name: "nostroke",
        size: 22,
        color: new cc.Color(236, 168, 155, 255)
    }
});

cleverapps.styles.SlotMachineEnergyView = {
    progress: {
        width: 426,
        padding: {
            x: 0
        },

        position: {
            x: { align: "center" },
            y: { align: "center" }
        },

        options: {
            type: {
                progress: bundles.slotmachine.frames.progressbar_purple_png
            },
            barText: {
                font: cleverapps.styles.FONTS.SLOTMACHINE_ENERGY_PROGRESSBAR_TEXT,
                text: "%from%/%to% %%",
                dy: -1,
                dx: 12
            }
        },

        delta: 20
    },

    progress_effect: {
        options: {
            type: {
                progress: bundles.slotmachine.frames.progressbar_white_png
            }
        }
    },

    label: {
        width: 240,
        height: 41,

        x: { align: "center" },
        y: { align: "bottom", dy: -40 },

        text: {
            full: {
                x: { align: "center" },
                y: { align: "center", dy: -45 }
            },

            timer: {
                x: { align: "center", dx: -30 },
                y: { align: "center", dy: -45 }
            },

            padding: 20
        }
    },

    timer: {
        x: { align: "center", dx: 65 },
        y: { align: "center", dy: -45 }
    }
};