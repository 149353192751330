/**
 * Created by vladislav on 2/13/2024
 */

var Requests = function () {
    connector.social.on("error:request", this.onError.bind(this));
};

Requests.prototype.send = function (to, type, onSuccess) {
    if (!Array.isArray(to)) {
        to = [to];
    }

    var picture = cleverapps.SocialManager.getShareData("default").image;
    if (connector.platform.oneOf(connector.WORTAL)) {
        picture = cleverapps.SocialManager.getShareData("wortal").image;
    }

    new ActionPlayer([
        function (f) {
            cleverapps.SocialManager.enforceLogin(function (code) {
                if (code === cleverapps.CODE_SUCCEED) {
                    f();
                }
            });
        },
        function (f) {
            FriendsCache.loadFriends(f);
        },
        function () {
            this._send(to, type, picture, onSuccess);
        }.bind(this)
    ]).play();
};

Requests.prototype._send = function (to, type, picture, onSuccess) {
    connector.social.requestCallback({
        to: to,
        text: Messages.get(type + ".message"),
        title: Messages.get(type + ".title"),
        image: picture
    }, function (success) {
        if (success) {
            this.onSuccess(type, to);

            if (onSuccess) {
                onSuccess();
            }
        } else {
            this.onFail(type, to);
        }
    }.bind(this));
};

Requests.prototype.onSuccess = function (type, to) {
    levels.friendSorter.markUsed(to);

    var friends = cleverapps.friends.listIds();
    var sentToFriends = to.filter(function (sentId) {
        return friends.indexOf(sentId) !== -1;
    });

    if (sentToFriends.length > 0) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.MESSAGE_SENT_FRIEND, { value: sentToFriends.length });
    }

    if (to.length - sentToFriends.length > 0) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.MESSAGE_SENT_INVITE, { value: to.length - sentToFriends.length });
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.MESSAGE_SENT_PREFIX + type, { value: to.length });
};

Requests.prototype.onFail = function (type, to) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.MESSAGE_CANCEL_PREFIX + type, { value: to.length });
};

Requests.prototype.onError = function (code) {
    if ([cleverapps.CODE_CANCELLED, cleverapps.CODE_CANCELLED_FACEBOOK_DIALOG].includes(code)) {
        var eventName = cleverapps.EVENTS.MESSAGE_ERROR + "_" + connector.info.source;
        if (typeof code === "number") {
            eventName += "_" + code;
        }
        cleverapps.eventLogger.logEvent(eventName);
    }
};