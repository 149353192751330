/**
 * Created by r4zi4l on 09.09.2022
 */

Snapshots.extractors = {
    random: function () {
        return cleverapps.Random.state;
    },

    abtest: function () {
        return cleverapps.abTest.groups;
    },

    scene: function () {
        if (Game.currentGame && Game.currentGame.level && Game.currentGame.level.episodeNo !== undefined && Game.currentGame.level.levelNo !== undefined && !cleverapps.environment.isMainScene()) {
            return {
                episodeNo: Game.currentGame.level.episodeNo,
                levelNo: Game.currentGame.level.levelNo
            };
        }

        if (typeof Merge !== "undefined" && Merge.currentMerge) {
            return {
                episodeNo: Merge.currentMerge.level.episodeNo,
                levelNo: Merge.currentMerge.level.levelNo
            };
        }
    },

    flags: function () {
        return Object.keys(cleverapps.gameModes).filter(function (flagName) {
            return cleverapps.gameModes[flagName];
        });
    },

    users: function () {
        var data = CustomSyncers.extractors.users();
        if (data) {
            data.forcedVisited = data.visited;
        }

        return Object.assign(data || {}, cleverapps.info.data, {
            country: cleverapps.country.country,
            payerLevel: cleverapps.paymentsHistory.classify(),
            userIdsHistory: cleverapps.userIdsHistory.ids.join(",")
        });
    },

    placements: function () {
        var data = cleverapps.placements.getInfo();
        data.onceStarts = cleverapps.clone(cleverapps.placements.onceStarts);

        if (cleverapps.fallSale && cleverapps.windows.currentWindow() instanceof FallSaleWindow) {
            delete data.onceStarts.fallSaleWindow;
        }

        return data;
    },

    games: function () {
        if (cleverapps.config.type === "merge") {
            return CustomSyncers.extractors.games();
        }

        var data = Game.currentGame && Game.currentGame.getInfo();
        if (!data) {
            return;
        }

        if (!data.keypad && Game.currentGame.keypad) {
            data.keypad = Game.currentGame.keypad.getInfo();
        }

        return Object.assign(data, {
            level: Game.currentGame.level.levelNo,
            episode: Game.currentGame.level.episodeNo
        });
    },

    travelBook: function () {
        if (!cleverapps.travelBook) {
            return;
        }

        var data = {};

        cleverapps.travelBook.listAllPages().forEach(function (page) {
            var pageData = cleverapps.dataLoader.load(DataLoader.TYPES.TRAVEL_BOOK_EVENTS + page.id);
            if (pageData) {
                data[page.id] = pageData;
            }
        });

        return data;
    },

    travelBookAttention: function () {
        if (!cleverapps.travelBook) {
            return;
        }
        
        var data = {};

        cleverapps.travelBook.listAllPages().forEach(function (page) {
            var attention = cleverapps.dataLoader.load(DataLoader.TYPES.TRAVEL_BOOK_ATTENTION + page.id);
            if (attention) {
                data[page.id] = attention;
            }
        });

        return data;
    },

    clan: function () {
        if (!cleverapps.userClan || !cleverapps.userClan.id) {
            return;
        }

        var data = cleverapps.userClan.getInfo();
        data.players = cleverapps.userClan.players.map(function (player) {
            return player.getInfo();
        });
        data.messages = cleverapps.clanMessages.listMessages().map(function (message) {
            return message.getInfo();
        });

        return data;
    },

    ads: function () {
        var data = {
            enabled: cleverapps.rewardedAdsManager && cleverapps.rewardedAdsManager.isRewardedSupported(),
            running: connector.ads.isInterstitialPlaying && cleverapps.rewardedAdsManager.prerollStatus !== RewardedAdsManager.PREROL_SHOWING
        };
        if (cleverapps.rewardedAdsManager.prerollStatus === RewardedAdsManager.PREROL_SHOWN) {
            data.prerollStatus = RewardedAdsManager.PREROL_SHOWN;
        }
        return data;
    },

    isLoggedIn: function () {
        return connector.social.isLoggedIn();
    },

    specialEnergyOffer: function () {
        if (typeof SpecialEnergyOffer !== "undefined") {
            return cleverapps.dataLoader.load(DataLoader.TYPES.SPECIAL_LIVES_OFFER_LAST_TIME) || {};
        }
    },

    events: function () {
        if (!cleverapps.eventManager) {
            return;
        }
        return cleverapps.eventManager.events;
    },

    stickersBookAttention: function () {
        if (!cleverapps.stickersBook) {
            return;
        }
        return cleverapps.stickersBook.getAttentionInfo();
    },
    
    noAds: function () {
        return cleverapps.noAds.getState();
    },

    rumble: function () {
        var rumble = cleverapps.knockoutGame && cleverapps.knockoutGame.rumble;
        if (!rumble) {
            return;
        }

        return rumble.getInfo();
    },

    knockout: function () {
        if (!Game.currentGame || !Game.currentGame.competition || !Game.currentGame.competition.options || Game.currentGame.competition.options.type !== "knockout") {
            return;
        }

        var opponentView = cleverapps.scenes.getRunningScene().playersView.opponentView;

        return opponentView.getOpenedPoints();
    },

    energyProducts: function () {
        return cleverapps.dataLoader.load(DataLoader.TYPES.ENERGY_PRODUCTS) || {};
    },

    adsLimits: function () {
        return cleverapps.adsLimits.getInfo();
    },

    tutorial: function () {
        if (cleverapps.tutorial) {
            return cleverapps.tutorial.getInfo();
        }
    }
};

Snapshots.importers = {
    random: function (serverData) {
        cleverapps.Random.state = serverData || 0;
    },

    abtest: function (serverData) {
        cleverapps.dataLoader.save(DataLoader.TYPES.ABTEST, serverData);
    },

    users: function (serverData) {
        if (cleverapps.user) {
            serverData.forcedVisited = serverData.forcedVisited || Date.now();
            cleverapps.user.updateInfo(serverData);
        }
    },

    settings: function (serverData) {
        var hashParams = cleverapps.getRequestParameters(location.hash);
        var language = hashParams.lang || serverData.language;

        if (!language || language.length === 0) {
            return;
        }

        cleverapps.settings.setLanguage(language);
        cleverapps.settings.updateDependents();

        Messages.loadLanguage(language, function () {});
    },

    flags: function (serverData) {
        if (!serverData || cleverapps.config.wysiwygMode) {
            return;
        }

        Object.keys(cleverapps.gameModes).forEach(function (flagName) {
            cleverapps.gameModes[flagName] = serverData.indexOf(flagName) !== -1;
        });
        cleverapps.gameModes.save();
    },

    placements: function (data) {
        if (data) {
            cleverapps.dataLoader.save(DataLoader.TYPES.PLACEMENTS, data);
        }

        if (cleverapps.placements) {
            cleverapps.placements.load();
        }
    },

    travelBook: function (data) {
        if (!cleverapps.travelBook) {
            return;
        }

        cleverapps.travelBook.listAllPages().forEach(function (page) {
            if (data[page.id]) {
                cleverapps.dataLoader.save(DataLoader.TYPES.TRAVEL_BOOK_EVENTS + page.id, data[page.id]);
            } else {
                cleverapps.dataLoader.remove(DataLoader.TYPES.TRAVEL_BOOK_EVENTS + page.id);
            }
        });
    },

    travelBookAttention: function (data) {
        if (!cleverapps.travelBook) {
            return;
        }

        cleverapps.travelBook.listAllPages().forEach(function (page) {
            if (data[page.id]) {
                cleverapps.dataLoader.save(DataLoader.TYPES.TRAVEL_BOOK_ATTENTION + page.id, data[page.id]);
            } else {
                cleverapps.dataLoader.remove(DataLoader.TYPES.TRAVEL_BOOK_ATTENTION + page.id);
            }
        });
    },

    clan: function () {
        if (cleverapps.userClan && cleverapps.clanMessages) {
            cleverapps.userClan.load();
            cleverapps.clanMessages.loadMessages();
        }
    },

    ads: function (data) {
        if (!data || (typeof data === "object" && !data.enabled)) {
            connector.ads.getPlugin().setStatus(connector.STATUS_DISABLED);
            cleverapps.flags.disableReconnect = true;
        }
        cleverapps.rewardedAdsManager.prerollStatus = data.prerollStatus;
    },

    subscription: function (data) {
        data[connector.info.source] = Object.values(data)[0];
        cleverapps.subscription.updateInfo(data);
    },

    specialEnergyOffer: function (data) {
        cleverapps.dataLoader.save(DataLoader.TYPES.SPECIAL_LIVES_OFFER_LAST_TIME, data);
    },

    events: function (serverData) {
        if (!cleverapps.eventManager) {
            return;
        }
        cleverapps.eventManager.events = serverData.map(function (eventData) {
            return new cleverapps.Event(eventData);
        });
    },

    stickersBookAttention: function (data) {
        if (!cleverapps.stickersBook) {
            return;
        }
        cleverapps.stickersBook.updateAttention(data);
    },

    noAds: function (state) {
        if (state) {
            cleverapps.noAds.enable();
        } else {
            cleverapps.noAds.reset();
        }
    },

    rumble: function (data) {
        cleverapps.dataLoader.save(DataLoader.TYPES.RUMBLE, data);
    },

    knockout: function (data) {
        cleverapps.dataLoader.save(DataLoader.TYPES.COMPETITION + "_knockout", data);
    },

    energyProducts: function (data) {
        cleverapps.dataLoader.save(DataLoader.TYPES.ENERGY_PRODUCTS, data);
    },

    adsLimits: function (data) {
        cleverapps.dataLoader.save(DataLoader.TYPES.ADS_LIMITS_DATA, data);

        cleverapps.adsLimits.load();
    }
};

Snapshots.registerBySlots = function (name, extractor, importer) {
    Meta.SLOTS.forEach(function (slot) {
        this.extractors[name + slot] = function () {
            return extractor(slot);
        };

        this.importers[name + slot] = function (serverData) {
            return importer(slot, serverData);
        };
    }.bind(this));

    this.extractors[name + "_snapshot"] = extractor;
    this.importers[name + "_snapshot"] = importer;

    extractor.useSlots = true;
    importer.useSlots = true;
};

Snapshots.registerBySlots("units", function (slot) {
    if (!cleverapps.unitSavers) {
        return;
    }
    var unitSaver = cleverapps.unitSavers.getInstance(slot);
    var unitSyncer = unitSaver.unitSyncer;
    var result = {};

    for (var syncerCode in unitSyncer.syncers) {
        var familySyncer = unitSyncer.syncers[syncerCode];

        result[familySyncer.code] = familySyncer.getInfo();
    }

    return result;
}, function (slot, serverData) {
    if (!cleverapps.unitSavers) {
        return;
    }
    var unitSaver = cleverapps.unitSavers.getInstance(slot);
    var unitSyncer = unitSaver.unitSyncer;
    unitSyncer.updateInfo(serverData, true);
});

Snapshots.registerBySlots("map2d", function (slot) {
    return cleverapps.dataLoader.load(DataLoader.TYPES.MAP2D + slot);
}, function (slot, serverData) {
    cleverapps.dataLoader.save(DataLoader.TYPES.MAP2D + slot, serverData);
});

Snapshots.registerBySlots("freeworker", function (slot) {
    return cleverapps.dataLoader.load(DataLoader.TYPES.FREE_WORKER + slot);
}, function (slot, serverData) {
    if (serverData) {
        cleverapps.dataLoader.save(DataLoader.TYPES.FREE_WORKER + slot, serverData);
    } else {
        cleverapps.dataLoader.remove(DataLoader.TYPES.FREE_WORKER + slot);
    }
});

Snapshots.registerBySlots("thirdElements", function (slot) {
    return cleverapps.dataLoader.load(DataLoader.TYPES.THIRDELEMENTS_PLANNER + slot);
}, function (slot, serverData) {
    if (serverData) {
        cleverapps.dataLoader.save(DataLoader.TYPES.THIRDELEMENTS_PLANNER + slot, serverData);
    } else {
        cleverapps.dataLoader.remove(DataLoader.TYPES.THIRDELEMENTS_PLANNER + slot);
    }
});
