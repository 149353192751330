/**
 * Created by r4zi4l on 17.06.2021
 */

var FeastMissionWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        this.options = options;

        this.mission = options.mission;
        this.name = options.name;
        this.bundleName = options.bundleName || this.mission.getName().toLowerCase() + "_window";
        this.pack = options.pack;
        var offer = this.offer = cleverapps.offerManager.findOffer({ mission: this.mission.type });

        options.styles = cleverapps.overrideStyles(options.styles || {}, {
            windowShowUpAnimation: { name: "slideUp", force: true }
        }, true);

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            cleverapps.overrideStyles(options.styles, cleverapps.styles.FeastMissionWindow.verticalWindow);
        }

        this._super({
            name: options.name,
            content: this.createContent(),
            contentPadding: cleverapps.styles.FeastMissionWindow.contentPadding,
            noBackground: true,
            closeButton: !this.tableCloseButton,
            styles: options.styles,
            notCloseByTouchInShadow: true,
            offerWindowButton: offer ? {
                text: "FeastMissionWindow." + this.mission.getName() + ".offer",
                offerType: offer.type,
                onClicked: this.onOfferClicked.bind(this),
                bundle: this.bundleName,
                badgeText: "FeastMissionWindow." + this.mission.getName() + ".offerBadge"
            } : undefined,
            openSound: options.openSound
        });

        this.mission.updateCompetitionResults();
    },

    onOfferClicked: function () {
        cleverapps.windows.currentWindow().close();
        new SingleProductOfferWindow(this.offer);
    },

    createContent: function () {
        var styles = cleverapps.styles.FeastMissionWindow;
        var mission = this.mission;

        var table = this.createTable();
        table.setAnchorPoint(0.5, 0.5);

        var titleRect = this.backgroundSpine.getBoneRect("title");
        var helpRect = this.backgroundSpine.getBoneRect("help");
        var timerRect = this.backgroundSpine.getBoneRect("timer");
        var closeRect = this.backgroundSpine.getBoneRect("close");

        var mainTitle = cleverapps.UI.generateOnlyText(this.options.title || this.name + ".title", cleverapps.styles.FONTS.FEAST_MISSION_MAIN_TITLE_TEXT);
        mainTitle.setAnchorPoint(0.5, 0.5);
        mainTitle.setPositionRound(titleRect);
        mainTitle.fitTo(titleRect.width, titleRect.height);
        table.addChild(mainTitle);

        var helpButton = new cleverapps.UI.HelpButton({
            type: cleverapps.styles.UI.Button.Images.help_button_light,
            callback: function () {
                new GuideWindow(this.getGuideOptions());
            }.bind(this)
        });
        helpButton.setAnchorPoint(0.5, 0.5);
        helpButton.setPositionRound(helpRect);
        table.addChild(helpButton);

        if (closeRect) {
            var closeButton = this.tableCloseButton = new CloseRoundButton({ onClicked: this.close.bind(this) });
            closeButton.setPositionRound(closeRect);
            closeButton.setScale(0.7);
            closeButton.baseScale = closeButton.scale;
            table.addChild(closeButton);
        }

        if (mission.isRunning()) {
            var timeLeft = mission.getTimeLeft();
            if (timeLeft > 0) {
                timeLeft = new cleverapps.CountDown(timeLeft, {
                    onFinish: function () {
                        var FinishWindow = this.mission.finishWindow;
                        new FinishWindow(this.mission);
                        this.close();
                    }.bind(this)
                });
            }

            var timer = new cleverapps.CountDownView(timeLeft, {
                font: cleverapps.styles.FONTS.FEAST_MISSION_TIMER_TEXT
            });
            timer.fitTo(timerRect.width, timerRect.height);
            timer.setPositionRound(timerRect);

            table.addChild(timer);
        } else {
            var finishTitle = cleverapps.UI.generateOnlyText(this.options.finishedText || "FeastMissionWindow.finished", cleverapps.styles.FONTS.FEAST_MISSION_TIMER_TEXT);
            finishTitle.setAnchorPoint(0.5, 0.5);
            finishTitle.setPositionRound(timerRect);
            finishTitle.fitTo(timerRect.width, timerRect.height);
            table.addChild(finishTitle);
        }

        var button = this.createButton();

        return new cleverapps.Layout([table, button].filter(Boolean), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createTable: function () {
        var mission = this.mission;

        var json = bundles[this.bundleName].jsons.mission_board;

        if (cleverapps.config.debugMode && cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            json = bundles[this.bundleName].jsons.mission_board_vertical || json;
        }

        var background = this.backgroundSpine = new cleverapps.Spine(json);
        if (background.hasAnimation("open")) {
            background.setAnimationAndIdleAfter("open", "idle");
        } else {
            background.setAnimation(0, "idle", true);
        }

        var tableRect = background.getBoneRect("table");

        var table = this.mission.table;
        var tableView = new TableView(table, {
            id: mission.type,
            data: mission.getResults(),
            height: tableRect.height,
            bg: false,
            rowOptions: {
                width: tableRect.width,
                dataIcon: MissionWindow.getTableRowIcon(mission.type),
                dataIconAnimation: MissionWindow.getTableRowIconAnimation(mission.type),
                dataIconAnimationSound: bundles.feast_window && bundles.feast_window.urls.row_icon_fly_effect,
                scoreTooltip: this.options.scoreTooltip === false ? undefined : this.name + ".tooltip.amount",
                mission: mission
            },
            rowConstructor: this.options.rowConstructor || FeastMissionRow
        });

        tableView.setPositionRound(tableRect);
        background.addChild(tableView);

        mission.onChangeResults = this.createListener(function () {
            var results = mission.getResults();
            table.updateRows(results);
        });

        var skin = (Mission.GetChildType(this.mission.type) === Mission.TYPE_EXPEDITION_FEAST ? cleverapps.meta.selectedLocationId() : "")
            + this.mission.getName();

        if (background.hasSkin(skin)) {
            background.setSkin(skin);
        }

        return background;
    },

    createButton: function () {
        var styles = cleverapps.styles.FeastMissionWindow;

        var mission = this.mission;

        if (mission.isRunning() && cleverapps.config.type === "merge") {
            return;
        }

        var options = {
            width: styles.button.width,
            height: styles.button.height,
            type: cleverapps.styles.UI.Button.Images.button_red,
            text: Messages.get("Play").toUpperCase(),

            onClicked: function () {
                this.close();

                cleverapps.focusManager.distract({
                    focus: "MissionPlayButtonPressed",
                    action: function (f) {
                        cleverapps.meta.wantsToPlay(f);
                    }
                });
            }.bind(this)
        };

        if (!mission.isRunning()) {
            options = Object.assign(options, {
                type: cleverapps.styles.UI.Button.Images.button_blue,
                text: mission.getPrize() ? "ClaimPrize.Button.text" : "Close",
                onClicked: this.close.bind(this)
            });
        }

        var button = new cleverapps.UI.Button(options);
        button.setPositionRound(styles.button);
        return button;
    },

    onClose: function () {
        this.mission.runningCompetition.updateShownPlace();
        this.mission.updateMissionIcon();
    },

    listBundles: function (mission) {
        return [mission.getName().toLowerCase() + "_window"];
    }
});

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    FEAST_MISSION_STROKE: {
        color: new cc.Color(95, 29, 17, 128),
        size: 1
    },

    FEAST_MISSION_SHADOW: {
        color: new cc.Color(11, 13, 37, 128),
        direction: cc.size(0, -3),
        blur: 4
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    FEAST_MISSION_MAIN_TITLE_TEXT: {
        size: 78,
        color: new cc.Color(255, 201, 39, 255),
        stroke: cleverapps.styles.DECORATORS.FEAST_MISSION_STROKE,
        shadow: cleverapps.styles.DECORATORS.FEAST_MISSION_SHADOW
    },
    FEAST_MISSION_TIMER_TEXT: {
        size: 50,
        color: new cc.Color(255, 220, 132, 255),
        stroke: cleverapps.styles.DECORATORS.FEAST_MISSION_STROKE,
        shadow: cleverapps.styles.DECORATORS.FEAST_MISSION_SHADOW
    },
    FEAST_MISSION_REWARD_TEXT: {
        size: 34,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.FeastMissionWindow = {
    margin: 30,

    contentPadding: {
        bottom: 0
    },

    table: {
        margin: -2
    },

    button: {
        x: { align: "center", dx: -25 },
        y: { align: "center" },
        width: 400,
        height: 95
    },

    verticalWindow: {
        Background: {
            padding: {
                x: -15
            }
        }
    }
};
