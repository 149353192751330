/**
 * Created by r4zi4l on 04.05.2022
 */

var Offer = function (offerType, savedData) {
    var data = Offers[offerType];

    this.type = data.type;
    this.force = data.force;

    var LogicCtor = data.logic ? data.logic : SingleProductOfferLogic;
    this.logic = new LogicCtor(this);

    this.name = data.name;
    this.bundle = data.bundle;

    this.load(savedData);
    if (!this.started) {
        this.started = Date.now();
        delete this.finished;
    }

    this.sideBarJson = Array.isArray(data.sideBarJson) ? data.sideBarJson[this.stage || 0] : data.sideBarJson;

    if (!savedData && data.force) {
        cleverapps.forces.clearForce(data.force.id);
    }

    this.onUpdateState();
};

Offer.prototype.load = function (data) {
    if (!data) {
        return;
    }

    this.started = data.started;
    this.finished = data.finished;
    this.stage = data.stage;
    this.lastRewardTime = data.lastRewardTime;
};

Offer.prototype.getInfo = function () {
    return {
        started: this.started,
        finished: this.finished,
        lastRewardTime: this.lastRewardTime,
        stage: this.stage
    };
};

Offer.prototype.incStage = function () {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.OFFER + this.name + (this.stage ? this.stage : ""));

    this.stage = (this.stage || 0) + 1;

    cleverapps.offerManager.save();
    cleverapps.offerManager.refreshOffer(this.type);
};

Offer.prototype.onUpdateState = function () {
    if (this.countdown) {
        this.countdown.remove();
        delete this.countdown;
    }

    var leftTime = this.logic.getTimeLeft();
    if (leftTime > 0) {
        this.countdown = new cleverapps.CountDown(leftTime, {
            onFinish: this.checkCompletion.bind(this),
            permanent: true
        });
    }
};

Offer.prototype.checkCompletion = function () {
    if (this.checkLeftTime() && !(this.logic.needWait && this.logic.needWait())) {
        this.complete();
    }

    if (this.logic.checkIfComplete) {
        this.logic.checkIfComplete();
    }
};

Offer.prototype.checkLeftTime = function () {
    var duration = Offers[this.type].duration;
    return duration && this.started && Date.now() > this.started + cleverapps.parseInterval(duration);
};

Offer.prototype.complete = function () {
    if (!Offers[this.type].availableByStages) {
        delete this.stage;
    }
    delete this.started;
    this.finished = Date.now();

    cleverapps.offerManager.save();

    cleverapps.offerManager.refreshOffer(this.type);
};

Offer.prototype.beforeRemove = function () {
    if (this.countdown) {
        this.countdown.remove();
        delete this.countdown;
    }
};
