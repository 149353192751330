/**
 * Created by Slava on 15.10.2024
 */

var LevelMasteryRewardView = cc.Node.extend({
    ctor: function (rewards) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.LevelMasteryRewardView;
        this.setContentSize(styles.icon);

        var bg;
        if (rewards.length > 1) {
            bg = cleverapps.UI.createScale9Sprite(bundles.levelmastery.frames.bg_green_large_2);
            bg.setPositionRound(styles.bg_large);
            bg.setContentSize(styles.bg_large);
            this.addChild(bg);
        } else {
            bg = new cc.Sprite(bundles.levelmastery.frames.pink_circle_png);
            bg.setPositionRound(styles.bg);
            bg.setScale(styles.bg.width / bg.width);
            this.addChild(bg);
        }

        var items = [];

        rewards.forEach(function (reward, index) {
            if (index > 0) {
                var plus = new cc.Sprite(bundles.levelmastery.frames.plus);
                plus.setAnchorPoint(0.5, 0.5);
                items.push(plus);
            }

            var iconNode = new cc.Node();
            iconNode.setContentSize(styles.icon);
            iconNode.setAnchorPoint(0.5, 0.5);

            var icon = this.icon = reward.getIcon();
            cleverapps.UI.fitToBox(icon, styles.icon);
            icon.setPositionRound(styles.icon);
            iconNode.addChild(icon);
            iconNode.setContentSize(icon.width * icon.scale, iconNode.height);

            var text = this.text = cleverapps.UI.generateOnlyText(aisensia.getNumberWithPostfix(reward.getText()), rewards.length > 1 ? cleverapps.styles.FONTS.LEVELMASTERY_REWARD_TEXT : cleverapps.styles.FONTS.LEVELMASTERY_REWARD_TEXT_BIG);
            text.setPositionRound(rewards.length > 1 ? styles.bg_large.text : styles.bg.text);
            iconNode.addChild(text);

            items.push(iconNode);
        });

        var layout = new cleverapps.Layout(items, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        layout.setPositionRound(rewards.length > 1 ? styles.bg_large.layout : styles.bg.layout);

        if (rewards.length > 1) {
            var timer = this.createTimer();
            timer.setPositionRound(styles.timer);
            layout.addChild(timer);
        }

        this.addChild(layout);
    }
});

LevelMasteryRewardView.prototype.createTimer = function () {
    var styles = cleverapps.styles.LevelMasteryRewardView.timer;
    var timer = new cleverapps.CountDown(aisensia.levelMastery.getTimeLeft(), {
        onFinish: function () {
            this.stopTimer();
        }.bind(this)
    });

    var timerLabel = cleverapps.UI.createScale9Sprite(bundles.levelmastery.frames.timer_png);
    timerLabel.setContentSize(styles.background);
    timerLabel.setPositionRound(styles.background);

    var textTimer = new cleverapps.CountDownView(timer, { font: cleverapps.styles.FONTS.LEVELMASTERY_TIMER_TEXT });
    textTimer.setPositionRound(styles.text);
    timerLabel.addChild(textTimer);

    return timerLabel;
};

LevelMasteryRewardView.prototype.stopTimer = LevelMasteryView.prototype.stopTimer;

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LEVELMASTERY_REWARD_TEXT: {
        size: 25,
        color: new cc.Color(141, 218, 3, 255),
        stroke: {
            color: new cc.Color(5, 124, 25, 255),
            size: 2
        },
    },

    LEVELMASTERY_REWARD_TEXT_BIG: {
        size: 35,
        color: new cc.Color(141, 218, 3, 255),
        stroke: {
            color: new cc.Color(5, 124, 25, 255),
            size: 2
        },
    }
});

cleverapps.styles.LevelMasteryRewardView = {
    icon: {
        x: { align: "center" },
        y: { align: "center" },
        width: 90,
        height: 80
    },

    margin: 5,

    bg: {
        x: { align: "center" },
        y: { align: "center" },
        width: 120,

        layout: {
            x: { align: "center" },
            y: { align: "center" }
        },

        text: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -35 }
        }
    },

    bg_large: {
        x: { align: "center", dx: -20 },
        y: { align: "center", dy: 5 },
        width: 125,
        height: 80,

        layout: {
            x: { align: "center", dx: -40 },
            y: { align: "center", dy: 10 }
        },

        text: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -25 }
        }
    },

    timer: {
        x: { align: "center", dx: 5 },
        y: { align: "center", dy: -55 },
        background: {
            x: { align: "center" },
            y: { align: "center", dy: 0 },
            width: 150,
            height: 50,
        },
        text: {
            x: { align: "center" },
            y: { align: "center", dy: 0 },
        }
    }
};