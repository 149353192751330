/**
 * Created by Slava on 30.09.2024
 */

UpMenuContainer.prototype.addShields = function () {
    var shields = new ShieldsView();
    this.addChild(shields);

    this.shields = new HidingNode(shields, cleverapps.UI.VERTICAL);
    cleverapps.focusManager.registerControl("shields", this.shields);

    this.updateSize();
    this.setupChildren();
}

UpMenuContainer.prototype.setupChildren = cleverapps.extendFunc(UpMenuContainer.prototype.setupChildren, function () {
    this._super();

    if (this.shields) {
        this.shields.setPositionRound(cleverapps.styles.UpMenuContainer.shields);
    }
});

cleverapps.overrideStyles(cleverapps.styles.UpMenuContainer, {
    shields: [
        {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -180 }
        },
        {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        },
        {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    ]
});